// import Firebase from "firebase";
import Firebase from "firebase/app";
import "firebase/database"; // If using Firebase database
import "firebase/firestore"; // If using Firebase database
import "firebase/messaging";

// If using Firebase database
// import 'firebase/storage';

// Your web app's Firebase configuration
const firebaseApp = Firebase.initializeApp({
  apiKey: "AIzaSyAWQUpOZ0O3yIQ_rwME22xFqCwlHXc6zfM",
  authDomain: "aukiss-c01c6.firebaseapp.com",
  databaseURL: "https://aukiss-c01c6.firebaseio.com",
  projectId: "aukiss-c01c6",
  storageBucket: "aukiss-c01c6.appspot.com",
  messagingSenderId: "299451668761",
  appId: "1:299451668761:web:36732db8eaab81a2ec8d88",
  measurementId: "G-7V13BQNMS2",
});

// Initialize Firebase
const firestore = firebaseApp.firestore();

let messaging = "";

if (Firebase.messaging.isSupported()) {
  messaging = firebaseApp.messaging();
}

firestore.settings({ experimentalForceLongPolling: true });

//export const facebookProvider = new Firebase.auth.FacebookAuthProvider();

export const db = {
  realtime: firebaseApp.database(),
  firestore: firestore,
  firemessaging: messaging,
};
