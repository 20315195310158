<template>
  <div class="text-light">
    <topnav></topnav>
    <div class="main main-raised" style="margin: 0px; background-color: #1c212e">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5" style="min-height: 60vh">
        <div class="custom-container-fluid" v-if="profile_found === false">
          <div class="row text-center">
            <div class="col-lg-10 offset-lg-1 col-sm-6 offset-sm-3 col-12 p-3 error-main">
              <div class="row">
                <div class="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
                  <img src="../../public/assets/img/404.png" class="img-fluid" alt="" />
                  <p class="text-atvyellow mb-4" style="font-size: 25px">
                    Category not found...
                  </p>
                  <p>Sorry the category your looking for cannot be found.</p>
                  <router-link
                    to="/magazine"
                    class="btn btn-atvyellow pl-5 pr-5 pt-3 pb-3 mt-5"
                    style="border-radius: 10px"
                    >GO TO MAGAZINE
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container" v-if="profile_found === true">
          <div class="row">
            <div class="col-12">
              <div class="text-atvyellow cursor-pointer" @click="$router.go(-1)">
                <span class="material-icons">west</span>
                <span style="vertical-align: top">Back</span>
              </div>
              <br />
           
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/magazine">Magazine</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ postData.post_title }}
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col-12">
              <h3 class="mt-0 font-weight-bold">{{ postData.post_title }}</h3>
            </div>
            <div class="col-12">
              <!-- postData.post_content -->
              <!-- :description="stripHtml" -->
              <social-sharing
                :url="getBaseUrl() + '/magazine-details/' + postData.id"
                :title="postData.post_title"
                :description="postData.post_title"
                quote
                twitter-user="Aukiss"
                inline-template
              >
                <div style="cursor: pointer" class="mb-3">
                  <network network="email" class="mr-3 badge badge-pill badge-danger">
                    <i class="fa fa-envelope text-light"></i> Email
                  </network>
                  <network network="facebook" class="mr-3 badge badge-pill badge-info">
                    <i class="fa fa-facebook text-light"></i> Facebook
                  </network>
                  <network network="linkedin" class="mr-3 badge badge-pill badge-rose">
                    <i class="fa fa-linkedin text-light"></i> LinkedIn
                  </network>
                  <network network="sms" class="mr-3 badge badge-pill badge-danger">
                    <i class="fa fa-commenting-o text-light"></i> SMS
                  </network>
                  <network network="telegram" class="mr-3 badge badge-pill badge-primary">
                    <i class="fa fa-telegram text-light"></i> Telegram
                  </network>
                  <network network="twitter" class="mr-3 badge badge-pill badge-info">
                    <i class="fa fa-twitter text-light"></i> Twitter
                  </network>
                  <network network="whatsapp" class="mr-3 badge badge-pill badge-success">
                    <i class="fa fa-whatsapp text-light"></i> Whatsapp
                  </network>
                </div>
              </social-sharing>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12">
              <div class="mb-4">
                <div style="position: relative">
                  <img
                    v-lazy="postData.image"
                    :alt="postData.post_title"
                    class="img-fluid w-100"
                  />
                </div>
                <div class="td-module-meta-info">
                  <h3 class="entry-title">
                    <a>
                      {{ postData.post_title }}
                    </a>
                  </h3>
                  <div class="td-editor-date">
                    <span class="post-author"
                      ><a>
                        <router-link :to="'/magazine-author/' + postData.user.id"
                          >{{ postData.post_author }}
                        </router-link></a
                      >

                      <span v-if="postData.post_date && postData.post_author">-</span>
                    </span>
                    <span class="post-date"
                      ><time class="font-weight-bold">{{
                        getYear(postData.post_date)
                      }}</time></span
                    >
                    <!-- <span class="td-module-comments"
                      ><span>{{ postData.comment_count }}</span></span
                    > -->
                  </div>

                  <div class="td-excerpt text-light">
                    <div style="line-height: 1.9" v-html="postData.post_content"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 border" v-if="postData.user">
              <div class="media">
                <img
                  class="mr-3 mt-3"
                  v-lazy="(postData.user || {}).photo"
                  style="width: 64px; height: 64px; object-fit: cover"
                  alt="Author image"
                />
                <div class="media-body p-2">
                  <h5 class="mt-0" if>
                    <router-link :to="'/magazine-author/' + postData.user.id">
                      {{
                        (postData.user || {}).first_name +
                        " " +
                        (postData.user || {}).last_name
                      }}
                    </router-link>
                  </h5>
                  <div>{{ postData.user.profile }}</div>
                  <div>
                    <a
                      v-if="(postData.user || {}).facebook_url"
                      :href="(postData.user || {}).facebook_url"
                      target="_blank"
                      ><i class="fa fa-facebook mr-4"></i
                    ></a>
                    <a
                      v-if="(postData.user || {}).instagram_url"
                      :href="(postData.user || {}).instagram_url"
                      target="_blank"
                      ><i class="fa fa-instagram mr-4"></i
                    ></a>
                    <a
                      v-if="(postData.user || {}).twitter_url"
                      :href="(postData.user || {}).twitter_url"
                      target="_blank"
                      ><i class="fa fa-twitter mr-4"></i
                    ></a>
                    <a
                      v-if="(postData.user || {}).youtube_url"
                      :href="(postData.user || {}).youtube_url"
                      target="_blank"
                      ><i class="fa fa-youtube mr-4"></i
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="row" v-if="(postData.relatedArticles || []).length > 0">
            <div class="col-md-12 mb-4">
              <div class="td-block-title-wrap">
                <h4 class="block-title td-block-title block-title-green">
                  <span class="td-pulldown-size">Related Articles</span>
                </h4>
              </div>
            </div>
            <div
              class="col-6"
              v-for="(t, i) in postData.relatedArticles.slice(0, 4)"
              :key="i + 'a'"
            >
              <div style="position: relative" class="">
                <img v-lazy="t.image" :alt="t.post_title" class="img-fluid w-100" />
              </div>
              <div class="td-module-meta-info">
                <h3 class="side-entry-title">
                  <router-link
                    @click.native="getMagazineData"
                    :to="'/magazine-details/' + t.id"
                  >
                    {{ t.post_title }}
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Magazine-Details",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      postData: {},
      profile_found: "",
    };
  },
  computed: {
    stripHtml() {
      let regex = /(<([^>]+)>)/gi;
      return this.postData.post_content.replace(regex, "");
    },
  },
  methods: {
    getYear(d) {
      console.log("date", moment(new Date(d.replace(/-/g, "/"))).format("MMMM D, YYYY"));
      if (d) {
        return moment(new Date(d.replace(/-/g, "/"))).format("MMMM D, YYYY");
        //moment(new Date(d.replace(/-/g, "/"))).format("MMMM D, YYYY");
      }
    },
    getBaseUrl() {
      return window.location.origin;
    },
    sendViews(mag_id) {
      let data = {
        user_id: (this.userInfo || {}).id,
        magazine: mag_id,
        movie: "",
        tvshow: "",
        livetv: "",
        liveradio: "",
        movie_clicked: "",
        magazine_clicked: 1,
        tvshow_clicked: "",
        livetv_clicked: "",
        liveradio_clicked: "",
      };
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/postUserView", data)
        .then((res) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },

    getMagazineData() {
      return new Promise((resolve) => {
        this.isLoading = true;
        axios
          .post("https://api.aukiss.tv/api/webmagazineId", {
            mag_id: this.$route.params.id,
          })
          .then((res) => {
            if (res.data.data) {
              this.postData = res.data.data;

              this.profile_found = true;
              this.sendViews(this.postData.id);
            } else {
              this.profile_found = false;
            }
            resolve("done");
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading,
  },
  created() {
    this.getMagazineData().then(() => {
      document.title = this.postData.post_title + " || Aukiss TV";
    });
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
  },
};
</script>

<style scoped>
@import url("../../public/assets/css/owl.carousel.css");
@import url("../../public/assets/css/owl.theme.css");
@import url("../../public/assets/css/owl.transitions.css");

.breadcrumb {
  background: none !important;
  padding: 0px;
  font-size: 11px;
}

@media (min-width: 768px) {
  .pl-md-25 {
    padding-left: 1rem !important;
  }
}

.td-trending-now-display-area .entry-title {
  font-size: 15px;
  line-height: 25px;
  margin: 0;
}

.entry-title {
  word-wrap: break-word;
}

.td-next-prev-wrap a {
  display: inline-block;
  margin-right: 7px;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid #dcdcdc;
  text-align: center;
  vertical-align: middle;
  font-size: 7px;
  color: #b7b7b7;
}

.tainer::after {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0)
  ) !important;
  border-radius: 0px;
}

.post-author {
  font-weight: bold;
}

.post-date {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  font-size: 11px;
}

.block-title {
  border-color: #f9c100 !important;
}

.block-title {
  text-align: left;
}

.block-title {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 26px;
  border-bottom: 2px solid #222222;
}

.block-title > * {
  background-color: #f9c100 !important;
}

.td-block-title span {
  text-transform: uppercase !important;
}

.td-block-title span,
.td-block-title a {
  display: inline-block;
}

.block-title a,
.block-title span,
.block-title label {
  line-height: 17px;
  display: inline-block;
  padding: 7px 12px 4px;
  background-color: #222222;
  color: #fff;
}

.td-module-comments {
  display: block;
}

.td-module-comments {
  position: relative;
  float: right;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
}

.td-module-comments span {
  color: #fff;
  background-color: #000;
  display: inline-block;
  min-width: 17px;
  padding: 3px 4px 4px 5px;
  position: relative;
}

.td-module-comments span:after {
  position: absolute;
  bottom: -3px;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 0;
  border-color: #000 transparent transparent transparent;
}

.block-title-dark {
  border-color: #222222 !important;
}

.block-title-dark > * {
  background-color: #222222 !important;
}

.block-title-green {
  border-color: #0599a9 !important;
}

.block-title-green > * {
  background-color: #0599a9 !important;
}

.block-title-danger {
  border-color: #f44336 !important;
}

.block-title-danger > * {
  background-color: #f44336 !important;
}

.block-title-grey {
  border-color: #607d8b !important;
}

.block-title-grey > * {
  background-color: #607d8b !important;
}

.block-title-info {
  border-color: #4db2ec !important;
}

.block-title-info > * {
  background-color: #4db2ec !important;
}

.entry-title {
  font-size: 21px;
  line-height: 25px;
  margin: 0 0 6px 0;
}

.td-post-category {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  top: auto;
}

.td-post-category {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  background-color: #222222;
  color: #fff;
  margin-right: 5px;
  padding: 3px 6px 4px;
  display: block;
  vertical-align: top;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.td-module-meta-info {
  padding: 13px 0 0 0;
}

.td-module-meta-info {
  width: 100%;
  margin-bottom: 0;
  z-index: 1;
  /* border: 0 solid #eaeaea; */
  min-height: 0;
}

.td-module-meta-info {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 11px;
  margin-bottom: 7px;
  line-height: 1;
  min-height: 17px;
}

.td-excerpt {
  font-size: 13px;
  color: #777;
  font-family: "Open Sans", arial, sans-serif;
  line-height: 21px;
  margin: 20px 0 0;
}

.article img {
  width: 223px;
  height: 156px;
  object-fit: cover;
}

.media:hover a {
  color: #f9c100;
}

.td-module-meta-info:hover a {
  color: #f9c100;
}

.side-entry-title {
  font-size: 13px !important;
  line-height: 1.3 !important;
  font-weight: 500 !important;
  margin: 0 0 35px 0;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .article img {
    width: 100%;
  }

  .side-right img {
    height: 254px !important;
  }

  .side-right .all {
    padding-right: 2px !important;
    padding-left: 2px !important;
    max-width: 288px !important;
    height: 180px !important;
  }

  .side-right .meta-info .post-title {
    pointer-events: none !important;
    margin: 4px auto;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }

  .left_big_info .post-title {
    pointer-events: none !important;
    margin: 4px auto;
    font-size: 16px !important;
    line-height: 21px;
    font-weight: 500;
  }

  .all .tainer::after {
    background: linear-gradient(
      0deg,
      rgb(0 0 0),
      rgba(0, 0, 0, 0) 90%,
      rgb(0 0 0 / 0%)
    ) !important;
  }
}
</style>
