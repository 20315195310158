<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section">
        <div
          class="custom-container-fluid"
          style="background-image: url('./assets/img/account_back.png'); height: 300px"
        >
          <div class="row mb-5 pt-3">
            <div class="col-md-12">
              <div
                class="text-atvyellow cursor-pointer"
                @click="$router.go(-1)"
              >
                <span class="material-icons"> west </span>
                <span style="vertical-align: top">Back</span>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-container-fluid">
          <div class="row" style="margin-top: -100px">
            <div class="col-md-4 wide_space">
              <div class="card">
                <div class="card-body text-light">
                  <div class="text-center">
                    <img
                      v-lazy="
                        $store.state.userInfo.photo
                          ? $store.state.userInfo.photo
                          : '../../../assets/img/blank_profile.png'
                      "
                      style="width: 162px; height: 162px"
                      class="rounded-circle img-fluid"
                      alt=""
                    />
                    <h3>{{ userInfo.first_name }} {{ userInfo.last_name }}</h3>
                    <h4>{{ userInfo.email }}</h4>
                    <h4>
                      Unique Id:
                      {{ (subscription.user || "").unique_user_id || "" }}
                    </h4>
                    <button
                      class="btn btn-sm btn-atvyellow"
                      @click="activeLink = 'Subscription'"
                    >
                      {{
                        ((subscription || {}).user_subs_plan || {}).plan_name
                      }}
                    </button>
                    <div style="color: rgba(255, 255, 255, 0.7)" class="mt-4">
                      Member since
                      {{ getDate((subscription || {}).created_at) }}.
                    </div>
                    <div
                      style="border: 1px solid rgba(255, 255, 255, 0.2); margin: 1rem 0px"
                    ></div>
                  </div>

                  <div class="row">
                    <div class="col-8 offset-3">
                      <ul class="sidelinks">
                        <li
                          @click="activeLink = 'Overview'"
                          :class="[
                            activeLink == 'Overview' ? 'active_tab' : ''
                          ]"
                        >
                          <div class="row">
                            <!--<div class="col-1">-->
                            <!--<i class="fa fa-caret-right"-->
                            <!--v-if="activeLink=='Overview'"-->
                            <!--style=""></i>-->
                            <!--</div>-->
                            <div class="col-10">
                              <a>Overview</a>
                            </div>
                          </div>
                        </li>
                        <li
                          @click="activeLink = 'Profile'"
                          :class="[activeLink == 'Profile' ? 'active_tab' : '']"
                        >
                          <div class="row">
                            <!--<div class="col-1">-->
                            <!--<i class="fa fa-caret-right col-1"-->
                            <!--v-if="activeLink=='Profile'"-->
                            <!--style="font-size: 20px;"></i>-->
                            <!--</div>-->
                            <div class="col-10">
                              <a>Profile</a>
                            </div>
                          </div>
                        </li>
                        <li
                          @click="activeLink = 'Subscription'"
                          :class="[
                            activeLink == 'Subscription' ? 'active_tab' : ''
                          ]"
                        >
                          <div class="row">
                            <!--<div class="col-1">-->
                            <!--<i class="fa fa-caret-right col-1"-->
                            <!--v-if="activeLink=='Subscription'"-->
                            <!--style="font-size: 20px;"></i>-->
                            <!--</div>-->
                            <div class="col-9">
                              <a>Subscription</a>
                            </div>
                          </div>
                        </li>
                        <!--<li @click="activeLink='Orders'"-->
                        <!--:class="[activeLink=='Orders' ? 'active_tab' : '']">-->
                        <!--<div class="row">-->
                        <!--&lt;!&ndash;<div class="col-1">&ndash;&gt;-->
                        <!--&lt;!&ndash;<i class="fa fa-caret-right col-1"&ndash;&gt;-->
                        <!--&lt;!&ndash;v-if="activeLink=='Orders'"&ndash;&gt;-->
                        <!--&lt;!&ndash;style="font-size: 20px;"></i>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!--<div class="col-9">-->
                        <!--<a>Orders</a>-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--</li>-->
                        <!--<li @click="activeLink='Transaction'"-->
                        <!--:class="[activeLink=='Transaction' ? 'active_tab' : '']">-->
                        <!--<div class="row">-->
                        <!--&lt;!&ndash;<div class="col-1">&ndash;&gt;-->
                        <!--&lt;!&ndash;<i class="fa fa-caret-right col-1"&ndash;&gt;-->
                        <!--&lt;!&ndash;v-if="activeLink=='Transaction'"&ndash;&gt;-->
                        <!--&lt;!&ndash;style="font-size: 20px;"></i>&ndash;&gt;-->
                        <!--&lt;!&ndash;</div>&ndash;&gt;-->
                        <!--<div class="col-9">-->
                        <!--<a>Transaction</a>-->
                        <!--</div>-->
                        <!--</div>-->
                        <!--</li>-->
                        <li>
                          <div class="row">
                            <!--<div class="col-1">-->
                            <!--</div>-->
                            <div class="col-9">
                              <router-link to="/logout">Logout</router-link>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card" v-if="activeLink == 'Overview'">
                <div class="card-body">
                  <h3 class="text-atvyellow font-weight-bold">Overview</h3>
                  <div class="mt-4">Hello there,</div>
                  <div class="mt-4">You are enjoying the good life.</div>
                  <div class="mt-4">
                    Thanks for supporting diaspora creatives
                  </div>
                </div>
              </div>
              <div class="card" v-if="activeLink == 'Profile'">
                <div class="card-body">
                  <h3 class="text-atvyellow font-weight-bold mb-5">Profile</h3>
                  <form class="col-md-6" @submit.prevent="submit">
                    <!--<div class="form-group">-->
                    <!--<label class="bmd-label-floating">Username </label>-->
                    <!--<input type="text" required v-model="details.user_name" class="form-control">-->
                    <!--</div>-->
                    <div class="form-group">
                      <label class="bmd-label-floating">Email </label>
                      <input
                        type="email"
                        disabled
                        :value="details.email"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group">
                      <label for="phone" class="pull-left">Phone Number</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="details.phone_number"
                        id="phone"
                      />
                    </div>
                    <div class="form-group">
                      <label for="first_name" class="pull-left"
                        >First Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="details.first_name"
                        id="first_name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="last_name" class="pull-left">Last Name</label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        v-model="details.last_name"
                        id="last_name"
                      />
                    </div>
                    <div class="form-group">
                      <label for="last_name" class="pull-left"
                        >Change Profile Image</label
                      >
                      <input
                        type="file"
                        @change="selectphoto"
                        class="form-control"
                        id="file"
                      />
                      <span class="uploading_progress"></span>
                    </div>
                    <div class="form-group">
                      <label for="password" class="pull-left"
                        >New Password</label
                      >
                      <input
                        type="password"
                        class="form-control"
                        v-model="details.password"
                        id="password"
                      />
                    </div>
                    <div class="form-group">
                      <label for="confirm_password" class="pull-left"
                        >Confirm Password</label
                      >
                      <input
                        type="password"
                        class="form-control"
                        v-model="details.confirm_password"
                        id="confirm_password"
                      />
                    </div>
                    <div class="text-center mt-5 mb-5">
                      <button
                        type="submit"
                        class="btn btn-block btn-atvyellow mb-3 h5 font-weight-bold"
                      >
                        Update Profile
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="card" v-if="activeLink == 'Subscription'">
                <div class="card-body">
                  <h3 class="text-atvyellow font-weight-bold mb-5">
                    Subscriptions
                  </h3>
                  <div class="table-responsive" v-if="sub_obj_empty">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th class="cart_product">Membership</th>
                          <th>Subscription Type</th>
                          <th>Starts On</th>
                          <th>Expires On</th>
                          <th>Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {{ (subscription.user_subs_plan || {}).plan_name }}
                          </td>
                          <td>
                            {{ (subscription.user_subs_plan || {}).plan }}
                          </td>
                          <td>{{ getDate(subscription.created_at) }}</td>
                          <td>{{ getDate(subscription.expired_at) }}</td>
                          <td>
                            GHC {{ (subscription.user_subs_plan || {}).amount }}
                          </td>
                          <td
                            class="text-atvyellow font-weight-bold"
                            v-if="subscription_expired"
                          >
                            <router-link
                              class="text-atvyellow"
                              to="/subscription"
                              >Change
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="text-center font-weight-bold contentItem">
                    You have no orders yet
                  </div>
                </div>
              </div>
              <div class="card" v-if="activeLink == 'Orders'">
                <div class="card-body">
                  <h3 class="text-atvyellow font-weight-bold mb-5">Orders</h3>
                  <div v-if="orders.length > 0" class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th class="cart_product">Membership</th>
                          <th>Subscription Type</th>
                          <th>Starts On</th>
                          <th>Expires On</th>
                          <th>Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Video on Demand</td>
                          <td>Monthly</td>
                          <td>July 1, 2020</td>
                          <td>August 1, 2020</td>
                          <td>Free</td>
                          <td class="text-atvyellow font-weight-bold">
                            Remove
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    v-if="orders.length == 0"
                    class="text-center font-weight-bold contentItem"
                  >
                    You have no orders yet
                  </div>
                </div>
              </div>
              <div class="card" v-if="activeLink == 'Transaction'">
                <div class="card-body">
                  <h3 class="text-atvyellow font-weight-bold mb-5">
                    Transactions
                  </h3>
                  <div v-if="sub_obj_empty" class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th class="cart_product">Membership</th>
                          <th>Subscription Type</th>
                          <th>Starts On</th>
                          <th>Expires On</th>
                          <th>Amount</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Video on Demand</td>
                          <td>Monthly</td>
                          <td>July 1, 2020</td>
                          <td>August 1, 2020</td>
                          <td>Free</td>
                          <td class="text-atvyellow font-weight-bold">
                            Remove
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="text-center font-weight-bold contentItem">
                    You have no transaction yet
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Swal from "sweetalert2";

export default {
  name: "Account",
  data() {
    return {
      activeLink: "Overview",
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      details: {
        id: this.$store.state.userInfo.id,
        user_name: this.$store.state.userInfo.user_name
          ? this.$store.state.userInfo.user_name
          : "",
        email: this.$store.state.userInfo.email,
        first_name: this.$store.state.userInfo.first_name,
        last_name: this.$store.state.userInfo.last_name,
        phone_number: this.$store.state.userInfo.phone_number,
        password: "",
        confirm_password: "",
        photo: "",
      },
      orders: [],
      transactions: [],
      subscription: {},
      photo: "",
      sub_obj_empty: false,
      subscription_expired: false,
    };
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading,
  },
  methods: {
    checkDate() {
      var date = moment((this.subscription || {}).expired_at);
      var now = moment();
      if (now > date) {
        this.subscription_expired = true;
      } else {
        this.subscription_expired = false;
      }
    },
    getDate(d) {
      if (d) {
        return moment(new Date(d)).format("Do MMM YYYY");
      }
    },
    getUserSubscription() {
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          // if (
          //   ((res.data.data || {}).user || {}).role_id == 2 ||
          //   ((res.data.data || {}).user || {}).role_id == 3 ||
          //   ((res.data.data || {}).user || {}).role_id == 4 ||
          //   ((res.data.data || {}).user || {}).role_id == 5
          // ) {
         //  } else {
            this.subscription = res.data.data;
            console.log(this.subscription, 'subscription')

            if (this.subscription && Object.keys(this.subscription).length !== 0) {
              this.sub_obj_empty = true;
            }

            this.checkDate();
          // }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectphoto(e) {
      this.photo = e.target.files[0];
    },
    create_UUID() {
      var dt = new Date().getTime();
      var uuid = "xxx4xxyxx".replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      });
      return uuid;
    },
    s3Upload() {
      return new Promise((resolve, reject) => {
        if (this.photo) {
          this.isLoading = true;
          let fileName = this.create_UUID() + this.userInfo.id + this.photo.name;
          const ID = "AKIA6ODVADTHDUUYFX4L";
          const SECRET = "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";
          const BUCKET_NAME = "aukisscontent";

          const s3 = new AWS.S3({
            accessKeyId: ID,
            secretAccessKey: SECRET,
          });
          const params = {
            Bucket: BUCKET_NAME,
            Key: fileName, // File name you want to save as in S3
            Body: this.photo,
            ContentType: this.photo.type,
            ACL: "public-read",
          };
          let me = this;
          s3.upload(params, function (err, data) {
            if (err) {
              reject("Error whiles uploading image...");
              return;
            }
            me.isLoading = false;
            resolve(data.Location);
          }).on("httpUploadProgress", function (progress) {
            let uploaded = parseInt((progress.loaded * 100) / progress.total);
            $(".uploading_progress").html("Uploading..." + uploaded + "%");
          });
        } else {
          resolve("null");
        }
      });
    },
    submit() {
      if (this.details.password != this.details.confirm_password) {
        Swal.fire({
          title: "Error!",
          text: "Password and password confirmation do not match",
          icon: "error",
          confirmButtonText: "Close",
        });
        return;
      }
      this.s3Upload().then((res) => {
        if (res != "null") {
          this.details.photo = res;
        } else {
          this.details.photo = this.$store.state.userInfo.photo
            ? this.$store.state.userInfo.photo
            : "";
        }
        this.saveInfo();
      });
    },
    saveInfo() {
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/webupdateaccount", this.details)
        .then((res) => {
          this.$store.dispatch("updateInfo", res.data).then((results) => {
            // this.userInfo = res.data.data;
            document.getElementById("file").value = "";
            $(".uploading_progress").html("");

            Swal.fire({
              title: "Success!",
              text: "Profile has been updated successfully...",
              icon: "success",
              confirmButtonText: "Close",
            });
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Error!",
            html: "",
            icon: "error",
            confirmButtonText: "Close",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  created() {
    this.$loadScript("https://sdk.amazonaws.com/js/aws-sdk-2.16.0.min.js")
      .then(() => {})
      .catch(() => {});
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getUserSubscription();
    document.title = "Profile || Aukiss TV";
  },
};
</script>

<style scoped>
.sidelinks li {
  margin-bottom: 12px;
  cursor: pointer;
}

.contentItem {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

li {
  list-style: none;
}

.sidelinks li a {
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
}

.active_tab a {
  color: #a88d30 !important;
}

.card {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #1c212e !important;
  box-shadow: 0px 4px 50px rgba(82, 82, 82, 0.1) !important;
  border-radius: 10px !important;
  color: white;
  min-height: 637px;
}

.table > thead > tr > th {
  border-bottom: 1px solid #a88d30;
}

.form-control {
  color: white !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef00 !important;
  opacity: 1;
}

.form-group input[type="file"] {
  opacity: 1 !important;
  position: relative !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
}
</style>
