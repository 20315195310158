import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import LoadScript from "vue-plugin-load-script";
import VueLazyload from "vue-lazyload";
//import VueVideoPlayer from "vue-video-player";
//import "video.js/dist/video-js.css";
//import "vue-video-player/src/custom-theme.css";
//import "videojs-flash";
//import "videojs-contrib-hls/dist/videojs-contrib-hls";

//Vue.config.ignoredElements = ["video-js"];

let SocialSharing = require("vue-social-sharing");
Vue.use(SocialSharing);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "../../assets/images/error.png",
  loading: "../../assets/images/loader.gif",
  attempt: 1,
});

Vue.use(LoadScript);

Vue.config.productionTip = false;
//Vue.use(VueVideoPlayer);
Vue.config.ignoredElements = ["video-js"];
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.loggedIn) {
      store
        .dispatch("storeRedirectPath", {
          path: to,
        })
        .then(() => {
          next({
            name: "Login",
          });
        });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters.loggedIn) {
      next({
        path: "/home",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
