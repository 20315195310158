var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-light"},[_c('topnav'),_c('div',{staticClass:"main main-raised",staticStyle:{"margin":"0px","background-color":"#1c212e"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"loader":"dots","is-full-page":_vm.fullPage,"height":_vm.height,"width":_vm.width,"background-color":"#6b6969"},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"section mt-5",staticStyle:{"min-height":"65vh"}},[_c('div',{staticClass:"custom-container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[(_vm.m3u8Type === 'm3u8')?_c('div',[_c('video-player',{ref:"videoPlayer",staticClass:"vjs-custom-skin",attrs:{"options":_vm.playerOptions},on:{"ready":function($event){return _vm.onPlayerReady($event)}}})],1):_c('div',{attrs:{"id":"oldplayer"}},[_vm._m(0)])]),_c('div',{staticClass:"col-md-4 content-column d-none d-md-block"},_vm._l((_vm.filteredPlayer),function(t,i){return _c('div',{key:i,staticClass:"mr-md-5 mr-3",on:{"click":function($event){_vm.movie_info = t;
            _vm.poster_image = t.image;
            _vm.showVideo(t.link, t.type, t);}}},[_c('div',{staticClass:"card cursor-pointer"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(t.image),expression:"t.image"}],staticClass:"card-img-top",attrs:{"alt":""}})]),_c('div',{staticClass:"mt-1 movie-text"}),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(t.name))])])}),0)]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-md-10 magazine_trending text-left pl-md-0"},[_c('div',_vm._l((_vm.currentTrend),function(t,i){return _c('div',{key:i,staticClass:"pr-2"},[_c('router-link',{attrs:{"to":'/magazine-details/' + t.id}},[_c('span',{domProps:{"innerHTML":_vm._s(
                  t.post_title.length > 80
                    ? t.post_title.substring(0, 80) + '...'
                    : t.post_title
                )}})])],1)}),0)])]),_c('div',{staticClass:"row d-block d-md-none mt-4"},[_c('div',{staticClass:"col-12 content-column"},_vm._l((_vm.filteredPlayer),function(t,i){return _c('div',{key:i,staticClass:"mr-md-5 mr-3",on:{"click":function($event){_vm.movie_info = t;
            _vm.poster_image = t.image;
            _vm.showVideo(t.link, t.type, t);}}},[_c('div',{staticClass:"card cursor-pointer"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(t.image),expression:"t.image"}],staticClass:"card-img-top",attrs:{"alt":""}})]),_c('div',{staticClass:"mt-1 movie-text"}),_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(t.name))])])}),0)])])])],1),_c('footernav')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('video',{attrs:{"poster":"capture.jpg","id":"player","playsinline":"","controls":""}},[_c('source',{attrs:{"src":"https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4","type":"video/mp4"}}),_c('source',{attrs:{"src":"https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4","type":"video/webm"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-2 mb-2 col-6 text-left"},[_c('div',{staticClass:"td-trending-now-title w-100 text-center"},[_vm._v(" Trending Now ")])])}]

export { render, staticRenderFns }