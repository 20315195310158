<template>
    <div class="text-light">
      <loading
        :active.sync="isLoading"
        :can-cancel="true"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="container" id="contentItem">
        <div class="row">
          <div
            class="col-lg-8 offset-lg-2 col-sm-6 offset-sm-3 col-12 p-3 error-main"
          >
            <div class="row">
              <div class="col-lg-7 col-12 col-sm-10 offset-lg-2 offset-sm-2">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <center>
                  <img
                    src="../../public/assets/img/logo.png"
                    alt=""
                    class="img-fluid mb-5"
                  />
                </center>
  
                <div class="h3 mb-4 text-left font-weight-bold">CONTENT CREATORS REGISTERATION</div>
                <form @submit.prevent="submit">
                  <div class="form-group">
                    <label class="bmd-label-floating"
                      >Username <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      required
                      v-model="data.user_name"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group">
                    <label for="email" class="bmd-label-floating"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      type="email"
                      required
                      class="form-control"
                      v-model="data.email"
                      id="email"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="phone" class="bmd-label-floating"
                      >Phone <span class="text-danger"></span
                    ></label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="data.phone_number"
                      id="phone"
                    />
                  </div>
                  <div class="form-group">
                    <label for="firstname" class="bmd-label-floating"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      required
                      class="form-control"
                      id="firstname"
                      v-model="data.first_name"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div class="form-group">
                    <label for="lastname" class="bmd-label-floating"
                      >Last Name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      required
                      class="form-control"
                      id="lastname"
                      v-model="data.last_name"
                      aria-describedby="emailHelp"
                    />
                  </div>
  
                  <div class="form-group">
                    <label for="lastname" class="bmd-label-floating"
                      >Address<span class="text-danger"></span
                    ></label>
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      v-model="data.address"
                    />
                  </div>
  
                  <div class="form-group">
                    <label for="lastname" class="bmd-label-floating"
                      >Gender<span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control font-weight-bold"
                      style="color: #a88d30"
                      v-model="data.gender"
                      aria-describedby="emailHelp"
                    >
                      <option value="">Gender</option>
                      <option v-for="(c, i) in genderData" :key="i" :value="c">
                        {{ c }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="lastname" class="bmd-label-floating"
                      >Role<span class="text-danger">*</span></label
                    >
                    <select
                      class="form-control font-weight-bold"
                      style="color: #a88d30"
                      v-model="data.role"
                      aria-describedby="emailHelp"
                    >
                      <option value="">Role</option>
                      <option v-for="(c, i) in rolesData" :key="i" :value="c">
                        {{ c }}
                      </option>
                    </select>
                  </div>
  
                  <div class="form-group">
                    <label for="lastname" class="bmd-label-floating"
                      >Referral Code (Optional)<span class="text-danger"></span
                    ></label>
                    <input
                      type="text"
                      class="form-control"
                      id="referral_code"
                      v-model="data.referral_code"
                    />
                  </div>
  
                  <div class="form-group">
                    <label for="password" class="bmd-label-floating"
                      >Password</label
                    >
                    <input
                      type="password"
                      required
                      class="form-control"
                      v-model="data.password"
                      id="password"
                    />
                  </div>
                  <div class="form-group">
                    <label for="confirm_password" class="bmd-label-floating"
                      >Confirm Password</label
                    >
                    <input
                      type="password"
                      required
                      class="form-control"
                      v-model="data.password_confirmation"
                      id="confirm_password"
                    />
                  </div>
                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn btn-block btn-atvyellow h5 font-weight-bold"
                    >
                      Register
                    </button>
                    <router-link
                      to="/login"
                      class="text-center text-atvyellow h5 font-weight-bold"
                    >
                      Login
                    </router-link>
                    <!--<div class="line mb-5 mt-5"><span>or login using</span></div>-->
                    <!--<img src="../../public/assets/img/facebook.png" alt="" class="img-fluid mr-3">-->
                    <!--<img src="../../public/assets/img/twitter.png" alt="" class="img-fluid mr-3">-->
                    <!--<img src="../../public/assets/img/instagram.png" alt="" class="img-fluid mr-3">-->
                    <!--<img src="../../public/assets/img/youtube.png" alt="" class="img-fluid mr-3">-->
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import Swal from "sweetalert2";
  import axios from "axios";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import { db } from "@/firebaseConfig";
  
  export default {
    data() {
      return {
        data: {
          user_name: "",
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          password: "",
          password_confirmation: "",
          device_token: "",
          address: "",
          gender: "",
          referral_code: "",
          city: "",
          continentCode: "",
          continentName: "",
          countryCode: "",
          countryName: "",
          ipAddress: "",
          stateProv: "",
          role: ""
        },
        genderData: ["Male", "Female"],
        rolesData: ["Radio(Content Creator)"],
        isLoading: false,
        fullPage: true,
        height: 128,
        width: 128,
      };
    },
    components: {
      Loading,
    },
    methods: {
      getMyToken() {
        if (db.firemessaging) {
          const messaging = db.firemessaging;
          messaging
            .getToken({
              vapidKey:
                "BGEfhqbz5V-0-ZUZkxP8Z8vzMKXEmg1ygefLM_nBhcEjJtGYBeFKiaW3Og7cghxAJyDEhxtbIb87LLDDTJ-lK-A",
            })
            .then(async (token) => {
              if (token) {
                this.data.device_token = token;
              } else {
                this.notificationsPermisionRequest();
              }
            })
            .catch(function (err) {
              // console.log('An error occurred while retrieving token. ', err);
            });
        }
      },
      getlocation(){
        this.isLoading = true;
        axios.get("https://api.db-ip.com/v2/free/self")
        .then((res)=> {
          this.data.city = res.data.city;
          this.data.continentCode = res.data.continentCode;
          this.data.continentName = res.data.continentName;
          this.data.countryCode = res.data.countryCode;
          this.data.countryName = res.data.countryName;
          this.data.ipAddress = res.data.ipAddress;
          this.data.stateProv = res.data.stateProv;
       
        })   
        .finally(() => {
            this.isLoading = false;
          });
      },
      notificationsPermisionRequest() {
        const messaging = db.firemessaging;
        messaging
          .requestPermission()
          .then(() => {
            this.getToken();
          })
          .catch((err) => {
            console.log("Unable to get permission to notify.", err);
          });
      },
      submit() {
        if (this.data.password != this.data.password_confirmation) {
          Swal.fire({
            title: "Error!",
            text: "Password and password confirmation do not match",
            icon: "error",
            confirmButtonText: "Close",
          });
          return;
        }
        this.isLoading = true;
        this.$store
          .dispatch("retrieveContentToken", this.data)
          .then((res) => {
            //this.$router.push("/home");
            // https://cpanel.aukissmedia.com/auths/auth-login
            window.location.href = 'https://cpanel.aukissmedia.com/auths/auth-login';
            
          })
          .catch((err) => {
            Swal.fire({
              title: "Error!",
              html: (err.data.error || {}).email
                ? (err.data.error || {}).email[0] + "<br>"
                : "" + (err.data.error || {}).phone_number
                ? (err.data.error || {}).phone_number[0]
                : "",
              icon: "error",
              confirmButtonText: "Close",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
    },
    created() {
      document.title = "Content Creators Registeration || Aukiss TV";
      this.getMyToken();
      this.getlocation();
    },
  };
  </script>
  
  <style scoped>
  .line {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #fff;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  
  .line span {
    background: #1c212e;
    padding: 0 10px;
  }
  
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    background-color: black !important;
    color: black !important;
    -webkit-box-shadow: 0 0 0 1000px #bdbdbd inset !important;
    -webkit-text-fill-color: black !important;
  }
  
  /* Change the white to any color ;) */
  /*input:-webkit-autofill,*/
  /*input:-webkit-autofill:hover,*/
  /*input:-webkit-autofill:focus,*/
  /*input:-webkit-autofill:active  {*/
  /*-webkit-box-shadow: 0 0 0 30px white inset !important;*/
  /*transition: background-color 5000s ease-in-out 0s;*/
  
  /*}*/
  /*Change text in autofill textbox*/
  input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
  }
  
  input:-webkit-autofill {
    color: #2a2a2a !important;
  }
  
  #contentItem {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .form-control {
    color: white !important;
  }
  </style>
  