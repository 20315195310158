<template>
  <div class="text-light">
    <topnav></topnav>
    <div class="main main-raised" style="margin: 0px; background-color: #1c212e">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5" style="min-height: 65vh">
        <div class="custom-container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="p-md-5 border">
                <div class="row">
                  <div class="col-md-1 text-center">
                    <img
                      v-lazy="
                        poster_image ? poster_image : '../../assets/images/error.png'
                      "
                      class="img-fluid rounded-circle pt-2"
                      alt=""
                      style="width: 100px; height: 100px"
                    />
                  </div>
                  <div class="col-md-11 mt-lg-3">
                    <audio id="player" controls>
                      <source src="" type="audio/mp3" />
                      <!--<source src="/path/to/audio.ogg" type="audio/ogg" />-->
                    </audio>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-10 offset-md-1">
              <h3 class="text-atvyellow font-weight-bold mb-4 mt-5">Channel List</h3>
            </div>
            <div class="col-md-10 offset-md-1">
              <div class="side_media border-around">
                <ul class="list-unstyled">
                  <li
                    class="media pb-4 pt-4"
                    v-for="(t, i) in tv_list"
                    :key="i"
                    @click="
                      movie_info = t;
                      poster_image = t.image;
                      showVideo(t.link, t.type, t);
                    "
                  >
                    <img
                      v-lazy="t.image ? t.image : '../../assets/images/error.png'"
                      class="mr-3 mt-2 media_image"
                      alt="..."
                    />
                    <div class="media-body">
                      <button class="btn btn-atvyellow">Play</button>
                      <h5 class="mt-0 mb-1 font-weight-bold media-body-text">
                        {{ t.name }}
                      </h5>
                      <small>
                        {{
                          ((t || {}).description || "").length > 120
                            ? t.description.substring(0, 120) + "..."
                            : t.description
                        }}
                      </small>
                      <!--<div class="media-body-text">September 19, 2020 - 5:00pm</div>-->
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Plyr from "plyr";
import axios from "axios";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Home",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      playing: "",
      tv_list: [],
      movie_player: "",
      poster_image: "",
    };
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading: () => import("vue-loading-overlay"),
  },
  methods: {
    getYear(d) {
      return d ? moment((d || {}).toDate()).format("MMMM Do YYYY, h:mm:ss a") : "";
    },
    triggerPlayer(videoUrl, type) {
      this.movie_player = new Plyr("#player", {
        debug: false,
        autoplay: true,
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "pip",
          "airplay",
          "fullscreen",
        ],
        settings: ["speed"],
      });
      if (type == "youtube") {
        this.movie_player.source = {
          type: "video",
          sources: [
            {
              src: videoUrl ? videoUrl : "",
              provider: "youtube",
            },
          ],
        };
      } else {
        this.movie_player.source = {
          type: "audio",
          sources: [
            {
              src: videoUrl ? videoUrl : "",
              type: "audio/mp3",
              size: 720,
            },
            {
              src: videoUrl ? videoUrl : "",
              type: "audio/mp3",
              size: 1080,
            },
          ],
          poster: this.poster_image ? this.poster_image : "",
        };
      }
    },

    sendViews(radio_id) {
      let data = {
        user_id: (this.userInfo || {}).id,
        magazine: "",
        movie: "",
        tvshow: "",
        livetv: "",
        liveradio: radio_id,
        movie_clicked: "",
        magazine_clicked: "",
        tvshow_clicked: "",
        livetv_clicked: "",
        liveradio_clicked: 1,
      };
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/postUserView", data)
        .then((res) => {
          //console.log("res", res);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showVideo(videoUrl, type, t) {
      if (this.movie_player) {
        this.movie_player.destroy();
        this.triggerPlayer(videoUrl, type);
        var arr = (t || {}).id;
        this.sendViews(arr);
        return;
      }
      this.triggerPlayer(videoUrl, type);
    },
    stopPlayer() {
      if (this.movie_player) {
        this.movie_player.stop();
      }
    },

    getLiveTv() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/liveradio")
        .then((res) => {
          this.tv_list = res.data.data;

          console.log("res", this.tv_list);
          if (this.tv_list.length > 0) {
            this.poster_image = this.tv_list[0].image;
            this.showVideo(this.tv_list[0].link);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          // console.log("res", res);
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {
           //  } else {
              this.subscription = res.data.data;
              if ((this.subscription || {}).expired_at || this.subscription == null) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                }
              } else {
                this.$router.push("/subscription");
              }
            // }
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    document.title = "Live Radio || Aukiss TV";

    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getLiveTv();
    this.checkExpiration();
  },
};
</script>

<style scoped>
/*@import url('../../public/assets/css/player.css');*/

.divider {
  width: 50px;
  background: #a88d30;
  height: 6px;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
}

.media-body-text {
  color: rgba(255, 255, 255, 0.7);
}

.border-around {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem 0rem 0.5rem 1rem;
  border-radius: 5px;
}

.category_list {
  list-style: none;
  margin-top: 1.5rem;
}

.category_list li {
  margin-bottom: 1.2rem;
}

li.media {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

li.media:last-child {
  border: none;
  padding-bottom: 0px !important;
}

li.media:first-child {
  padding-top: 0px !important;
}

.media_image {
  height: 90px;
  width: 91px;
  object-fit: cover;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .media_image {
    height: 70px;
    width: 70px;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 1385px) and (min-width: 768px) {
  .media_image {
    display: none;
  }

  .media-body-text {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  .plyr video {
    height: 600px !important;
  }
}
</style>
