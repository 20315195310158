<template>
  <div class="text-light">
    <topnav></topnav>
    <div class="main main-raised" style="margin: 0px; background-color: #1c212e">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-3" style="min-height: 65vh">
        <div class="custom-container-fluid">
          <div class="row">
            <div class="col-md-12 text-center mb-4">
              <h3 class="font-weight-bold mb-4 mt-3">How Can We Help You?</h3>
              <form @submit.prevent="sendQuestion">
                <div class="input-group mb-3 col-md-4 offset-md-4">
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.title"
                    placeholder="Search a question..."
                    style="
                      padding: 25px;
                      background: rgba(228, 228, 228, 0.3);
                      border-top-left-radius: 10px;
                      border-bottom-left-radius: 10px;
                      color: white;
                    "
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      style="
                        margin-top: -1px;
                        padding: 16.7px;
                        color: white;
                        box-shadow: none;
                        border-left: none;
                        background: rgba(228, 228, 228, 0.3);
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                      "
                      type="submit"
                      id="button-addon2"
                    >
                      <i class="material-icons text-light">search</i>
                    </button>
                  </div>
                </div>
              </form>
              <p style="color: rgba(255, 255, 255, 0.5)">
                Or choose a category to quickly find what you need
              </p>
            </div>
            <div class="col-md-3 wide_space">
              <div
                :class="[active_card == 'getting_started' ? 'active_tab' : '', 'card']"
                @click="
                  active_card = 'getting_started';
                  search = false;
                "
              >
                <div class="card-body text-center">
                  <img
                    src="../../public/assets/img/help/get_started.png"
                    v-if="active_card == 'getting_started'"
                    alt=""
                    class="img-fluid"
                  />
                  <img
                    src="../../public/assets/img/help/get_started_muted.png"
                    v-else
                    alt=""
                    class="img-fluid"
                  />
                  <h4 class="font-weight-bold">Getting Started</h4>
                </div>
              </div>
            </div>
            <div class="col-md-3 wide_space">
              <div
                :class="[active_card == 'pricing' ? 'active_tab' : '', 'card']"
                @click="
                  active_card = 'pricing';
                  search = false;
                "
              >
                <div class="card-body text-center">
                  <img
                    src="../../public/assets/img/help/pricing_plan.png"
                    v-if="active_card == 'pricing'"
                    alt=""
                    class="img-fluid"
                  />
                  <img
                    src="../../public/assets/img/help/pricing_plan_muted.png"
                    v-else
                    alt=""
                    class="img-fluid"
                  />
                  <h4 class="font-weight-bold">Pricing & Plans</h4>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 wide_space"
              @click="
                active_card = 'sales';
                search = false;
              "
            >
              <div :class="[active_card == 'sales' ? 'active_tab' : '', 'card']">
                <div class="card-body text-center">
                  <img
                    src="../../public/assets/img/help/sales_question.png"
                    v-if="active_card == 'sales'"
                    alt=""
                    class="img-fluid"
                  />
                  <img
                    src="../../public/assets/img/help/sales_question_muted.png"
                    v-else
                    alt=""
                    class="img-fluid"
                  />
                  <h4 class="font-weight-bold">Sales Question</h4>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 wide_space"
              @click="
                active_card = 'user_guide';
                search = false;
              "
            >
              <div :class="[active_card == 'user_guide' ? 'active_tab' : '', 'card']">
                <div class="card-body text-center">
                  <img
                    src="../../public/assets/img/help/user_guid.png"
                    alt=""
                    v-if="active_card == 'user_guide'"
                    class="img-fluid"
                  />
                  <img
                    src="../../public/assets/img/help/user_guide_muted.png"
                    alt=""
                    v-else
                    class="img-fluid"
                  />
                  <h4 class="font-weight-bold">Usage Guides</h4>
                </div>
              </div>
            </div>

            <div class="col-md-12 mt-5" v-if="!search">
              <div v-if="active_card == 'getting_started'">
                <h3 class="text-atvyellow font-weight-bold text-center">
                  Getting Started
                </h3>
                <div
                  class="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div
                    class="panel panel-default"
                    v-for="(g, i) in GettingStarted"
                    :key="i"
                  >
                    <div class="panel-heading" role="tab">
                      <a
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        :href="'#collapse' + i"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <h4 class="panel-title">
                          <span
                            class="mr-5"
                            style="
                              height: 14px;
                              width: 14px;
                              background-color: #bbb;
                              border-radius: 50%;
                              display: inline-block;
                            "
                          ></span>
                          {{ g.title }}
                        </h4>
                      </a>
                    </div>
                    <div
                      :id="'collapse' + i"
                      :class="[i == 0 ? 'in show' : '', 'panel-collapse collapse']"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div class="panel-body" v-html="g.message"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="active_card == 'pricing'">
                <h3 class="text-atvyellow font-weight-bold text-center">
                  Pricing & Plans
                </h3>
                <div
                  class="panel-group"
                  id="price_accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="panel panel-default" v-for="(g, i) in PricingPlan" :key="i">
                    <div class="panel-heading" role="tab">
                      <a
                        role="button"
                        data-toggle="collapse"
                        data-parent="#price_accordion"
                        :href="'#price_collapseOne' + i"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <h4 class="panel-title">
                          <span
                            class="mr-5"
                            style="
                              height: 14px;
                              width: 14px;
                              background-color: #bbb;
                              border-radius: 50%;
                              display: inline-block;
                            "
                          ></span>
                          {{ g.title }}
                        </h4>
                      </a>
                    </div>
                    <div
                      :id="'price_collapseOne' + i"
                      :class="[i == 0 ? 'in show' : '', 'panel-collapse collapse']"
                      role="tabpanel"
                      aria-labelledby="price_headingOne"
                    >
                      <div class="panel-body" v-html="g.message"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="active_card == 'sales'">
                <h3 class="text-atvyellow font-weight-bold text-center">
                  Sales Question
                </h3>
                <div
                  class="panel-group"
                  id="sales_accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="panel panel-default" v-for="(g, i) in Question" :key="i">
                    <div class="panel-heading" role="tab" id="sales_headingOne">
                      <a
                        role="button"
                        data-toggle="collapse"
                        data-parent="#sales_accordion"
                        :href="'#sales_collapseOne' + i"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <h4 class="panel-title">
                          <span
                            class="mr-5"
                            style="
                              height: 14px;
                              width: 14px;
                              background-color: #bbb;
                              border-radius: 50%;
                              display: inline-block;
                            "
                          ></span>
                          {{ g.title }}
                        </h4>
                      </a>
                    </div>
                    <div
                      :id="'sales_collapseOne' + i"
                      :class="[i == 0 ? 'in show' : '', 'panel-collapse collapse']"
                      role="tabpanel"
                      aria-labelledby="sales_headingOne"
                    >
                      <div class="panel-body" v-html="g.message"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="active_card == 'user_guide'">
                <h3 class="text-atvyellow font-weight-bold text-center">Usage Guides</h3>
                <div
                  class="panel-group"
                  id="user_guide_accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  <div class="panel panel-default" v-for="(g, i) in UsageGuide" :key="i">
                    <div class="panel-heading" role="tab" id="user_guide_headingOne">
                      <a
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        :href="'#user_guide_collapseOne' + i"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <h4 class="panel-title">
                          <span
                            class="mr-5"
                            style="
                              height: 14px;
                              width: 14px;
                              background-color: #bbb;
                              border-radius: 50%;
                              display: inline-block;
                            "
                          ></span>
                          {{ g.title }}
                        </h4>
                      </a>
                    </div>
                    <div
                      :id="'user_guide_collapseOne' + i"
                      :class="[i == 0 ? 'in show' : '', 'panel-collapse collapse']"
                      role="tabpanel"
                      aria-labelledby="user_guide_headingOne"
                    >
                      <div class="panel-body" v-html="g.message"></div>

                      <div v-show="g.id == 1">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.aukiss.aukiss_app"
                          target="_blank"
                        >
                          <img
                            src="../../public/assets/img/tvandroid.png"
                            alt=""
                            class="img-fluid mr-1"
                            ype="button"
                          />
                        </a>

                        <a
                          href="https://apps.apple.com/us/app/aukiss-tv/id1534585800"
                          target="_blank"
                        >
                          <img
                            src="../../public/assets/img/apple.png"
                            alt=""
                            class="img-fluid mr-1"
                            ype="button"
                          />
                        </a>
                      </div>

                      <div v-show="g.id == 3">
                        <a
                          href="https://play.google.com/store/apps/details?id=com.aukiss.aukiss_tvapp"
                          target="_blank"
                        >
                          <img
                            src="../../public/assets/img/tvandroid.png"
                            alt=""
                            class="img-fluid mr-1"
                            ype="button"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-5" v-if="search">
              <div class="row">
                <div class="col-md-12" style="font-size: 24px">
                  <span class="font-weight-bold">Search results for </span>
                  <span>"{{ search_item }}"</span>
                </div>
                <div class="col-md-12">
                  <div
                    class="panel-group"
                    role="tablist"
                    aria-multiselectable="true"
                    v-if="search_data.length > 0"
                  >
                    <div
                      class="panel panel-default"
                      v-for="(g, i) in search_data"
                      :key="i"
                    >
                      <div class="panel-heading" role="tab">
                        <a
                          role="button"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          :href="'#collapse' + i"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <h4 class="panel-title">
                            <span
                              class="mr-5"
                              style="
                                height: 14px;
                                width: 14px;
                                background-color: #bbb;
                                border-radius: 50%;
                                display: inline-block;
                              "
                            ></span>
                            {{ g.title }}
                          </h4>
                        </a>
                      </div>
                      <div
                        :id="'collapse' + i"
                        :class="[i == 0 ? 'in show' : '', 'panel-collapse collapse']"
                        role="tabpanel"
                        aria-labelledby="headingOne"
                      >
                        <div class="panel-body" v-html="g.message"></div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="mt-4">No result(s) found...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

export default {
  name: "Help",
  data() {
    return {
      active_card: "getting_started",
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      GettingStarted: [],
      PricingPlan: [],
      Question: [],
      UsageGuide: [],
      data: {
        title: "",
      },
      search: false,
      search_data: [],
      search_item: "",
    };
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading: () => import("vue-loading-overlay"),
  },
  methods: {
    sendQuestion() {
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/searchhelpquestion", this.data)
        .then((res) => {
          if (res.data) {
            this.search_data = res.data.data;
            this.search_item = this.data.title;
            this.data.title = "";
            this.active_card = "";
            this.search = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getQuestions() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/help")
        .then((res) => {
          if ((res.data || {}).data) {
            (this.GettingStarted = res.data.data.GettingStarted),
              (this.PricingPlan = res.data.data.PricingPlan),
              (this.Question = res.data.data.Question),
              (this.UsageGuide = res.data.data.UsageGuide);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    document.title = "Help || Aukiss TV";
    this.getQuestions();
    var elements = document.getElementsByClassName('index-page sidebar-collapse');
    for(var i=0; i<elements.length; i++) {
    elements[i].removeAttribute("style");
     }
  },
};
</script>

<style scoped>
@import url("../../public/assets/css/player.css");

.card {
  background: #1c212e;
  border: 1px solid rgba(232, 232, 232, 0.3);
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(158, 145, 101, 0.1);
  border-radius: 10px;
  color: #ffffff;
}

.active_tab {
  color: #a88d30 !important;
  border: 1px solid #a88d30;
  box-sizing: border-box;
  box-shadow: 10px 20px 20px rgba(158, 145, 101, 0.1);
  border-radius: 10px;
}

.category_list li {
  margin-bottom: 1.2rem;
}

@media screen and (max-width: 767px) {
}

@media (min-width: 1200px) {
  .plyr video {
    height: 600px !important;
  }
}
</style>
