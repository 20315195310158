<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5" style="min-height: 65vh">
        <div class="custom-container-fluid">
          <div class="row mb-5">
            <div class="col-md-12">
              <div
                class="text-atvyellow cursor-pointer"
                @click="$router.go(-1)"
              >
                <span class="material-icons"> west </span>
                <span style="vertical-align: top">Back</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="p-md-5 border">
                <div class="row">
                  <div class="col-md-1 text-center">
                    <img
                      v-lazy="
                        poster_image
                          ? poster_image
                          : '../../assets/images/error.png'
                      "
                      class="img-fluid rounded-circle pt-2"
                      alt=""
                      style="width: 100px; height: 100px"
                    />
                  </div>
                  <div class="col-md-11 mt-lg-3">
                    <audio id="player" controls>
                      <source src="" type="audio/mp3" />
                      <!--<source src="/path/to/audio.ogg" type="audio/ogg" />-->
                    </audio>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="text-atvyellow font-weight-bold mb-4 mt-5 col-6">
                  <h3 class="text-atvyellow font-weight-bold">Channel List</h3>
                </div>
              </div>
            </div>

            <div class="row owl-carousel pl-3">
              <div
                class="mr-md-5 mr-3"
                v-for="(t, i) in filteredPlayer"
                :key="i"
                @click="
                  movie_info = t;
                  poster_image = t.image;
                  showVideo(t.link, t.type, t);
                "
              >
                <div class="card cursor-pointer">
                  <img
                    v-lazy="t.image ? t.image : '../../assets/images/error.png'"
                    class="card-img-top"
                    alt
                  />
                </div>
                <div class="mt-1 movie-text"></div>
                <div class="font-weight-bold">{{ t.name }}</div>
              </div>
            </div>

            <!-- </div> -->

            <!-- <div class="col-md-10 offset-md-1">
              <div class="side_media border-around">
           

                 <ul class="list-unstyled">
                  <li
                    class="media pb-4 pt-4"
                    v-for="(t, i) in filteredPlayer"
                    :key="i"
                    @click="
                      movie_info = t;
                      poster_image = t.image;
                      showVideo(t.link, t.type, t);
                    "
                  >
                    <img
                      v-lazy="
                        t.image ? t.image : '../../assets/images/error.png'
                      "
                      class="mr-3 mt-2 media_image"
                      alt="..."
                    />
                    <div class="media-body">
                      <button class="btn btn-atvyellow">Play</button>
                      <h5 class="mt-0 mb-1 font-weight-bold media-body-text">
                        {{ t.name }}
                      </h5>
                      <small>
                        {{
                          ((t || {}).description || "").length > 120
                            ? t.description.substring(0, 120) + "..."
                            : t.description
                        }}
                      </small>
                    </div>
                  </li>
                </ul>
              </div>
            </div>-->
          </div>
        </div>
      </div>

      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Plyr from "plyr";
import axios from "axios";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "live-Tv",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      playing: "",
      tv_list: {},
      movie_player: "",
      poster_image: "",
      details: [],
      filter: "",
    };
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading: () => import("vue-loading-overlay"),
  },
  computed: {
    filteredPlayer() {
      if (this.filter === "") {
        return this.details;
      }

      return this.details.filter((names) =>
        names.name.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
  },
  methods: {
    getYear(d) {
      return d
        ? moment((d || {}).toDate()).format("MMMM Do YYYY, h:mm:ss a")
        : "";
    },
    triggerPlayer(videoUrl, type) {
      this.movie_player = new Plyr("#player", {
        debug: false,
        autoplay: true,
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "pip",
          "airplay",
          "fullscreen",
        ],
        settings: ["speed"],
      });
      if (type == "youtube") {
        this.movie_player.source = {
          type: "video",
          sources: [
            {
              src: videoUrl ? videoUrl : "",
              provider: "youtube",
            },
          ],
        };
      } else {
        this.movie_player.source = {
          type: "audio",
          sources: [
            {
              src: videoUrl ? videoUrl : "",
              type: "audio/mp3",
              size: 720,
            },
            {
              src: videoUrl ? videoUrl : "",
              type: "audio/mp3",
              size: 1080,
            },
          ],
          poster: this.poster_image ? this.poster_image : "",
        };
      }
    },

    sendViews(radio_id) {
      let data = {
        user_id: (this.userInfo || {}).id,
        magazine: "",
        movie: "",
        tvshow: "",
        livetv: "",
        liveradio: radio_id,
        movie_clicked: "",
        magazine_clicked: "",
        tvshow_clicked: "",
        livetv_clicked: "",
        liveradio_clicked: 1,
      };
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/postUserView", data)
        .then((res) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    showVideo(videoUrl, type, t) {
      if (this.movie_player) {
        this.movie_player.destroy();
        this.triggerPlayer(videoUrl, type);
        var arr = (t || {}).id;
        this.sendViews(arr);
        return;
      }
      this.triggerPlayer(videoUrl, type);
    },
    stopPlayer() {
      if (this.movie_player) {
        this.movie_player.stop();
      }
    },
    removeDuplicate(data) {
      return [...new Set(data)];
    },

    getLiveTv() {
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/radiodetails", {
          radio_id: this.$route.params.id,
        })
        .then((res) => {
          this.tv_list = res.data.data;
          this.poster_image = this.tv_list.image;
          this.showVideo(this.tv_list.link);
          this.$nextTick(() => {
            exeCarousel();
          });
        })

        .finally(() => {
          this.isLoading = false;
        });
    },

    getAllRadiosGroups() {
      this.isLoading = true;
      axios
        .get(
          `https://api.aukiss.tv/api/mobileradiotypes/${this.$route.params.type}`
        )
        .then((res) => {
          if (res.data.data.length > 0) {
            this.details = res.data.data;

            this.getLiveTv();

            // this.poster_image = this.details[0].image;
            // this.showVideo(this.details[0].link);
          }
          this.$nextTick(() => {
            exeCarousel();
          });
        })

        .finally(() => {
          this.isLoading = false;
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {
            // } else {
              this.subscription = res.data.data;
              if (
                (this.subscription || {}).expired_at ||
                this.subscription == null
              ) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                }
              } else {
                this.$router.push("/subscription");
              }
            // }
          } else {
            this.$router.push("/subscription");
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    document.title = "Live Radio Player|| Aukiss TV";
    this.$loadScript("/assets/js/owl.carousel.min.js")
      .then(() => {})
      .catch(() => {});

    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getAllRadiosGroups();
    //this.getLiveTv();
    this.checkExpiration();
  },
};
</script>

<style scoped>
@import url("../../public/assets/css/owl.carousel.css");
@import url("../../public/assets/css/owl.theme.css");
@import url("../../public/assets/css/owl.transitions.css");

.dropbtn {
  background-color: white;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: white;
}
.divider {
  width: 50px;
  background: #a88d30;
  height: 6px;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
}

.media-body-text {
  color: rgba(255, 255, 255, 0.7);
}

.border-around {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem 0rem 0.5rem 1rem;
  border-radius: 5px;
}

.category_list {
  list-style: none;
  margin-top: 1.5rem;
}

.category_list li {
  margin-bottom: 1.2rem;
}

li.media {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

li.media:last-child {
  border: none;
  padding-bottom: 0px !important;
}

li.media:first-child {
  padding-top: 0px !important;
}

.media_image {
  height: 90px;
  width: 91px;
  object-fit: cover;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .media_image {
    height: 70px;
    width: 70px;
  }
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 1385px) and (min-width: 768px) {
  .media_image {
    display: none;
  }

  .media-body-text {
    font-size: 14px;
  }

  .player {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }

  .vjs-custom-skin {
    height: 90% !important;
  }

  .vjs-custom-skin /deep/ .video-js {
    width: 100% !important;
    height: 90%;
  }

  .video-js .vjs-tech {
    background-color: #000;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease;
    width: 100%;
    z-index: 1;
  }
}

.space-top {
  margin-top: 3.5rem;
}

.card-img-top[lazy="loading"] {
  object-fit: cover !important;
}

.fixed-bg {
  background-image: url("../../public/assets/img/parallax.png");
  height: 390px;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fixed-bg-sub {
  position: absolute;
  color: #fff;
  width: 55%;
  padding: 5px 10px;
  text-align: left;
  margin-left: 2rem;
  /* top: 1px; */
  margin-top: 100px;
}

@media only screen {
  .parallax {
    /* The image used */
    background-image: url("../../public/assets/img/parallax.png");
    /* Set a specific height */
    height: 350px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.parallax-caption {
  position: absolute;
  color: #fff;
  /* width: 55%; */
  /* z-index: 8; */
  padding: 5px 10px;
  text-align: left;
  margin-left: 2rem;
  /* top: 1px; */
  margin-top: 100px;
}

.nivo-caption {
  position: absolute;
  top: 60%;
  color: #fff;
  width: 55%;
  z-index: 8;
  padding: 5px 10px;
  text-align: left;
}

.carousel-caption {
  bottom: 40% !important;
  left: 5% !important;
}

.card {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  border-radius: 10px !important;
}

.best-series-card {
  margin-top: 15px !important;
}

.card-img-top {
  height: 215px !important;
  /*object-fit: cover !important;*/
  border-radius: 10px !important;
}

.cust-img-top {
  height: 160px !important;
  object-fit: cover !important;
}

.newest-episode-card {
  height: 160px !important;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .card-img-top {
    height: auto !important;
  }

  .cust-img-top {
    height: 160px !important;
    object-fit: cover !important;
  }

  .featured-series-img {
    height: 115px !important;
  }

  .best-series-first-img {
    height: auto !important;
  }

  .caption_text {
    font-size: 14px;
  }

  .newest-episode-card {
    height: auto !important;
  }

  .nivo-caption {
    top: 0%;
    width: 100%;
  }

  .parallax-caption {
    /*top: 15%;*/
    margin-left: 0px;
    width: 80%;
  }

  .fixed-bg-sub {
    margin-left: 0px;
    width: 80%;
  }

  .slider_image {
    height: 302px !important;
  }
}

.tainer::after {
  /*background: #000000c4;*/
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0) 90%,
    rgba(0, 0, 0, 0)
  ) !important;
}

.tainer {
  border-radius: 5px !important;
}

.category_list li {
  margin-bottom: 1.2rem;
}

.caption_text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (min-width: 768px) {
  .d-sm-block-custom {
    display: block !important;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1164px) {
  .slider_image {
    height: 600px !important;
  }
}

@media screen and (max-width: 1164px) and (min-width: 768px) {
  .slider_image {
    height: 400px !important;
  }

  .carousel-caption {
    bottom: 47% !important;
  }

  .parallax-caption {
    /*top: 20%;*/
    width: 80%;
  }

  .fixed-bg-sub {
    width: 80%;
  }
}
</style>
