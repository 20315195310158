<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5">
        <div class="container-fluid custom-container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-weight-bold">Browse By Category</h3>
            </div>
            <div class="col-6 col-md-3">
              <select
                class="form-control font-weight-bold"
                style="color: #a88d30"
                @change="getCategoryMovies()"
                v-model="selected_category"
              >
                <option value>All categories</option>
                <option v-for="(c, i) in categories" :key="i" :value="c">
                  {{ c }}
                </option>
              </select>
            </div>

            <div class="col-5 col-md-6">
              <!--add section here-->
              <div class="w-100" v-if="SeriesTopHeaderAd1 != null">
                <a :href="SeriesTopHeaderAd1.adUrl" target="_blank">
                  <img
                    v-lazy="SeriesTopHeaderAd1.image"
                    :alt="SeriesTopHeaderAd1.id"
                    style="width: 100%; max-height: 250px; object-fit: fill"
                  />
                </a>
              </div>
              <!--end dd section here-->
            </div>
            <div class="col-6 col-md-3">
              <selectize v-on:change="search()" :settings="settings">
                <option value selected>Search series...</option>
              </selectize>
            </div>

            <div class="col-md-12 mt-4">
              <div class="tab-content text-center">
                <div class="tab-pane active" id="profile">
                  <div class="row">
                    <div
                      class="col-md-2 col-sm-6 col-xs-6 col-6"
                      v-for="(a, i) in category_movies"
                      :key="i"
                    >
                      <router-link :to="'/series-details/' + a.id">
                        <div class="card">
                          <img
                            v-lazy="
                              a.image
                                ? a.image
                                : '../../public/assets/img/tv/2.png'
                            "
                            class="card-img-top"
                            alt
                          />
                        </div>
                        <div class="mt-1 movie-text"></div>
                        <div class="font-weight-bold">{{ a.post_title }}</div>
                      </router-link>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-12"></div>
                  </div>
                </div>
                <div class="tab-pane" id="messages">
                  <div class="row">
                    <div
                      class="col-md-2 col-sm-6 col-xs-6 col-6"
                      v-for="(c, i) in category_movies"
                      :key="i"
                    >
                      <router-link
                        :to="'/series-details/' + c.id"
                        @click.native.prevent="handleNavigation(c.id)"
                      >
                        <div class="card">
                          <img v-lazy="c.image" class="card-img-top" alt />
                        </div>
                        <div class="mt-1 movie-text">
                          <span v-if="c.movireleasedate"
                            >{{ getYear(c.movireleasedate) }},</span
                          >
                          <span v-for="(cc, p) in c.categories" :key="p">
                            {{ cc }}
                            <span v-if="c.categories.length - 1 !== p">,</span>
                          </span>
                        </div>
                        <div class="font-weight-bold">{{ c.post_title }}</div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Selectize from "vue2-selectize";

export default {
  name: "TV",
  data() {
    return {
      settings: this.search(),
      isLoading: false,
      fullPage: false,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      shows: [],
      categories: [],
      category_movies: [],
      selected_category: "",
      SeriesTopHeaderAd1: {},
      details: {
        id: this.$store.state.userInfo.id,
        city: "",
        continentCode: "",
        continentName: "",
        countryCode: "",
        countryName: "",
        ipAddress: "",
        stateProv: "",
      },
    };
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading,
    Selectize,
  },
  methods: {
    search: function () {
      var me = this;
      return {
        valueField: "type",
        labelField: "post_title",
        searchField: "post_title",
        create: false,
        open() {},
        onItemAdd: function (value, $item) {
          let data1 = value.split("/")[0].replace(/\s/g, "");
          let data2 = value.split("/")[1].replace(/\s/g, "");
          console.log(data1, data2);
          if (data1 == "alltvshows") {
            me.$router.push("/series-details/" + data2);
          } else if (data1 == "allcelebrity") {
            me.$router.push("/celebrity-profile/" + data2);
          }
        },
        render: {
          option: function (item, escape) {
            let mydata = "";
            if (item.component === "alltvshows") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="selectize_img" src="' +
                escape(item.image) +
                '" alt="">' +
                escape(item.post_title) +
                "</span>" +
                "</span>" +
                '<span class="selectize_description">' +
                escape(item.post_content) +
                "</span>" +
                '<ul class="selectize_meta">' +
                (item.movireleasedate
                  ? '<li class="language">' +
                    escape(new Date(item.movireleasedate).getFullYear()) +
                    "</li>"
                  : "") +
                "</ul>" +
                "</div>";
            } else if (item.component === "allcelebrity") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="celebrity_img" src="' +
                escape(item.photo) +
                '" alt="">' +
                escape(item.name) +
                "</span>" +
                "</span>" +
                (item.person_info
                  ? '<span class="selectize_description">' +
                    escape(item.person_info) +
                    "</span>"
                  : "") +
                "</div>";
            }
            return mydata;
          },
        },
        load: function (query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: "https://api.aukiss.tv/api/searchwebtvshow",
            type: "POST",
            data: {
              title: encodeURIComponent(query),
            },
            error: function () {
              callback();
            },
            success: function (res) {
              console.log(res.data);
              let mydata = res.data;
              if (mydata.length > 0) {
                mydata.forEach((e) => {
                  e.type = res.message + "/" + e.id;
                  e.component = res.message.replace(/\s/g, "");
                  if (e.component == "allcelebrity") {
                    e.image = e.photo;
                    e.post_title = e.name;
                    e.post_content = e.person_info;
                  }
                });
                callback(mydata);
              }
            },
          });
        },
      };
    },
    getYear(d) {
      if (d) {
        return moment(new Date(d)).format("YYYY");
      }
    },
    removeDuplicate(data) {
      return [...new Set(data)];
    },
    getCategoryMovies() {
      this.category_movies = this.shows;
      if (this.selected_category) {
        this.category_movies = this.shows.filter((e) => {
          if (e.categories.includes(this.selected_category)) {
            //console.log('e',e)
            return e;
          }
        });
      }
    },
    // getTVMovies(category) {
    //   this.category_movies = this.shows.filter((e) => {
    //     if (e.categories.includes(category)) {
    //       return e;
    //     }
    //   });
    // },
    handleNavigation(ids) {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          if (res.data.data !== null) {
            if (
              ((res.data.data || {}).user || {}).role_id == 2 ||
              ((res.data.data || {}).user || {}).role_id == 3 ||
              ((res.data.data || {}).user || {}).role_id == 4 ||
              ((res.data.data || {}).user || {}).role_id == 5
            ) {
              this.$router.push("/series-details/" + ids);
            } else {
              this.subscription = res.data.data;
              if (
                (this.subscription || {}).expired_at ||
                this.subscription == null
              ) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                } else {
                  this.$router.push("/series-details/" + ids);
                }
              } else {
                //
                this.$router.push("/subscription");
              }
            }
          } else {
            this.$router.push("/subscription");
          }
        });
    },
    getAllMovies() {
  this.isLoading = true;
  axios
    .get("https://api.aukiss.tv/api/webtvshows")
    .then((res) => {
      this.shows = res.data.data;
      this.category_movies = res.data.data;

      // Collect all categories
      this.shows.forEach((e) => {
        this.categories = this.categories.concat(e.categories);
      });

      // Remove duplicates from categories
      this.categories = this.removeDuplicate(this.categories);

      // Sort the categories alphabetically
      this.categories.sort((a, b) => a.localeCompare(b));
    })
    .finally(() => {
      this.isLoading = false;
    });
},

    getAdsData() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/adstartApp")
        .then((json) => {
          this.SeriesTopHeaderAd1 = json.data.SeriesTopHeaderAd1;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    saveInfo() {
      this.isLoading = true;

      axios
        .post("https://api.aukiss.tv/api/locationupdate", this.details)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getlocation() {
      this.isLoading = true;
      axios
        .get("https://api.db-ip.com/v2/free/self")
        .then((res) => {
          this.details.city = res.data.city;
          this.details.continentCode = res.data.continentCode;
          this.details.continentName = res.data.continentName;
          this.details.countryCode = res.data.countryCode;
          this.details.countryName = res.data.countryName;
          this.details.ipAddress = res.data.ipAddress;
          this.details.stateProv = res.data.stateProv;

          this.saveInfo();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          // console.log("res", res);
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {
            // } else {
              this.subscription = res.data.data;
              if (
                (this.subscription || {}).expired_at ||
                this.subscription == null
              ) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                }
              } else {
                this.$router.push("/subscription");
              }
            //v}
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    document.title = "TV Series || Aukiss TV";
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getAllMovies();
    this.checkExpiration();
    this.getAdsData();
    this.getlocation();
    var elements = document.getElementsByClassName('index-page sidebar-collapse');
    for(var i=0; i<elements.length; i++) {
    elements[i].removeAttribute("style");
     }
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 0px !important;
  border-radius: 10px !important;
}

.card-img-top {
  height: 280px !important;
  /*object-fit: cover !important;*/
  border-radius: 10px !important;
}

.card-img-top[lazy="loading"] {
  object-fit: cover !important;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .card-img-top {
    height: 100px !important;
  }
}
</style>
