<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <div class="section">
        <div class="tainer mb-4">
          <img
            src="../../public/assets/img/magazine/1.png"
            class="img-fluid w-100 trending_image"
          />
        </div>
        <div class="container-fluid custom-container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-weight-bold">Browse By Category</h3>
            </div>
            <div class="col-md-11">
              <div class="nav-tabs-navigation">
                <div class="nav-tabs-wrapper">
                  <ul class="nav nav-tabs" data-tabs="tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        href="#profile"
                        data-toggle="tab"
                      >
                        All</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#messages" data-toggle="tab">
                        AUKISS A+
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#profile" data-toggle="tab">
                        Books
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#messages" data-toggle="tab">
                        Music
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#profile" data-toggle="tab">
                        More
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-6 col-md-1">
              <select
                name=""
                id=""
                class="form-control font-weight-bold"
                style="color: #a88d30"
              >
                <option value="">Filter</option>
              </select>
            </div>
            <div class="col-md-12 mt-4">
              <div class="tab-content">
                <div class="tab-pane active" id="profile">
                  <div class="row">
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="messages">
                  <div class="row">
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-25">
                      <div class="card">
                        <div class="card-body">
                          <img
                            src="../../public/assets/img/shop/1.png"
                            class="img-fluid w-100"
                            alt=""
                          />
                          <h4 class="font-weight-bold text-center-sm">
                            AuKiss S-Time G1 Watch
                          </h4>
                          <h5 class="text-center-sm">$90.99</h5>
                          <div class="text-center">
                            <router-link
                              to="/product-details"
                              class="btn btn-atvyellow"
                              >Buy Now</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-5">
              <ul class="pagination pagination-atv-yellow">
                <li class="page-item">
                  <a href="javascript:void(0);" class="page-link">
                    <img
                      src="../../public/assets/img/arrow-left.png"
                      class="img-fluid"
                      alt=""
                  /></a>
                </li>
                <li class="page-item">
                  <a href="javascript:void(0);" class="page-link">1</a>
                </li>
                <li class="page-item">
                  <a href="javascript:void(0);" class="page-link">2</a>
                </li>
                <li class="active page-item">
                  <a href="javascript:void(0);" class="page-link">3</a>
                </li>
                <li class="page-item">
                  <a href="javascript:void(0);" class="page-link">4</a>
                </li>
                <li class="page-item">
                  <a href="javascript:void(0);" class="page-link">5</a>
                </li>
                <li class="page-item">
                  <a href="javascript:void(0);" class="page-link">
                    <img
                      src="../../public/assets/img/arrow-right.png"
                      class="img-fluid"
                      alt=""
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
      isLoading: false,
      fullPage: true,
      details: {},
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
    };
  },
  components: {
    topnav: () => import("@/components/topNav"),
    footernav: () => import("@/components/footer"),
  },
  created() {
    document.title = "Shop || Atv";
    var elements = document.getElementsByClassName(
      "index-page sidebar-collapse"
    );
    for (var i = 0; i < elements.length; i++) {
      elements[i].removeAttribute("style");
    }
  },

  methods: {},
};
</script>

<style scoped>
.card {
  margin-bottom: 0px !important;
  border-radius: 10px !important;
}

.card-img-top {
  height: 280px !important;
  object-fit: cover !important;
  border-radius: 10px !important;
}

.tainer::after {
  border-radius: 0px !important;
}
.trending_image {
  height: 302px;
  object-fit: cover;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 599px) {
  .text-center-sm {
    text-align: center !important;
  }
}
@media screen and (max-width: 599px) and (min-width: 300px) {
  .trending_image {
    height: 102px;
  }
}

.pagination > .page-item > .page-link,
.pagination > .page-item > span {
  border-radius: 5px !important;
}
</style>
