import Vue from "vue";
import VueRouter from "vue-router";
// import Landing_page from "../views/Landing_page.vue";
// import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import ContentCreatorRegister from "../views/content_creator_register.vue";
import Complete from "../views/Complete_registration.vue";
import Payment from "../views/Payment_method.vue";
import Movies from "../views/Movies.vue";
import Series from "../views/Series.vue";
import Store from "../views/Shop.vue";
import Product_details from "../views/Product_details.vue";
import Cart from "../views/Cart.vue";
import Movie_details from "../views/Movie_details.vue";
import Series_details from "../views/Series_details.vue";
import Celebrity_profile from "../views/Celebrity_profile.vue";
import Account_profile from "../views/Account_profile";
import Live_tv from "../views/Live_tv";
import Live_radio from "../views/Live_radio";
import Live_radio_main from "../views/Live_radio_main";
import Live_radio_groups from "../views/Live_radio_groups";
import Live_radio_details from "../views/Live_radio_details";
import Live_radio_player from "../views/Live_radio_player";
import Audio from "../views/Audio";

import Help from "../views/Help";
import Subscription_plan from "../views/Subscription_plan";
import Magazine_author_page from "../views/Magazine_author_page";
// import Homepage from "../views/Homepage";
import logout from "../views/logout";
import Reset_password from "../views/Reset_password";
import Margazine_old from "../views/Margazine_old";
import Magazine_detail from "../views/Magazine_detail";
import Magazine_category_page from "../views/Magazine_category_page";
import Subscription_plan_expired from "../views/Subscription_plan_expired";
import ReferralCode from "../views/Referral_code";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing_page",
    component: () => import("../views/Landing_page.vue"),

    // component: Landing_page,
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Homepage.vue"),
    // component: Homepage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/magazine",
    name: "Magazine",
    component: () => import("../views/Magazine.vue"),

    // component: Landing_page,
  },
  {
    path: "/login",
    name: "Login",
    // component: Login,
    component: () => import("../views/Login.vue"),

    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/referral-code",
    name: "ReferralCode",
    component: ReferralCode,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reset-password",
    name: "Reset_password",
    component: Reset_password,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/movies",
    name: "Movies",
    component: Movies,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logout",
    component: logout,
    name: "logout",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/live-tv",
    name: "Live_tv",
    component: Live_tv,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/live-radio",
    name: "Live_radio",
    component: Live_radio,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/audio",
    name: "Audio",
    component: Audio,
    meta: {
      requiresAuth: true,
    },
  },

  

  {
    path: "/radiomain",
    name: "Live_radio_main",
    component: Live_radio_main,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/radio-details/:id",
    name: "Live_radio_groups",
    component: Live_radio_groups,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/live-radio-details/:id",
    name: "Live_radio_details",
    component: Live_radio_details,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/live-radio-player/:id/:type",
    name: "Live_radio_player",
    component: Live_radio_player,
    meta: {
      requiresAuth: true,
    },
  },



  {
    path: "/celebrity-profile/:id",
    name: "Celebrity_profile",
    component: Celebrity_profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/mag",
    name: "Margazine",
    component: Margazine_old,
  },
  {
    path: "/store",
    name: "store",
    component: Store,
  },
  {
    path: "/product-details",
    name: "product_details",
    component: Product_details,
  },
  {
    path: "/magazine-author/:id",
    name: "Magazine_author_page",
    component: Magazine_author_page,
  },
  {
    path: "/magazine/category/:id",
    name: "Magazine_category_page",
    component: Magazine_category_page,
  },
  {
    path: "/magazine-details/:id",
    name: "Margazine_detail",
    component: Magazine_detail,
  },
  {
    path: "/series",
    name: "series",
    component: Series,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "Account_profile",
    component: Account_profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
  },
  {
    path: "/movie-details/:id",
    name: "Movie_details",
    component: Movie_details,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subscription",
    name: "subscription",
    component: Subscription_plan,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/subscription/expired",
    name: "subscription_expired",
    component: Subscription_plan_expired,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/series-details/:id",
    name: "Series_details",
    component: Series_details,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/complete-registration",
    name: "Complete",
    component: Complete,
  },
  {
    path: "/payment-method",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/content_signup",
    name: "ContentCreatorRegister",
    component: ContentCreatorRegister
  },
  {
    path: "/404",
    component: () => import("../views/errorPage.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
