<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5">
        <div class="container-fluid custom-container-fluid">
          <div class="row mb-5">
            <div class="col-md-12">
              <div
                class="text-atvyellow cursor-pointer"
                @click="$router.go(-1)"
              >
                <span class="material-icons">west</span>
                <span style="vertical-align: top">Back</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-weight-bold">Browse By Category</h3>
            </div>
            <div class="col-6 col-md-3">
              <select
                class="form-control font-weight-bold"
                style="color: #a88d30"
                @change="getCategoryMovies()"
                v-model="selected_category"
              >
                <option value>All categories</option>
                <option v-for="(c, i) in categories" :key="i" :value="c">
                  {{ c }}
                </option>
              </select>
            </div>

            <div class="col-5 col-md-6">
              <!--add section here-->
              <div class="w-100" v-if="RadioTopHeaderAd1 != null">
                <a :href="RadioTopHeaderAd1.adUrl" target="_blank">
                  <img
                    v-lazy="RadioTopHeaderAd1.image"
                    :alt="RadioTopHeaderAd1.id"
                    style="width: 100%; max-height: 250px; object-fit: fill"
                  />
                </a>
              </div>
              <!--end dd section here-->
            </div>

            <div class="col-6 col-md-3">
              <selectize v-on:change="radioSearch()" :settings="settings">
                <!-- settings is optional -->
                <option value selected>Find a radio,mixes,podcast...</option>
              </selectize>
            </div>

            <div class="col-md-12 mt-4">
              <div class="row" v-show="category_movies.length > 0">
                <div
                  class="col-md-2 col-sm-6 col-xs-6 col-6"
                  v-for="(c, i) in category_movies"
                  :key="i"
                >
                  <router-link :to="'/live-radio-details/' + c.id">
                    <div class="card cursor-pointer">
                      <img v-lazy="c.image" class="card-img-top" alt />
                    </div>
                    <div class="mt-1 movie-text">
                      <!-- <span v-for="(cc, p) in c.categories" :key="p"
                        >{{ cc }},
                        <span v-if="c.categories.length - 1 !== p"> </span
                      ></span>-->
                    </div>
                    <div class="font-weight-bold">{{ c.name }}</div>
                  </router-link>
                </div>
              </div>
              <div class="row" v-show="category_movies.length == 0">
                <div class="container" id="contentItem">
                  <div class="row text-center">
                    <div
                      class="
                        col-lg-10
                        offset-lg-1
                        col-sm-6
                        offset-sm-3
                        col-12
                        p-3
                        error-main
                      "
                    >
                      <div class="row">
                        <div
                          class="
                            col-lg-8 col-12 col-sm-10
                            offset-lg-2 offset-sm-1
                          "
                        >
                          <img
                            src="../../public/assets/img/comingsoon.png"
                            class="img-fluid"
                            alt
                          />
                          <p
                            class="text-atvyellow mb-4"
                            style="font-size: 25px"
                          >
                            Whoops Podcasts page is coming soon...
                          </p>

                          <router-link
                            to="/radiomain"
                            class="btn btn-atvyellow pl-5 pr-5 pt-3 pb-3 mt-5"
                            style="border-radius: 10px"
                            >GO BACK</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
      <!-- <input type="text" v-model="selecting" id="selecting" /> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Selectize from "vue2-selectize";

export default {
  name: "Radio",
  data() {
    return {
      settings: this.radioSearch(),
      selecting: "",
      isLoading: false,
      fullPage: false,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      details: [],
      categories: [],
      category_movies: [],
      selected_category: "",
      RadioTopHeaderAd1: {},
    };
  },
  // watch: {
  //     'selected': function (value) {
  //         console.log('city changed');
  //     }
  // },
  methods: {
    radioSearch: function () {
      var me = this;
      return {
        valueField: "type",
        labelField: "name",
        searchField: "name",
        create: false,
        onItemAdd: function (value, $item) {
          let data1 = value.split("/")[0].replace(/\s/g, "");
          let data2 = value.split("/")[1].replace(/\s/g, "");

          // console.lo("item", $item);

          // console.log(data1, data2);
          if (data1 == "allradios") {
            me.$router.push("/live-radio-details/" + data2);
          } else if (data1 == "allcelebrity") {
            me.$router.push("/celebrity-profile/" + data2);
          }
        },
        render: {
          option: function (item, escape) {
            let mydata = "";
            if (item.component === "allradios") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="celebrity_img" src="' +
                escape(item.image) +
                '" alt="">' +
                escape(item.name) +
                "</span>" +
                "</span>" +
                (item.description
                  ? '<span class="selectize_description">' +
                    escape(item.description) +
                    "</span>"
                  : "") +
                "</div>";
            } else if (item.component === "allcelebrity") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="celebrity_img" src="' +
                escape(item.photo) +
                '" alt="">' +
                escape(item.name) +
                "</span>" +
                "</span>" +
                (item.person_info
                  ? '<span class="selectize_description">' +
                    escape(item.person_info) +
                    "</span>"
                  : "") +
                "</div>";
            }
            return mydata;
          },
        },
        load: function (query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: "https://api.aukiss.tv/api/searchwebradio",
            type: "POST",
            data: {
              title: encodeURIComponent(query),
            },
            error: function () {
              callback();
            },
            success: function (res) {
              let mydata = res.data;

              if (mydata.length > 0) {
                mydata.forEach((e) => {
                  e.type = res.message + "/" + e.id;
                  e.component = res.message.replace(/\s/g, "");

                  if (e.component == "allcelebrity") {
                    e.image = e.photo;
                    e.post_title = e.name;
                    e.post_content = e.person_info;
                  }
                });
                callback(mydata);
              }
            },
          });
        },
      };
    },
    getYear(d) {
      if (d) {
        return moment(new Date(d)).format("YYYY");
      }
    },
    removeDuplicate(data) {
      return [...new Set(data)];
    },
    getCategoryMovies() {
      this.category_movies = this.details;
      if (this.selected_category) {
        this.category_movies = this.details.filter((e) => {
          if (e.categories.includes(this.selected_category)) {
            return e;
          }
        });
      }
    },
    getAllRadiosGroups() {
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/radiotypes", {
          type_id: this.$route.params.id,
        })
        .then((res) => {
          this.details = res.data.data;

          // console.log("data", this.details);
          this.category_movies = res.data.data;
          if (this.details.length > 0) {
            this.details.forEach((e) => {
              this.categories = this.categories.concat(e.categories);
            });
            this.categories = this.removeDuplicate(this.categories);
          } else {
            // console.log("no data");
            //this.$router.push("/404");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getAdsData() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/adstartApp")
        .then((json) => {
          this.RadioTopHeaderAd1 = json.data.RadioTopHeaderAd1;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          //console.log("res", res);
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {
            // } else {
              this.subscription = res.data.data;
              if (
                (this.subscription || {}).expired_at ||
                this.subscription == null
              ) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                }
              } else {
                this.$router.push("/subscription");
              }
            // }
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading,
    Selectize,
  },
  created() {
    document.title = "Radio || Aukiss TV";
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getAllRadiosGroups();
    this.checkExpiration();
    this.getAdsData();
  },
};
</script>

<style scoped>
@import "~selectize/dist/css/selectize.bootstrap3.css";

.dropbtn {
  background-color: white;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: white;
}

.card {
  margin-bottom: 0px !important;
  border-radius: 10px !important;
}

.card-img-top {
  height: 215px !important;
  border-radius: 10px !important;
}

.card-img-top[lazy="loading"] {
  object-fit: cover !important;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .card-img-top {
    height: auto !important;
  }
}
</style>
