<template>
  <div class="text-light">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      loader="dots"
      :is-full-page="fullPage"
      :height="height"
      :width="width"
      background-color="#6b6969"
    ></loading>
    <div class="container" id="contentItem">
      <div class="row text-center">
        <div
          class="col-lg-8 offset-lg-2 col-sm-6 offset-sm-3 col-12 p-3 error-main"
        >
          <div class="row">
            <div class="col-lg-7 col-12 col-sm-10 offset-lg-2 offset-sm-2">
              <img
                src="../../public/assets/img/logo.png"
                alt=""
                class="img-fluid mb-5"
              />

              <div class="h3 mb-4 text-left font-weight-bold">REFERRAL</div>
              <form @submit.prevent="submit">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="pull-left"
                    >Enter referral code</label
                  >
                  <input
                    type="text"
                    v-model="data.referral_code"
                    class="form-control"
                    id="referral_code"
                  />
                </div>

                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-block btn-atvyellow mb-3 h5 font-weight-bold"
                  >
                    Proceed
                  </button>

                  <!--<div class="line mb-5 mt-5"><span>or login using</span></div>-->
                  <!--<img src="../../public/assets/img/facebook.png" alt="" class="img-fluid mr-3">-->
                  <!--<img src="../../public/assets/img/twitter.png" alt="" class="img-fluid mr-3">-->
                  <!--<img src="../../public/assets/img/instagram.png" alt="" class="img-fluid mr-3">-->
                  <!--<img src="../../public/assets/img/youtube.png" alt="" class="img-fluid mr-3">-->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
    import axios from "axios";
    import Swal from "sweetalert2";
    import Loading from "vue-loading-overlay";
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        name: "Login",
        data() {
            return {
                data: {
                    referral_code: "",
                     id: this.$store.state.userInfo.id,
                 
                },
                isLoading: false,
                fullPage: true,
                height: 128,
                width: 128,

            };
        },
        components: {
            Loading
        },
        methods: {
            submit() {
                this.isLoading = true;
               
                if(this.data.referral_code === ""){
                  
                  this.$router.push('/home');
                }else {
                console.log('data here')
                axios.post("https://api.aukiss.tv/api/webupdatereferralcode",this.data)
                .then((res) => {
                    
                     this.$router.push('/home');
                    })
                    .catch(err => {
                        console.log(err)
                        Swal.fire({
                            title: "Error!",
                            html: 'Error updating Referral code',
                            icon: "error",
                            confirmButtonText: "Close"
                        });
                    }).finally(() => {
                    this.isLoading = false;
                });

                }

            }
        },
        created() {
            document.title = 'Referral  || Aukiss TV';
        }
    };
</script>


<style scoped>
    .line {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #fff;
        line-height: 0.1em;
        margin: 10px 0 20px;
    }

    .line span {
        background: #1C212E;
        padding: 0 10px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:active,
    input:-webkit-autofill:focus {
        background-color: black !important;
        color: black !important;
        -webkit-box-shadow: 0 0 0 1000px #bdbdbd inset !important;
        -webkit-text-fill-color: black !important;
    }

    /* Change the white to any color ;) */
    /*input:-webkit-autofill,*/
    /*input:-webkit-autofill:hover,*/
    /*input:-webkit-autofill:focus,*/
    /*input:-webkit-autofill:active  {*/
    /*-webkit-box-shadow: 0 0 0 30px white inset !important;*/
    /*transition: background-color 5000s ease-in-out 0s;*/

    /*}*/
    /*Change text in autofill textbox*/
    input:-webkit-autofill {
        -webkit-text-fill-color: black !important;
    }

    input:-webkit-autofill {
        color: #2a2a2a !important;
    }

    #contentItem {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .form-control {
        color: white !important;
    }
</style>
