<template>
    <div class="text-light">
        <topnav></topnav>
        <div class="main main-raised" style="margin: 0px; background-color: #1C212E; min-height: 100vh">
            <div class="section mt-3">
                <div class="custom-container-fluid">
                    <div class="row mb-5">
                        <div class="col-md-12">
                            <div class="text-atvyellow">
                                <span class="material-icons"> west </span> <span
                                style="vertical-align: top;">Back</span>
                            </div>
                            <div class="col-md-12">
                                <h2 class="font-weight-bold">Shopping Cart</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table table-bordered cart_summary">
                                    <tbody>
                                    <tr>
                                        <td class="cart_product"><a href="#">
                                            <img src="../../public/assets/img/shop/1.png"
                                                 style="width: 170px; height:120px;object-fit:cover;border-radius: 5px;"
                                                 alt="Product"></a></td>
                                        <td class="cart_description" colspan="5">
                                            <div class="product-det">
                                                <h4 class="text-atvyellow mt-0 font-weight-bold">AUKISS S-Time G1
                                                    Watch</h4>
                                                <div class="font-weight-bold">$49.88</div>
                                            </div>
                                        <td class="qty">
                                            <div class="product-variation">
                                                <form action="#" method="post">
                                                    <div class="cart-plus-minus">
                                                        <div class="numbers-row">
                                                            <div
                                                                onClick="var result = document.getElementById('qty'); var qty = result.value; if( !isNaN( qty ) &amp;&amp; qty &gt; 0 ) result.value--;return false;"
                                                                class="dec qtybutton"><i class="fa fa-minus">&nbsp;</i>
                                                            </div>
                                                            <input type="text" class="qty" title="Qty" value="1"
                                                                   maxlength="12" name="qty">
                                                            <div
                                                                onClick="var result = document.getElementById('qty'); console.log(result); var qty = result.value; if( !isNaN( qty )) result.value++;return false;"
                                                                class="inc qtybutton"><i class="fa fa-plus">&nbsp;</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="cart_product"><a href="#">
                                            <img src="../../public/assets/img/shop/1.png"
                                                 style="width: 170px; height:120px;object-fit:cover;border-radius: 5px;"
                                                 alt="Product"></a></td>
                                        <td class="cart_description" colspan="5">
                                            <h4 class="text-atvyellow mt-0 font-weight-bold">AUKISS S-Time G1 Watch</h4>
                                            <div class="font-weight-bold">$49.88</div>
                                        <td class="qty">
                                            <div class="product-variation">
                                                <form action="#" method="post">
                                                    <div class="cart-plus-minus">
                                                        <div class="numbers-row">
                                                            <div
                                                                onClick="var result = document.getElementById('qty'); var qty = result.value; if( !isNaN( qty ) &amp;&amp; qty &gt; 0 ) result.value--;return false;"
                                                                class="dec qtybutton"><i class="fa fa-minus">&nbsp;</i>
                                                            </div>
                                                            <input type="text" class="qty" title="Qty" value="1"
                                                                   maxlength="12" id="qty" name="qty">
                                                            <div
                                                                onClick="var result = document.getElementById('qty'); console.log(result); var qty = result.value; if( !isNaN( qty )) result.value++;return false;"
                                                                class="inc qtybutton"><i class="fa fa-plus">&nbsp;</i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <br><br>
                    <div class="row mt-5">
                        <div class="col-md-12 text-center">
                           <div><span class="text-atvyellow h4">Total : </span><span>$89.00</span> </div>
                            <button class="btn btn-atvyellow button mt-4" style="padding: 0.5rem 5rem">Checkout</button>
                        </div>
                    </div>
                </div>
            </div>
            <footernav></footernav>
        </div>


    </div>
</template>

<script>
    /* eslint-disable */

    export default {
        name: "Home",
        data() {
            return {
                data: {
                    email: "",
                    password: ""
                },
                isLoading: false,
                fullPage: true,
                details: {},
                height: 128,
                width: 128,
                images: ['http://localhost:8080/assets/images/products/img03.jpg', 'http://localhost:8080/assets/images/products/img07.jpg', 'http://localhost:8080/assets/images/products/img02.jpg', 'http://localhost:8080/assets/images/products/img04.jpg'],
                userInfo: this.$store.state.userInfo,
            };
        },
        components: {
            topnav: () => import('@/components/topNav'),
            footernav: () => import('@/components/footer')
        },
        // beforeDestroy(){
        //     $('.magnifier, .cursorshade, .tracker').remove();
        // },

        created() {
            // this.isLoading=true;
            this.$loadScript("/assets/js/new/jquery.flexslider.js")
                .then(() => {
                    this.$loadScript("/assets/js/new/zoom.js")
                        .then(() => {
                            runCloud();
                            flexing();
                        })
                        .catch(() => {
                        }).finally(() => {
                        // this.isLoading=false;
                    });
                })
                .catch(() => {
                });
        },

        methods: {}
    };
</script>

<style scoped>
    .table-bordered th, .table-bordered td {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
    }

    .product-variation {
        display: inline-block;
        width: 170px !important;
    }

    .numbers-row {
        border: none;
    }

    .cart_summary .qty input {
        border: none;
        text-align: center;
    }

    .cart_summary .qty {
        text-align: right;
        width: auto;
    }
    @media screen and (max-width: 599px) {
        .product-det {
            width: 180px;
        }
    }

</style>
