<template>
  <div class="text-light">
    <topnav />
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      />

  <div class="section mt-5" style="min-height: 65vh">
    <div class="custom-container-fluid">
     <div class="row">
      <div class="col-md-8">
        <div v-if="m3u8Type === 'm3u8'">
          <video-player
            ref="videoPlayer"
            class="vjs-custom-skin"
            :options="playerOptions"
            @ready="onPlayerReady($event)"
          >
          </video-player>
        </div>

        <div v-else id="oldplayer">
          <video poster="capture.jpg" id="player" playsinline controls>
            <source
              src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4"
              type="video/mp4"
            />
            <source
              src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4"
              type="video/webm"
            />
          </video>
        </div>
      </div>

      <div class="col-md-4 content-column d-none d-md-block">
        <div
          class="mr-md-5 mr-3"
          v-for="(t, i) in filteredPlayer"
          :key="i"
          @click="
            movie_info = t;
            poster_image = t.image;
            showVideo(t.link, t.type, t);
          "
        >
          <div class="card cursor-pointer">
            <img v-lazy="t.image" class="card-img-top" alt />
          </div>
          <div class="mt-1 movie-text"></div>
          <div class="font-weight-bold">{{ t.name }}</div>
        </div>
      </div>
    </div>
    

    <div class="row">
      <div class="col-md-2 mb-2 col-6 text-left">
        <div class="td-trending-now-title w-100 text-center">
          Trending Now
        </div>
      </div>
      <div class="col-md-10 magazine_trending text-left pl-md-0">
        <div>
          <div v-for="(t, i) in currentTrend" :key="i" class="pr-2">
            
            <router-link :to="'/magazine-details/' + t.id">
              <span
                v-html="
                  t.post_title.length > 80
                    ? t.post_title.substring(0, 80) + '...'
                    : t.post_title
                "
              ></span>
            </router-link>
          </div>
        </div>
      </div>
    </div>

   <div class="row d-block d-md-none mt-4">
      <div class="col-12 content-column">
        <div
          class="mr-md-5 mr-3"
          v-for="(t, i) in filteredPlayer"
          :key="i"
          @click="
            movie_info = t;
            poster_image = t.image;
            showVideo(t.link, t.type, t);
          "
        >
          <div class="card cursor-pointer">
            <img v-lazy="t.image" class="card-img-top" alt />
          </div>
          <div class="mt-1 movie-text"></div>
          <div class="font-weight-bold">{{ t.name }}</div>
        </div>
      </div>
    </div> 
  </div>
</div>

    </div>
    <footernav />
  </div>
</template>

<script>
/* eslint-disable */
import Plyr from "plyr";
import axios from "axios";
import "vue-loading-overlay/dist/vue-loading.css";
//import videojs from "video.js";
import VideoPlayer from "@/components/VideoPlayer.vue";
import Selectize from "vue2-selectize";

export default {
  name: "live-Tv",
  data() {
    return {
      settings: this.test(),
      selecting: "",
      activeLink: "Overview",
      player: "",
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      playing: "",
      tv_list: [],
      Trend: [],
      tvdata: [],
      movie_player: "",
      players: "",
      poster_image: "",
      m3u8Type: "m3u8",
      initialized: false,
      currentTech: "",
      m3u8_video_url: "",
      currentIndex: 0,
      input: "",
      filterdone: "false",
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
      },
      videoSrc: "",
      details: {
        id: this.$store.state.userInfo.id,
        city: "",
        continentCode: "",
        continentName: "",
        countryCode: "",
        countryName: "",
        ipAddress: "",
        stateProv: "",
      },
    };
  },

  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading: () => import("vue-loading-overlay"),
    VideoPlayer,
    Selectize,
  },

  computed: {
    filteredPlayer() {
      // if (this.filter === "") {
      //   return this.tvdata;
      // }

      return (
        this.tv_list.filter((names) =>
          (names.name || "")
            .toLowerCase()
            .includes((this.input || "").toLowerCase())
        ) || this.tv_list
      );
    },
    currentTrend() {
      return [this.Trend[this.currentIndex]];
    }
  },

  methods: {
    test: function() {
      var me = this;
      return {
        valueField: "type",
        labelField: "post_title",
        searchField: "post_title",
        create: false,
        onItemAdd: function(value, $item) {
          let data1 = value.split("/")[0].replace(/\s/g, "");
          let data2 = value.split("/")[1].replace(/\s/g, "");
          console.log(data1, "data1");
          console.log("data2", data2);
          console.log("valuevalueddd", value);
          console.log("itemitemitemitem", $item);
          if (data1 == "allmovies") {
            me.showVideo("/movie-details/" + data2);
          } else if (data1 == "allcelebrity") {
            me.$router.push("/celebrity-profile/" + data2);
          }
        },
        render: {
          option: function(item, escape) {
            console.log("item", item);
            let mydata = "";
            if (item.component === "allmovies") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="selectize_img" src="' +
                escape(item.image) +
                '" alt="">' +
                escape(item.post_title) +
                "</span>" +
                "</span>" +
                '<span class="selectize_description">' +
                escape(item.post_content) +
                "</span>" +
                '<ul class="selectize_meta">' +
                (item.movireleasedate
                  ? '<li class="language">' +
                    escape(new Date(item.movireleasedate).getFullYear()) +
                    "</li>"
                  : "") +
                "</ul>" +
                "</div>";
            } else if (item.component === "allcelebrity") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="celebrity_img" src="' +
                escape(item.photo) +
                '" alt="">' +
                escape(item.name) +
                "</span>" +
                "</span>" +
                (item.person_info
                  ? '<span class="selectize_description">' +
                    escape(item.person_info) +
                    "</span>"
                  : "") +
                "</div>";
            }
            return mydata;
          },
        },
        load: function(query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: "https://api.aukiss.tv/api/searchwebmovie",
            type: "POST",
            data: {
              title: encodeURIComponent(query),
            },
            error: function() {
              callback();
            },
            success: function(res) {
              let mydata = res.data;
              if (mydata.length > 0) {
                mydata.forEach((e) => {
                  e.type = res.message + "/" + e.id;
                  e.component = res.message.replace(/\s/g, "");
                  if (e.component == "allcelebrity") {
                    e.image = e.photo;
                    e.post_title = e.name;
                    e.post_content = e.person_info;
                  }
                });
                callback(mydata);
              }
            },
          });
        },
      };
    },
    getYear(d) {
      return d
        ? moment((d || {}).toDate()).format("MMMM Do YYYY, h:mm:ss a")
        : "";
    },
    getMagazineData() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/admagazine")
        .then((json) => {
          //console.log("trend", json.data.data);
          this.Trend = json.data.data;

          this.$nextTick(() => {
            exeCarousel();
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateTrend() {
      this.currentIndex = (this.currentIndex + 1) % this.Trend.length;
    },

    // onPlayerPlay(player) {
    //   console.log("on player");
    //   //this.playVideo(this.videoSrc);
    //   //this.player.play();
    // },
    onPlayerReady(player) {
      console.log("player ready!", player);
      this.player = player;
      this.playVideo(this.videoSrc);
      this.player.play();
    },
    playVideo: function(source) {
      console.log(source);
      const video = {
        withCredentials: false,
        type: "application/x-mpegurl",
        src: source,
      };
      //this.player.dispose();
      this.player.reset(); // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video);

      //this.player.load();
      this.player.play();
    },

    onTimeupdate(e) {
      console.log("currentTime", e.cache_.currentTime);
    },

    triggerPlayer(videoUrl, type) {
      this.videoSrc = videoUrl;
      this.player = this.$refs.videoPlayer;
      var data = 1;

      if (type !== "m3u8") {
        if (data.length < 1) {
          $("#oldplayer").remove();

          data = data + 1;
        }
        console.log("data", data);

        this.$nextTick(() => {
          this.movie_player = new Plyr("#player", {
            debug: false,
            autoplay: true,
            displayDuration: false,
            settings: ["speed", "current-time", "duration"],
          });
          if (type === "youtube") {
            this.movie_player.source = {
              type: "video",
              sources: [
                {
                  src: videoUrl ? videoUrl : "",
                  provider: "youtube",
                },
              ],
            };
          } else {
            this.movie_player.source = {
              type: "video",
              sources: [
                {
                  src: videoUrl ? videoUrl : "",
                  type: "video/mp4",
                  size: 720,
                },
                {
                  src: videoUrl ? videoUrl : "",
                  type: "video/webm",
                  size: 1080,
                },
              ],
              poster: this.poster_image ? this.poster_image : "",
            };
          }
        });
      } else {
        if (this.player) {
          this.player = this.$refs.videoPlayer.player;
          this.player.reset();
          this.playVideo(videoUrl);
        }
      }
    },

    sendViews(tv_id) {
      let data = {
        user_id: (this.userInfo || {}).id,
        magazine: "",
        movie: "",
        tvshow: "",
        livetv: tv_id,
        liveradio: "",
        movie_clicked: "",
        magazine_clicked: "",
        tvshow_clicked: "",
        livetv_clicked: 1,
        liveradio_clicked: "",
      };
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/postUserView", data)
        .then((res) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    showVideo(videoUrl, type, t) {
      this.m3u8Type = type;
      if (type === "m3u8") {
        this.movie_player = "";
        if (this.movie_player) {
          this.movie_player.stop();
          this.movie_player.destroy();
        }
      } else {
        if (this.movie_player) {
          this.movie_player.destroy();
        }
      }
      this.triggerPlayer(videoUrl, type);
      let arr = (t || {}).id;
      this.sendViews(arr);
    },
    stopPlayer() {
      if (this.movie_player) {
        this.movie_player.stop();
      }
    },

    saveInfo() {
      this.isLoading = true;

      axios
        .post("https://api.aukiss.tv/api/locationupdate", this.details)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getlocation() {
      this.isLoading = true;
      axios
        .get("https://api.db-ip.com/v2/free/self")
        .then((res) => {
          this.details.city = res.data.city;
          this.details.continentCode = res.data.continentCode;
          this.details.continentName = res.data.continentName;
          this.details.countryCode = res.data.countryCode;
          this.details.countryName = res.data.countryName;
          this.details.ipAddress = res.data.ipAddress;
          this.details.stateProv = res.data.stateProv;

          this.saveInfo();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getLiveTv() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/livetv")
        .then((res) => {
          this.tv_list = res.data.data;
          this.tvdata = res.data.data;
          if (this.tv_list.length > 0) {
            // console.log("res", this.tv_list);
            this.poster_image = this.tv_list[0].image;
            this.showVideo(this.tv_list[0].link, this.tv_list[0].type);
          }
          // this.$nextTick(() => {
          //   exeCarousel();
          // });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          // console.log("res", res);
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {
            // } else {
            this.subscription = res.data.data;
            if (
              (this.subscription || {}).expired_at ||
              this.subscription == null
            ) {
              if (this.checkDate()) {
                this.$router.push("/subscription/expired");
              }
            } else {
              this.$router.push("/subscription");
            }
            // }
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    setInterval(this.updateTrend, 10000); // 30 seconds
  },

  created() {
    document.title = "Live TV || Aukiss TV";
    this.$loadScript("/assets/js/owl.carousel.min.js")
      .then(() => {
        this.$nextTick(() => {
          this.getMagazineData();
        });
        this.getLiveTv();
        this.checkExpiration();
        this.getlocation();
        var elements = document.getElementsByClassName(
          "index-page sidebar-collapse"
        );
        for (var i = 0; i < elements.length; i++) {
          elements[i].removeAttribute("style");
        }
      })
      .catch(() => {});
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
  },
};
</script>

<style scoped>
@import url("../../public/assets/css/owl.carousel.css");
@import url("../../public/assets/css/owl.theme.css");
@import url("../../public/assets/css/owl.transitions.css");
@import "~selectize/dist/css/selectize.bootstrap3.css";
@import url("../../public/assets/css/player.css");





.dropbtn {
  background-color: white;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: white;
}
.divider {
  width: 50px;
  background: #a88d30;
  height: 6px;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
}

.media-body-text {
  color: rgba(255, 255, 255, 0.7);
}

.border-around {
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 1rem 0rem 0.5rem 1rem;
  border-radius: 5px;
}

.category_list {
  list-style: none;
  margin-top: 1.5rem;
}

.category_list li {
  margin-bottom: 1.2rem;
}

li.media {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

li.media:last-child {
  border: none;
  padding-bottom: 0px !important;
}

li.media:first-child {
  padding-top: 0px !important;
}

.media_image {
  height: 90px;
  width: 91px;
  object-fit: cover;
}



@media screen and (max-width: 599px) and (min-width: 300px) {
  .media_image {
    height: 70px;
    width: 70px;
  }
  .td-trending-now-title {
  background-color: #A78C30;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2px 5px 1px;
  display: inline-block;
  line-height: 20px;
  -webkit-transition: background-color 10s;
  transition: background-color 10s;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.td-trending-now-wrapper .td-next-prev-wrap {
  margin: 0 0 0 auto;
  z-index: 1;
}

.td-trending-now-display-area .entry-title {
  font-size: 15px;
  line-height: 10px;
  margin: 0;
}

.entry-title {
  word-wrap: break-word;
}

.td-next-prev-wrap a {
  display: inline-block;
  margin-right: 7px;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid #dcdcdc;
  text-align: center;
  vertical-align: middle;
  font-size: 7px;
  color: #b7b7b7;
}
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 1385px) and (min-width: 768px) {
  .media_image {
    display: none;
  }

  .media-body-text {
    font-size: 14px;
  }


  .player {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }

  .vjs-custom-skin {
    height: 90% !important;
  }

  .vjs-custom-skin /deep/ .video-js {
    width: 100% !important;
    height: 90%;
  }

  .video-js .vjs-tech {
    background-color: #000;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.2s ease;
    width: 100%;
    z-index: 1;
  }
  .td-trending-now-title {
  background-color: #A78C30;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2px 5px 1px;
  display: inline-block;
  line-height: 20px;
  -webkit-transition: background-color 10s;
  transition: background-color 10s;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.td-trending-now-wrapper .td-next-prev-wrap {
  margin: 0 0 0 auto;
  z-index: 1;
}

.td-trending-now-display-area .entry-title {
  font-size: 15px;
  line-height: 10px;
  margin: 0;
}

.entry-title {
  word-wrap: break-word;
}

.td-next-prev-wrap a {
  display: inline-block;
  margin-right: 7px;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid #dcdcdc;
  text-align: center;
  vertical-align: middle;
  font-size: 7px;
  color: #b7b7b7;
}
}

.space-top {
  margin-top: 3.5rem;
}

.card-img-top[lazy="loading"] {
  object-fit: cover !important;
}

.fixed-bg {
  background-image: url("../../public/assets/img/parallax.png");
  height: 390px;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fixed-bg-sub {
  position: absolute;
  color: #fff;
  width: 55%;
  padding: 5px 10px;
  text-align: left;
  margin-left: 2rem;
  /* top: 1px; */
  margin-top: 100px;
}

@media only screen {
  .parallax {
    /* The image used */
    background-image: url("../../public/assets/img/parallax.png");
    /* Set a specific height */
    height: 350px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content-column {
    height: 800px;
    overflow-y: auto;
  }
  .td-trending-now-title {
  background-color: #A78C30;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2px 5px 1px;
  display: inline-block;
  line-height: 20px;
  -webkit-transition: background-color 10s;
  transition: background-color 10s;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.td-trending-now-wrapper .td-next-prev-wrap {
  margin: 0 0 0 auto;
  z-index: 1;
}

.td-trending-now-display-area .entry-title {
  font-size: 15px;
  line-height: 10px;
  margin: 0;
}

.entry-title {
  word-wrap: break-word;
}

.td-next-prev-wrap a {
  display: inline-block;
  margin-right: 7px;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid #dcdcdc;
  text-align: center;
  vertical-align: middle;
  font-size: 7px;
  color: #b7b7b7;
}
}

.parallax-caption {
  position: absolute;
  color: #fff;
  /* width: 55%; */
  /* z-index: 8; */
  padding: 5px 10px;
  text-align: left;
  margin-left: 2rem;
  /* top: 1px; */
  margin-top: 100px;
}

.nivo-caption {
  position: absolute;
  top: 60%;
  color: #fff;
  width: 55%;
  z-index: 8;
  padding: 5px 10px;
  text-align: left;
}

.carousel-caption {
  bottom: 40% !important;
  left: 5% !important;
}

.card {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  border-radius: 10px !important;
  width: 200px;
}

.best-series-card {
  margin-top: 15px !important;
}

.card-img-top {
  height: 100px !important;

  /*object-fit: cover !important;*/
  border-radius: 10px !important;
}

.cust-img-top {
  height: 100px !important;
  object-fit: cover !important;
}

.newest-episode-card {
  height: 160px !important;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .card-img-top {
    height: 70px !important;
  }

  .cust-img-top {
    height: 160px !important;
    object-fit: cover !important;
  }

  .featured-series-img {
    height: 115px !important;
  }

  .best-series-first-img {
    height: auto !important;
  }

  .caption_text {
    font-size: 14px;
  }

  .newest-episode-card {
    height: auto !important;
  }

  .nivo-caption {
    top: 0%;
    width: 100%;
  }

  .parallax-caption {
    /*top: 15%;*/
    margin-left: 0px;
    width: 80%;
  }

  .fixed-bg-sub {
    margin-left: 0px;
    width: 80%;
  }

  .slider_image {
    height: 302px !important;
  }
  .content-column {
    height: 300px;
    overflow-y: auto;
  }
  .td-trending-now-title {
  background-color: #A78C30;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 2px 5px 1px;
  display: inline-block;
  line-height: 20px;
  -webkit-transition: background-color 10s;
  transition: background-color 10s;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
}

.td-trending-now-wrapper .td-next-prev-wrap {
  margin: 0 0 0 auto;
  z-index: 1;
}

.td-trending-now-display-area .entry-title {
  font-size: 15px;
  line-height: 10px;
  margin: 0;
}

.entry-title {
  word-wrap: break-word;
}

.td-next-prev-wrap a {
  display: inline-block;
  margin-right: 7px;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid #dcdcdc;
  text-align: center;
  vertical-align: middle;
  font-size: 7px;
  color: #b7b7b7;
}
}

.tainer::after {
  /*background: #000000c4;*/
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0) 90%,
    rgba(0, 0, 0, 0)
  ) !important;
}

.tainer {
  border-radius: 5px !important;
}

.category_list li {
  margin-bottom: 1.2rem;
}

.caption_text {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (min-width: 768px) {
  .d-sm-block-custom {
    display: block !important;
  }
  .content-column {
    height: 700px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1164px) {
  .slider_image {
    height: 600px !important;
  }
  .content-column {
    height: 480px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1164px) and (min-width: 768px) {
  .slider_image {
    height: 400px !important;
  }

  .carousel-caption {
    bottom: 47% !important;
  }

  .parallax-caption {
    /*top: 20%;*/
    width: 80%;
  }

  .fixed-bg-sub {
    width: 80%;
  }

  .content-column {
    height: 700px;
    overflow-y: auto;
  }
}
</style>
