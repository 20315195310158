<template>
    <div class="text-light">
        <topnav></topnav>
        <div class="main main-raised" style="margin: 0px; background-color: #1C212E; min-height: 100vh">
            <div class="section mt-5">
                <div class="container-fluid custom-container-fluid">
                    <div class="row">
                        <div class="col-md-8 wide_space">
                            <div class="row">
                                <div class="col-6">
                                    <h3 class="text-atvyellow pull-left font-weight-bold">Trending</h3>
                                </div>
                                <div class="col-6">
                                    <h5 class="pull-right mt-4 text-muted font-weight-bold">View all</h5>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="row mt-3">
                        <div class="col-md-8 wide_space">
                            <div class="tainer mb-4">
                                <img src="../../public/assets/img/magazine/2.png"
                                     class="img-fluid w-100 trending_image" alt="" style="border-radius:5px;">
                                <div class="nivo-caption mb-3">
                                    <button class="btn btn-atvyellow">Carribean</button>
                                    <h3 class="font-weight-bold caption_text">Barbados to remove queen of england as
                                        their head of state</h3>
                                    <div class="caption_text">Nate - <span>September 19, 2020.</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="side_media border-around">
                                <ul class="list-unstyled">
                                    <li class="media">
                                        <img src="../../public/assets/img/media/1.png" class="mr-3 mt-2 media_image"
                                             alt="...">
                                        <div class="media-body">
                                            <button class="btn btn-atvyellow">Business</button>
                                            <h5 class="mt-0 mb-1 font-weight-bold media-body-text">Emirates Returns AED
                                                5 Million
                                                To Customers In Refunds</h5>
                                            <div class="media-body-text">Nate - September 19, 2020.</div>
                                            <hr class="hr-muted">
                                        </div>
                                    </li>
                                    <li class="media">
                                        <img src="../../public/assets/img/media/1.png" class="mr-3 mt-2 media_image"
                                             alt="...">
                                        <div class="media-body">
                                            <button class="btn btn-atvyellow">Business</button>
                                            <h5 class="mt-0 mb-1 font-weight-bold media-body-text">Emirates Returns AED
                                                5 Million
                                                To Customers In Refunds</h5>
                                            <div class="media-body-text">Nate    -    September 19, 2020.</div>
                                            <hr class="hr-muted">
                                        </div>
                                    </li>
                                    <li class="media">
                                        <img src="../../public/assets/img/media/1.png" class="mr-3 mt-2 media_image"
                                             alt="...">
                                        <div class="media-body">
                                            <button class="btn btn-atvyellow">Business</button>
                                            <h5 class="mt-0 mb-1 font-weight-bold media-body-text">Emirates Returns AED
                                                5 Million
                                                To Customers In Refunds</h5>
                                            <div class="media-body-text">Nate    -    September 19, 2020.</div>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-8 wide_space">
                            <div class="row">
                                <div class="col-6">
                                    <h3 class="text-atvyellow pull-left font-weight-bold">Business</h3>
                                </div>
                                <div class="col-6">
                                    <h5 class="pull-right mt-4 text-muted font-weight-bold">View all</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card custom_card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="tainer">
                                                        <img src="../../public/assets/img/magazine/2.png" alt=""
                                                             class="img-fluid w-100"
                                                             style="border-radius: 5px; max-height: 304px; object-fit: cover">
                                                    </div>
                                                    <h3 class="font-weight-bold">Bill Gate Is Remembering His Father
                                                        With A Moving Message</h3>
                                                    <div class="text-muted">Nate - September 19, 2020.</div>
                                                    <p style="font-size: 17px;" class="mt-4 mb-5">Lorem ipsum dolor sit
                                                        amet,
                                                        consectetur adipiscing elit. Commodo malesuada varius malesuada
                                                        purus
                                                        non in.</p>
                                                </div>
                                                <div class="col-md-6">
                                                    <ul class="list-unstyled">
                                                        <li class="media">
                                                            <img src="../../public/assets/img/media/1.png"
                                                                 class="mr-3 media_image"

                                                                 alt="...">
                                                            <div class="media-body">
                                                                <button class="btn btn-atvyellow">Business</button>
                                                                <h5 class="mt-0 mb-1 font-weight-bold media-body-text">
                                                                    Emirates
                                                                    Returns AED
                                                                    5 Million
                                                                    To Customers In Refunds</h5>
                                                                <div
                                                                    class="media-body-text">Nate    -    September 19, 2020.</div>
                                                                <hr class="hr-muted" style="margin-right: -30px;">
                                                            </div>
                                                        </li>
                                                        <li class="media">
                                                            <img src="../../public/assets/img/media/1.png"
                                                                 class="mr-3 media_image"

                                                                 alt="...">
                                                            <div class="media-body">
                                                                <button class="btn btn-atvyellow">Business</button>
                                                                <h5 class="mt-0 mb-1 font-weight-bold media-body-text">
                                                                    Emirates
                                                                    Returns AED
                                                                    5 Million
                                                                    To Customers In Refunds</h5>
                                                                <div
                                                                    class="media-body-text">Nate    -    September 19, 2020.</div>
                                                                <hr class="hr-muted" style="margin-right: -30px;">
                                                            </div>
                                                        </li>
                                                        <li class="media">
                                                            <img src="../../public/assets/img/media/1.png"
                                                                 class="mr-3 media_image"

                                                                 alt="...">
                                                            <div class="media-body">
                                                                <button class="btn btn-atvyellow">Business</button>
                                                                <h5 class="mt-0 mb-1 font-weight-bold media-body-text">
                                                                    Emirates
                                                                    Returns AED
                                                                    5 Million
                                                                    To Customers In Refunds</h5>
                                                                <div
                                                                    class="media-body-text">Nate    -    September 19, 2020.</div>
                                                            </div>
                                                        </li>
                                                    </ul>

                                                    <!--</div>-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <h3 class="text-atvyellow pull-left font-weight-bold">Lifestyle</h3>
                                </div>
                                <div class="col-6">
                                    <h5 class="pull-right mt-4 text-muted font-weight-bold">View all</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="card custom_card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="card">
                                                        <div class="tainer">
                                                            <img src="../../public/assets/img/magazine/3.png"
                                                                 class="card-img-top w-100" style="border-radius: 5px; height: 265px; object-fit: cover">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="card">
                                                        <div class="tainer">
                                                            <img src="../../public/assets/img/magazine/4.png"
                                                                 class="card-img-top w-100" style="border-radius: 5px; height: 265px; object-fit: cover">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="card">
                                                        <div class="tainer">
                                                            <img src="../../public/assets/img/magazine/5.png"
                                                                 class="card-img-top w-100" style="border-radius: 5px; height: 265px; object-fit: cover">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text-center border-around mt-4">
                                <div class="row">
                                    <div class="col-10 offset-1">
                                        <h3 class="font-weight-bold">Stay Connected</h3>
                                        <div class="divider"></div>
                                        <p class="mt-3 mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et
                                            non aliquam enim, ut platea pellentesque id leo.</p>
                                        <div class="mb-4">
                                            <img src="../../public/assets/img/facebook.png" alt=""
                                                 class="img-fluid mr-4">
                                            <img src="../../public/assets/img/twitter.png" alt=""
                                                 class="img-fluid mr-4">
                                            <img src="../../public/assets/img/youtube.png" alt=""
                                                 class="img-fluid mr-4">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="border-around mt-5">
                                <h3 class="font-weight-bold text-center">Categories</h3>
                                <div class="divider"></div>
                                <div class="row">
                                    <div class="col-md-10 offset-md-2">
                                        <ul class="category_list">
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Business</span></div>
                                                    <div class="col-3"><span>(20)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Lifestyle</span></div>
                                                    <div class="col-3"><span>(20)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Trending</span></div>
                                                    <div class="col-3"><span>(15)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Fashion</span></div>
                                                    <div class="col-3"><span>(0)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Inspiration</span></div>
                                                    <div class="col-3"><span>(12)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Lifestyle</span></div>
                                                    <div class="col-3"><span>(20)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Culture</span></div>
                                                    <div class="col-3"><span>(20)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Technology</span></div>
                                                    <div class="col-3"><span>(20)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Africa</span></div>
                                                    <div class="col-3"><span>(20)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Entertainment</span></div>
                                                    <div class="col-3"><span>(20)</span></div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="row">
                                                    <div class="col-2"><i
                                                        class="fa fa-chevron-right text-atvyellow"></i></div>
                                                    <div class="col-6"><span class="">Energy</span></div>
                                                    <div class="col-3"><span>(20)</span></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <footernav></footernav>
        </div>


    </div>
</template>

<script>
    /* eslint-disable */

    export default {
        name: "Home",
        data() {
            return {
                data: {
                    email: "",
                    password: ""
                },
                isLoading: false,
                fullPage: true,
                details: {},
                height: 128,
                width: 128,
                userInfo: this.$store.state.userInfo,
            };
        },
        components: {
            topnav: () => import('@/components/darktopNav'),
            footernav: () => import('@/components/footer')
        },
        created() {
            document.title = 'Margazine || Atv';
        },

        methods: {}
    };
</script>

<style scoped>
    .nivo-caption {
        position: absolute;
        top: 60%;
        color: #fff;
        width: 55%;
        z-index: 8;
        padding: 5px 10px;
        text-align: left;
    }

    .trending_image {
        height: 502px;
        object-fit: cover;
    }

    .divider {
        width: 50px;
        background: #A88D30;
        height: 6px;
        margin: 0 auto;
        text-align: center;
        border-radius: 5px;
    }

    .custom_card {
        border: 1px solid rgba(255, 255, 255, 0.3) !important;
        background: none;
        color: white !important;
    }

    .border-around {
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 1rem 0rem 0.5rem 1rem;
        border-radius: 5px;
    }

    .tainer {
        border-radius: 5px !important;
    }

    .category_list {
        list-style: none;
        margin-top: 1.5rem;
    }

    .category_list li {
        margin-bottom: 1.2rem;
    }

    .media_image {
        height: 130px;
        width: 131px;
        object-fit: cover;
    }

    @media screen and (max-width: 599px) and (min-width: 300px) {
        .caption_text {
            font-size: 14px;
        }
        .nivo-caption{
            top:50%;
            width: 100%;
        }

        .media_image {
            height: 70px;
            width: 70px;
        }

        .trending_image {
            height: 302px;
        }
    }

    @media screen and (max-width: 767px) {
        .side_media {
            /*margin-top: 1.5rem;*/
        }

    }

    @media screen and (max-width: 1385px) and (min-width: 768px) {
        .media_image {
            display: none;
        }
        .nivo-caption{
            width: 100%;
        }

        .media-body-text {
            font-size: 14px;
        }
    }


</style>
