<template>
  <div class="text-light">
    <topnav></topnav>
    <div class="main main-raised" style="margin: 0px; background-color: #1c212e">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5" style="min-height: 60vh">
        <div class="custom-container-fluid" v-if="profile_found === false">
          <div class="row text-center">
            <div class="col-lg-10 offset-lg-1 col-sm-6 offset-sm-3 col-12 p-3 error-main">
              <div class="row">
                <div class="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
                  <img src="../../public/assets/img/404.png" class="img-fluid" alt="" />
                  <p class="text-atvyellow mb-4" style="font-size: 25px">
                    Category not found...
                  </p>
                  <p>Sorry the category your looking for cannot be found.</p>
                  <router-link
                    to="/magazine"
                    class="btn btn-atvyellow pl-5 pr-5 pt-3 pb-3 mt-5"
                    style="border-radius: 10px"
                    >GO TO MAGAZINE
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container" v-if="profile_found === true">
          <div class="row">
            <div class="col-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/magazine">Magazine</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ $route.params.id }}
                  </li>
                </ol>
              </nav>
            </div>
            <div class="col-12">
              <h3 class="mt-0 font-weight-bold">{{ $route.params.id }}</h3>
            </div>
          </div>
          <div class="row mt-3 mb-4">
            <div
              class="col-md-6 pr-md-1 all left_big"
              v-for="(t, i) in PostData.slice(0, 1)"
              :key="i + 'a'"
            >
              <div class="tainer">
                <img
                  v-lazy="t.image"
                  class="img-fluid left_big_image"
                  :alt="t.post_title"
                />
              </div>
              <div class="meta-info">
                <a class="post-category mr-1">
                  {{ $route.params.id }}
                </a>
                <div class="left_big_info">
                  <h3 class="post-title">
                    <router-link :to="'/magazine-details/' + t.id" :title="t.post_title">
                      {{ t.post_title }}
                    </router-link>
                  </h3>
                </div>
                <div class="td-editor-date">
                  <span class="post-author"
                    ><a href="https://aukissmagazine.com/author/aukiss416/">{{
                      t.post_author
                    }}</a>
                    <span v-if="t.post_date && t.post_author">-</span>
                  </span>
                  <span class="post-date"
                    ><time class="font-weight-bold">{{
                      getYear(t.post_date)
                    }}</time></span
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6 pl-md-25">
              <div class="row side-right">
                <div
                  class="col-md-6 pr-md-1 pl-md-0 all"
                  v-for="(t, i) in PostData.slice(1, 3)"
                  :key="i + 'a'"
                >
                  <div class="tainer">
                    <img
                      v-lazy="t.image"
                      class="img-fluid right_bottom_image"
                      :alt="t.post_title"
                    />
                  </div>
                  <div class="meta-info">
                    <a class="post-category mr-1">{{ $route.params.id }} </a>
                    <div class="">
                      <h3 class="post-title-sm">
                        <router-link
                          :to="'/magazine-details/' + t.id"
                          :title="t.post_title"
                        >
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                    </div>
                    <div class="td-editor-date">
                      <span class="post-author"
                        ><a>{{ t.post_author }}</a>
                        <span v-if="t.post_date && t.post_author">-</span>
                      </span>
                      <span class="post-date"
                        ><time class="font-weight-bold">{{
                          getYear(t.post_date)
                        }}</time></span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-6 pr-md-1 pl-md-0 all"
                  v-for="(t, i) in PostData.slice(3, 5)"
                  :key="i + 'b'"
                >
                  <div class="tainer">
                    <img
                      v-lazy="t.image"
                      class="img-fluid right_bottom_image"
                      :alt="t.post_title"
                    />
                  </div>
                  <div class="meta-info">
                    <a class="post-category mr-1">{{ $route.params.id }} </a>
                    <div class="">
                      <h3 class="post-title-sm">
                        <router-link
                          :to="'/magazine-details/' + t.id"
                          :title="t.post_title"
                        >
                          {{ t.post_title }}
                        </router-link>
                      </h3>
                    </div>
                    <div class="td-editor-date">
                      <span class="post-author"
                        ><a>{{ t.post_author }}</a>
                        <span v-if="t.post_date && t.post_author">-</span>
                      </span>
                      <span class="post-date"
                        ><time class="font-weight-bold">{{
                          getYear(t.post_date)
                        }}</time></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end of top PostData section-->

          <div class="row mt-5">
            <div
              class="col-md-6"
              v-for="(t, i) in PostData.slice(5, PostData.length)"
              :key="i + 'c'"
            >
              <div class="mb-4">
                <div style="position: relative">
                  <img
                    v-lazy="t.image"
                    :alt="t.post_title"
                    class="img-fluid w-100"
                    style="max-height: 304px; object-fit: cover"
                  />
                  <a class="td-post-category">{{ $route.params.id }}</a>
                </div>
                <div class="td-module-meta-info">
                  <h3 class="entry-title">
                    <router-link :to="'/magazine-details/' + t.id">
                      {{ t.post_title }}
                    </router-link>
                  </h3>
                  <div class="td-editor-date">
                    <span class="post-author"
                      ><a>{{ t.post_author }}</a>
                      <span v-if="t.post_date && t.post_author">-</span>
                    </span>
                    <span class="post-date"
                      ><time class="font-weight-bold">{{
                        getYear(t.post_date)
                      }}</time></span
                    >
                    <span class="td-module-comments"
                      ><span href="">{{ t.comment_count }}</span></span
                    >
                  </div>

                  <div class="td-excerpt w-100 cat_img">
                    <div
                      v-html="
                        t.post_content.length > 120
                          ? t.post_content.substring(0, 120) + '...'
                          : t.post_content
                      "
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Category",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      PostData: [],
      profile_found: "",
    };
  },
  methods: {
    getYear(d) {
      if (d) {
        return moment(new Date(d.replace(/-/g, "/"))).format("MMMM D, YYYY");
        //moment(new Date(d.replace(/-/g, "/"))).format("MMMM D, YYYY");
      }
    },
    getMagazineData() {
      this.isLoading = true;
      fetch("https://api.aukiss.tv/api/webmagazineCategory")
        .then((response) => response.json())
        .then((json) => {
          if (json[this.$route.params.id]) {
            this.PostData = json[this.$route.params.id];
            this.profile_found = true;
          } else {
            this.profile_found = false;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading,
  },
  created() {
    document.title = this.$route.params.id + " Category || Aukiss TV";
    this.$loadScript("/assets/js/owl.carousel.min.js")
      .then(() => {
        this.$nextTick(() => {
          this.getMagazineData();
        });
      })
      .catch(() => {});
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
  },
};
</script>

<style scoped>
@import url("../../public/assets/css/owl.carousel.css");
@import url("../../public/assets/css/owl.theme.css");
@import url("../../public/assets/css/owl.transitions.css");

.breadcrumb {
  background: none !important;
  padding: 0px;
  font-size: 11px;
}

@media (min-width: 768px) {
  .pl-md-25 {
    padding-left: 1rem !important;
  }
}

.td-trending-now-wrapper .td-next-prev-wrap {
  margin: 0 0 0 auto;
  z-index: 1;
}

.td-trending-now-display-area .entry-title {
  font-size: 15px;
  line-height: 25px;
  margin: 0;
}

.entry-title {
  word-wrap: break-word;
}

.td-next-prev-wrap a {
  display: inline-block;
  margin-right: 7px;
  width: 25px;
  height: 25px;
  line-height: 24px;
  border: 1px solid #dcdcdc;
  text-align: center;
  vertical-align: middle;
  font-size: 7px;
  color: #b7b7b7;
}

.left_big_image {
  height: 400px;
  object-fit: cover;
}

.right_bottom_image {
  height: 200px;
  object-fit: cover;
  width: 100%;
}

.meta-info {
  position: absolute;
  margin-bottom: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
  width: 90%;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 11px;
  margin-bottom: 7px;
  line-height: 1;
  min-height: 17px;
  cursor: pointer;
  padding: 22px 20px;
}

.tainer::after {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0)
  ) !important;
  border-radius: 0px;
}

.post-category {
  margin: 0 0 5px;
  padding: 3px 7px;
  background-color: rgba(0, 0, 0, 0.7);
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  background-color: #222222;
  color: #fff;
}

.post-title {
  font-size: 27px;
  font-weight: 500;
  line-height: 34px;
}

.post-title-sm {
  margin: 4px auto;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}

.post-author {
  font-weight: bold;
}

.post-date {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  font-size: 11px;
}

.all {
  /*height: 300px; !* Modify this according to your need *!*/
  overflow: hidden;
  /* Removing this will break the effects */
}

/* Quick-zoom Container */
.all img {
  transition: transform 0.5s, filter 1.5s ease-in-out;
}

.all .tainer::after {
  transition: transform 0.5s, filter 1.5s ease-in-out;
}

/* The Transformation */
.all:hover img {
  transform: scale(1.1);
}

.all:hover .tainer::after {
  transform: scale(1.1);
}

.block-title {
  border-color: #f9c100 !important;
}

.block-title {
  text-align: left;
}

.block-title {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 26px;
  border-bottom: 2px solid #222222;
}

.block-title > * {
  background-color: #f9c100 !important;
}

.td-block-title span {
  text-transform: uppercase !important;
}

.td-block-title span,
.td-block-title a {
  display: inline-block;
}

.block-title a,
.block-title span,
.block-title label {
  line-height: 17px;
  display: inline-block;
  padding: 7px 12px 4px;
  background-color: #222222;
  color: #fff;
}

.td-module-comments {
  display: block;
}

.td-module-comments {
  position: relative;
  float: right;
  font-family: "Open Sans", arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
}

.td-module-comments span {
  color: #fff;
  background-color: #000;
  display: inline-block;
  min-width: 17px;
  padding: 3px 4px 4px 5px;
  position: relative;
}

.td-module-comments span:after {
  position: absolute;
  bottom: -3px;
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 0;
  border-color: #000 transparent transparent transparent;
}

.block-title-dark {
  border-color: #222222 !important;
}

.block-title-dark > * {
  background-color: #222222 !important;
}

.block-title-green {
  border-color: #0599a9 !important;
}

.block-title-green > * {
  background-color: #0599a9 !important;
}

.block-title-danger {
  border-color: #f44336 !important;
}

.block-title-danger > * {
  background-color: #f44336 !important;
}

.block-title-grey {
  border-color: #607d8b !important;
}

.block-title-grey > * {
  background-color: #607d8b !important;
}

.block-title-info {
  border-color: #4db2ec !important;
}

.block-title-info > * {
  background-color: #4db2ec !important;
}

.entry-title {
  font-size: 21px;
  line-height: 25px;
  margin: 0 0 6px 0;
}

.td-post-category {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  top: auto;
}

.td-post-category {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  background-color: #222222;
  color: #fff;
  margin-right: 5px;
  padding: 3px 6px 4px;
  display: block;
  vertical-align: top;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
}

.td-module-meta-info {
  padding: 13px 0 0 0;
}

.td-module-meta-info {
  width: 100%;
  margin-bottom: 0;
  z-index: 1;
  /* border: 0 solid #eaeaea; */
  min-height: 0;
}

.td-module-meta-info {
  font-family: "Open Sans", arial, sans-serif;
  font-size: 11px;
  margin-bottom: 7px;
  line-height: 1;
  min-height: 17px;
}

.td-excerpt {
  font-size: 13px;
  color: #777;
  font-family: "Open Sans", arial, sans-serif;
  line-height: 21px;
  margin: 20px 0 0;
}

.media_image {
  width: 92px;
  height: 63px;
  object-fit: cover;
}

.article img {
  width: 223px;
  height: 156px;
  object-fit: cover;
}

.media-body-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
}

.media:hover a {
  color: #f9c100;
}

.td-module-meta-info:hover a {
  color: #f9c100;
}

.side-entry-title {
  font-size: 13px !important;
  line-height: 1.3 !important;
  font-weight: 500 !important;
  margin: 0 0 35px 0;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .article {
    display: block !important;
  }

  .article img {
    width: 100%;
  }

  .side-right {
    display: -webkit-box;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .side-right img {
    height: 254px !important;
  }

  .side-right .all {
    padding-right: 2px !important;
    padding-left: 2px !important;
    max-width: 288px !important;
    height: 180px !important;
  }

  .side-right .meta-info .post-title {
    pointer-events: none !important;
    margin: 4px auto;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }

  .left_big_image {
    height: 320px !important;
  }

  .left_big {
    padding-left: 0px;
    padding-right: 0px;
  }

  .left_big_info .post-title {
    pointer-events: none !important;
    margin: 4px auto;
    font-size: 16px !important;
    line-height: 21px;
    font-weight: 500;
  }

  .all .tainer::after {
    background: linear-gradient(
      0deg,
      rgb(0 0 0),
      rgba(0, 0, 0, 0) 90%,
      rgb(0 0 0 / 0%)
    ) !important;
  }
}
</style>
