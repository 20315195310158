<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-3">
        <div class="custom-container-fluid">
          <div class="row mb-5">
            <div class="col-md-12">
              <div
                class="text-atvyellow cursor-pointer"
                @click="$router.go(-1)"
              >
                <span class="material-icons"> west </span>
                <span style="vertical-align: top">Back</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 wide_space">
              <img
                v-lazy="detail.image"
                class="img-fluid main_image"
                style="border-radius: 10px"
                alt=""
              />
            </div>
            <div class="col-md-9">
              <h3 class="text-atvyellow mt-lg-0 font-weight-bold mt-sm-4">
                {{ detail.post_title }}
              </h3>
              <div class="mb-4">
                <span class="mr-2">{{ getYear(detail.movireleasedate) }}</span>
                <span class="mr-2">|</span
                ><span class="mr-2" v-if="detail.movie_time_duration">{{
                  detail.movie_time_duration
                }}</span
                ><span class="mr-2">|</span>
                <span class="text-atvyellow font-weight-bold">
                  <span v-for="(a, k) in detail.categories" :key="k"
                    >{{ a
                    }}<span v-if="detail.categories.length - 1 !== k"
                      >,
                    </span></span
                  >
                </span>
              </div>
              <p class="col-md-10 text-justify pl-0 pr-0">
                {{ detail.post_excerpt }}
              </p>
              <div>
                <button
                  v-if="detail.trailler_url"
                  class="btn btn-outline-atvyellow bg-light mr-4 pr-5 pl-5"
                  :disabled="!detail.trailler_url"
                  @click="
                    poster_image = detail.hori_image || detail.image;
                    showVideo(detail.trailler_url);
                  "
                >
                  Trailer
                </button>

                <button
                v-if="detail.video_url"
                  class="btn btn-atvyellow pr-5 pl-5"
                  :disabled="!detail.video_url"
                  @click="
                    poster_image =  detail.hori_image || detail.image;
                    showVideo(detail.video_url);
                  "
                >
                  Watch Now
                </button>
              </div>
              <!--cast code-->
              <!-- <h3 class="text-atvyellow mt-5" style="font-weight: 400">Cast & Crew</h3> -->
              <div class="nav-tabs-navigation mb-5 mt-5">
                <div class="nav-tabs-wrapper">
                  <ul class="nav nav-tabs" data-tabs="tabs">
                    <li class="nav-item mr-3">
                      <a
                        class="nav-link active pl-4 pr-4 pt-2 pb-2"
                        href="#cast"
                        data-toggle="tab"
                      >
                        Cast</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link pl-4 pr-4 pt-2 pb-2"
                        href="#crew"
                        data-toggle="tab"
                      >
                        Crew
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-content">
                <div class="tab-pane active" id="cast">
                  <div class="row">
                    <div
                      class="col-md-3 mb-4"
                      v-for="(c, i) in detail.cast"
                      :key="i"
                    >
                      <router-link :to="'/celebrity-profile/' + c.id">
                        <div class="media">
                          <img
                            v-lazy="c.cast_image"
                            class="mr-3 img-fluid rounded-circle"
                            style="width: 60px; height: 60px; object-fit: cover"
                            alt="..."
                          />
                          <div class="media-body">
                            <div class="mt-2">{{ c.title }}</div>
                            <div
                              class="text-atvyellow"
                              style="font-weight: 400"
                            >
                              {{ c.character }}
                              <!-- {{
                                (c.person_info || []).length > 60
                                  ? (c.person_info || []).substring(0, 60) + "..."
                                  : c.person_info
                              }} -->
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="crew">
                  <div class="row">
                    <div
                      class="col-md-3 mb-4"
                      v-for="(c, i) in detail.crew"
                      :key="i"
                    >
                      <router-link :to="'/celebrity-profile/' + c.id">
                        <div class="media">
                          <img
                            v-lazy="c.crew_image"
                            class="mr-3 img-fluid rounded-circle"
                            style="width: 60px; height: 60px; object-fit: cover"
                            alt="..."
                          />
                          <div class="media-body">
                            <div class="mt-2">{{ c.title }}</div>
                            <div
                              class="text-atvyellow"
                              style="font-weight: 400"
                            >
                              {{ c.character }}
                              <!-- {{
                                
                                (c.person_info || []).length > 60
                                  ? (c.person_info || []).substring(0, 60) + "..."
                                  : c.person_info
                              }} -->
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

              <h3
                class="text-atvyellow mt-5"
                v-if="detail.fun_fact"
                style="font-weight: 400"
              >
                Fun Facts
              </h3>
              <p
                class="col-md-10 text-justify pl-0 pr-0"
                v-if="detail.fun_fact"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit
                tempor rutrum vivamus nascetur amet, dolor. Ipsum posuere ornare
                cras faucibus nulla sed. Est eu aliquam sit nunc in arcu
                condimentum. Sed dui ac eget nisl. Habitasse dictumst ipsum
                donec lectus purus eleifend tellus auctor. Egestas convallis
                arcu augue magna consequat in feugiat pharetra.
              </p>
              <h3
                class="text-atvyellow mt-5"
                style="font-weight: 400"
                v-if="detail.recommendedMovies.length > 0"
              >
                You May Also Like
              </h3>
              <div class="row">
                <!-- <div class="col-md-12" v-if="detail.recommendedMovies == 0">
                  No recommended videos
                </div> -->
                <div
                  class="col-md-2 col-sm-6 col-xs-6 col-6"
                  v-for="(c, i) in detail.recommendedMovies"
                  :key="i"
                >
                  <router-link
                    @click.native="getMovieDetails"
                    :to="'/movie-details/' + c.id"
                  >
                    <div class="card">
                      <img
                        v-lazy="c.image"
                        style="height: 150px; border-radius: 5px"
                        class="card-img-top recommended"
                        alt=""
                      />
                    </div>
                    <div class="mt-1 movie-text">
                      {{ getYear(c.movireleasedate) }}
                      <span
                        v-if="
                          (c.categories || []).length > 0 && c.movireleasedate
                        "
                        >,</span
                      >
                      <span v-for="(cc, p) in c.categories" :key="p"
                        >{{ cc
                        }}<span v-if="c.categories.length - 1 !== p"
                          >,
                        </span></span
                      >
                    </div>
                    <div class="font-weight-bold">{{ c.post_title }}</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
    <div
      class="modal fade"
      id="movieModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="text-center">
              <h3 class="text-orange mt-2 font-weight-bold">
                {{ detail.post_title }}
              </h3>
            </div>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="stopPlayer()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <video poster="capture.jpg" id="player" playsinline controls>
              <source src="" type="video/mp4" />
              <source src="" type="video/webm" />
            </video>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-orange button"
              data-dismiss="modal"
              aria-label="Close"
              @click="stopPlayer()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Plyr from "plyr";

export default {
  name: "MovieDetail",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      detail: {},
      movie_player: "",
      poster_image: "",
    };
  },
  components: {
    topnav: () => import("@/components/topNav"),
    footernav: () => import("@/components/footer"),
    Loading,
  },
  methods: {
    triggerPlayer(videoUrl) {
      this.movie_player = new Plyr("#player", {
        debug: false,
        settings: ["speed"],
      });
      this.movie_player.source = {
        type: "video",
        title: "Example title",
        sources: [
          {
            src: videoUrl ? videoUrl : "",
            type: "video/mp4",
            size: 720,
          },
          {
            src: videoUrl ? videoUrl : "",
            type: "video/webm",
            size: 1080,
          },
        ],
        poster: this.poster_image ? this.poster_image : "",
      };
    },
    showVideo(videoUrl) {
      openModal("#movieModal");
      if (this.movie_player) {
        this.movie_player.destroy();
        this.triggerPlayer(videoUrl);
        return;
      }
      this.triggerPlayer(videoUrl);
    },
    stopPlayer() {
      if (this.movie_player) {
        this.movie_player.stop();
      }
    },

    getYear(d) {
      if (d) {
        return moment(new Date(d)).format("YYYY");
      }
    },

    sendViews(mov_id) {
      let data = {
        user_id: (this.userInfo || {}).id,
        magazine: "",
        movie: mov_id,
        tvshow: "",
        livetv: "",
        liveradio: "",
        movie_clicked: 1,
        magazine_clicked: "",
        tvshow_clicked: "",
        livetv_clicked: "",
        liveradio_clicked: "",
      };
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/postUserView", data)
        .then((res) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },

    getMovieDetails() {
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/webmoviesId", {
          movie_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.detail = res.data.data;
            document.title = this.detail.post_title + " || Aukiss TV";
            // console.log("details", this.detail);

            this.sendViews(this.detail.id);
          } else {
            this.$router.push("/404");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          // console.log("res", res);
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {
            // } else {
            this.subscription = res.data.data;
            if (
              (this.subscription || {}).expired_at ||
              this.subscription == null
            ) {
              if (this.checkDate()) {
                this.$router.push("/subscription/expired");
              }
            } else {
              this.$router.push("/subscription");
            }
            // }
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getMovieDetails();
    this.checkExpiration();
  },
};
</script>

<style scoped>
.table-bordered th,
.table-bordered td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.nav-tabs .nav-item .nav-link.active {
  color: white !important;
  background-color: #a88d30;
}

.nav-tabs .nav-item .nav-link {
  background-color: whitesmoke;
  color: #a88d30 !important;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .main_image {
    height: 260px !important;
    width: 100%;
  }

  .recommended {
    height: 208px !important;
  }
}
</style>
