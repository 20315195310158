<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5">
        <div class="container-fluid custom-container-fluid">
          <div class="row">
            <div class="col-md-12">
              <h3 class="font-weight-bold">Browse By Category</h3>
            </div>
            <div class="col-6 col-md-3">
              <select
                class="form-control font-weight-bold"
                style="color: #a88d30"
                @change="getCategoryMovies()"
                v-model="selected_category"
              >
                <option value>All categories</option>
                <option v-for="(c, i) in categories" :key="i" :value="c">
                  {{ c }}
                </option>

               
              </select>
            </div>

            <div class="col-5 col-md-6">
              <!--add section here-->
              <div class="w-100" v-if="MoviesTopHeaderAd1 != null">
                <a :href="MoviesTopHeaderAd1.adUrl" target="_blank">
                  <img
                    v-lazy="MoviesTopHeaderAd1.image"
                    :alt="MoviesTopHeaderAd1.id"
                    style="width: 100%; max-height: 250px; object-fit: fill"
                  />
                </a>
              </div>
              <!--end dd section here-->
            </div>

            <div class="col-6 col-md-3">
              <selectize v-on:change="test()" :settings="settings">
                <!-- settings is optional -->
                <option value selected>Find a movie...</option>
              </selectize>
            </div>

            <div class="col-md-12 mt-4">
              <div class="row">
                <div
                  class="col-md-2 col-sm-6 col-xs-6 col-6"
                  v-for="(c, i) in category_movies"
                  :key="i"
                >
                  <router-link
                    :to="'/movie-details/' + c.id"
                    @click.native.prevent="handleNavigation(c.id)"
                  >
                    <div class="card cursor-pointer">
                      <img v-lazy="c.image" class="card-img-top" alt />
                    </div>
                    <div class="mt-1 movie-text"></div>
                    <div class="font-weight-bold">{{ c.post_title }}</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
      <!-- <input type="text" v-model="selecting" id="selecting" /> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { runInThisContext } from "vm";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Selectize from "vue2-selectize";

export default {
  name: "Movies",
  data() {
    return {
      settings: this.test(),
      selecting: "",
      isLoading: false,
      fullPage: false,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      details: [],
      categories: [],
      category_movies: [],
      selected_category: "",
      MoviesTopHeaderAd1: {},
      details: {
        id: this.$store.state.userInfo.id,
        city: "",
        continentCode: "",
        continentName: "",
        countryCode: "",
        countryName: "",
        ipAddress: "",
        stateProv: "",
      },
    };
  },
  // watch: {
  //     'selected': function (value) {
  //         console.log('city changed');
  //     }
  // },
  methods: {
    test: function() {
      var me = this;
      return {
        valueField: "type",
        labelField: "post_title",
        searchField: "post_title",
        create: false,
        onItemAdd: function(value, $item) {
          let data1 = value.split("/")[0].replace(/\s/g, "");
          let data2 = value.split("/")[1].replace(/\s/g, "");
          console.log(data1, data2);
          if (data1 == "allmovies") {
            me.$router.push("/movie-details/" + data2);
          } else if (data1 == "allcelebrity") {
            me.$router.push("/celebrity-profile/" + data2);
          }
        },
        render: {
          option: function(item, escape) {
            console.log("item", item);
            let mydata = "";
            if (item.component === "allmovies") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="selectize_img" src="' +
                escape(item.image) +
                '" alt="">' +
                escape(item.post_title) +
                "</span>" +
                "</span>" +
                '<span class="selectize_description">' +
                escape(item.post_content) +
                "</span>" +
                '<ul class="selectize_meta">' +
                (item.movireleasedate
                  ? '<li class="language">' +
                    escape(new Date(item.movireleasedate).getFullYear()) +
                    "</li>"
                  : "") +
                "</ul>" +
                "</div>";
            } else if (item.component === "allcelebrity") {
              mydata =
                "<div>" +
                '<span class="selectize_title">' +
                '<span class="selectize_name"><img class="celebrity_img" src="' +
                escape(item.photo) +
                '" alt="">' +
                escape(item.name) +
                "</span>" +
                "</span>" +
                (item.person_info
                  ? '<span class="selectize_description">' +
                    escape(item.person_info) +
                    "</span>"
                  : "") +
                "</div>";
            }
            return mydata;
          },
        },
        load: function(query, callback) {
          if (!query.length) return callback();
          $.ajax({
            url: "https://api.aukiss.tv/api/searchwebmovie",
            type: "POST",
            data: {
              title: encodeURIComponent(query),
            },
            error: function() {
              callback();
            },
            success: function(res) {
              let mydata = res.data;
              if (mydata.length > 0) {
                mydata.forEach((e) => {
                  e.type = res.message + "/" + e.id;
                  e.component = res.message.replace(/\s/g, "");
                  if (e.component == "allcelebrity") {
                    e.image = e.photo;
                    e.post_title = e.name;
                    e.post_content = e.person_info;
                  }
                });
                callback(mydata);
              }
            },
          });
        },
      };
    },
    getYear(d) {
      if (d) {
        return moment(new Date(d)).format("YYYY");
      }
    },
    removeDuplicate(data) {
      return [...new Set(data)];
    },
    getCategoryMovies() {
      this.category_movies = this.details;
      if (this.selected_category) {
        this.category_movies = this.details.filter((e) => {
          if (e.categories.includes(this.selected_category)) {
            return e;
          }
        });
      }
    },

    handleNavigation(ids) {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          if (res.data.data !== null) {
            if (
              ((res.data.data || {}).user || {}).role_id == 2 ||
              ((res.data.data || {}).user || {}).role_id == 3 ||
              ((res.data.data || {}).user || {}).role_id == 4 ||
              ((res.data.data || {}).user || {}).role_id == 5
            ) {
              this.$router.push("/movie-details/" + ids);
            } else {
              this.subscription = res.data.data;
              if (
                (this.subscription || {}).expired_at ||
                this.subscription == null
              ) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                } else {
                  this.$router.push("/movie-details/" + ids);
                }
              } else {
                //
                this.$router.push("/subscription");
              }
            }
          } else {
            this.$router.push("/subscription");
          }
        });
    },

    saveInfo() {
      this.isLoading = true;

      axios
        .post("https://api.aukiss.tv/api/locationupdate", this.details)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getlocation() {
      this.isLoading = true;
      axios
        .get("https://api.db-ip.com/v2/free/self")
        .then((res) => {
          this.details.city = res.data.city;
          this.details.continentCode = res.data.continentCode;
          this.details.continentName = res.data.continentName;
          this.details.countryCode = res.data.countryCode;
          this.details.countryName = res.data.countryName;
          this.details.ipAddress = res.data.ipAddress;
          this.details.stateProv = res.data.stateProv;

          this.saveInfo();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getAllMovies() {
  this.isLoading = true;
  axios
    .get("https://api.aukiss.tv/api/webmovies")
    .then((res) => {
      this.details = res.data.data;
      this.category_movies = res.data.data;
      
      // Collect all categories
      this.details.forEach((e) => {
        this.categories = this.categories.concat(e.categories);
      });
      
      // Remove duplicates from categories
      this.categories = this.removeDuplicate(this.categories);
      
      // Sort the categories alphabetically
      this.categories.sort((a, b) => a.localeCompare(b));
    })
    .finally(() => {
      this.isLoading = false;
    });
},

    getAdsData() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/adstartApp")
        .then((json) => {
          this.MoviesTopHeaderAd1 = json.data.MoviesTopHeaderAd1;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          // console.log("res", res);
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {
            // } else {
            this.subscription = res.data.data;
            if (
              (this.subscription || {}).expired_at ||
              this.subscription == null
            ) {
              if (this.checkDate()) {
                this.$router.push("/subscription/expired");
              }
            } else {
              this.$router.push("/subscription");
            }
            // }
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading,
    Selectize,
  },
  created() {
    document.title = "Movies || Aukiss TV";
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getAllMovies();
    this.checkExpiration();
    this.getAdsData();
    this.getlocation();
    var elements = document.getElementsByClassName(
      "index-page sidebar-collapse"
    );
    for (var i = 0; i < elements.length; i++) {
      elements[i].removeAttribute("style");
    }
  },
};
</script>

<style scoped>
@import "~selectize/dist/css/selectize.bootstrap3.css";

.dropbtn {
  background-color: white;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: white;
}

.card {
  margin-bottom: 0px !important;
  border-radius: 10px !important;
}

.card-img-top {
  height: 215px !important;
  border-radius: 10px !important;
}

.card-img-top[lazy="loading"] {
  object-fit: cover !important;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .card-img-top {
    height: auto !important;
  }
}
</style>
