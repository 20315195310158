<template>
  <div class="text-light">
    <topnav></topnav>

    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>

      <div class="section mt-3">
        <div class="custom-container-fluid" v-if="profile_found === false">
          <div class="row text-center">
            <div class="col-lg-10 offset-lg-1 col-sm-6 offset-sm-3 col-12 p-3 error-main">
              <div class="row">
                <div class="col-lg-8 col-12 col-sm-10 offset-lg-2 offset-sm-1">
                  <img src="../../public/assets/img/404.png" class="img-fluid" alt="" />
                  <p class="text-atvyellow mb-4" style="font-size: 25px">
                    Profile not found...
                  </p>
                  <p>Sorry the celebrity profile your looking for cannot be found.</p>
                  <router-link
                    to="/"
                    class="btn btn-atvyellow pl-5 pr-5 pt-3 pb-3 mt-5"
                    style="border-radius: 10px"
                    >GO HOME
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="custom-container-fluid" v-if="profile_found === true">
          <div class="row mb-5">
            <div class="col-md-12">
              <div class="text-atvyellow" @click="$router.go(-1)">
                <span class="material-icons"> west </span>
                <span style="vertical-align: top">Back</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 wide_space">
              <div
                class="card text-center mt-0"
                style="background: rgba(255, 255, 255, 0.2); color: white"
              >
                <div class="tainer">
                  <img
                    v-lazy="details.photo"
                    style="border-radius: 10px"
                    class="card-img-top"
                    alt=""
                  />
                </div>
                <!-- <div class="mt-5">
                  <div style="font-size: 20px" class="font-weight-bold mb-2">Acting</div>
                
                </div> -->
                <div class="mt-5" v-if="details.name">
                  <div style="font-size: 20px" class="font-weight-bold mb-2">
                    {{ details.name }}
                  </div>
                  <div style="color: rgba(255, 255, 255, 0.5)">Name</div>
                </div>
                <div class="mt-5" v-if="details.alsoknownas">
                  <div style="font-size: 20px" class="font-weight-bold mb-2">
                    {{ details.alsoknownas }}
                  </div>
                  <div style="color: rgba(255, 255, 255, 0.5)">Also Known As</div>
                </div>
                <div class="mt-5" v-if="details.birthday">
                  <div style="font-size: 20px" class="font-weight-bold mb-2">
                    {{ details.birthday }}
                  </div>
                  <div style="color: rgba(255, 255, 255, 0.5)">Birthday</div>
                </div>
                <div class="mt-5" v-if="details.deathday">
                  <div style="font-size: 20px" class="font-weight-bold mb-2">
                    {{ details.deathday }}
                  </div>
                  <div style="color: rgba(255, 255, 255, 0.5)">Date of Passing</div>
                </div>
                <div class="mt-5">
                  <div style="font-size: 20px" class="font-weight-bold mb-2">
                    {{ details.place_of_birth }}
                  </div>
                  <div style="color: rgba(255, 255, 255, 0.5)">Place of Birth</div>
                </div>

                <div class="mt-5">
                  <div style="font-size: 20px" class="font-weight-bold mb-2" v-if="details.person_info">
                    {{ details.person_info }}
                  </div>
                  <div style="color: rgba(255, 255, 255, 0.5)">Information</div>
                </div>
                <div class="mt-5"></div>
              </div>
            </div>
            <div class="col-md-8">
               <!-- <h3 class="text-atvyellow mt-lg-0 font-weight-bold mt-sm-4 mb-4">
                {{ details.name }}
              </h3>
              <p class="col-md-10 text-justify pl-0 pr-0" v-if="details.person_info">
                {{ details.person_info }}
              </p>-->
              <h3 class="text-atvyellow mt-lg-0 font-weight-bold mt-sm-4"
              v-if="((details || {}).movie_tvshow || []).length > 0"
              >
                Movies
              </h3>
              <div class="row">
              <div
                  class="col-md-2 col-sm-6 col-xs-6 col-6"
                  v-for="(m, i) in (details || {}).movie_tvshow"
                  :key="i"
                >
                  <router-link
                  :to="'/movie-details/' + m.id"
                  >
                    <div class="card">
                      <img
                        v-lazy="m.image"
                        style="height: 180px; border-radius: 5px"
                        class="card-img-top recommended"
                        alt=""
                      />
                    </div>
                    
                    
                  </router-link>
                </div>
                <div
                    class="mt-3"
                    v-if="((details || {}).movie_tvshow || []).length == 0"
                  >
                  
                  </div>
              </div>



              <h3 class="text-atvyellow mt-lg-0 font-weight-bold mt-sm-4"
                v-if="((details || {}).series_tvshow || []).length > 0"
              >
                Series
              </h3>
              <div class="row">
              <div
                  class="col-md-2 col-sm-6 col-xs-6 col-6"
                  v-for="(m, i) in (details || {}).series_tvshow"
                  :key="i"
                >
                  <router-link
                  :to="'/series-details/' + m.id"
                  >
                    <div class="card">
                      <img
                        v-lazy="m.image"
                        style="height: 180px; border-radius: 5px"
                        class="card-img-top recommended"
                        alt=""
                      />
                    </div>
                    
                    
                  </router-link>
                </div>
                <div
                    class="mt-3"
                    v-if="((details || {}).series_tvshow || []).length == 0"
                  >
                  
                  </div>
              </div>



              <h3 class="text-atvyellow mt-lg-0 font-weight-bold mt-sm-4"
              v-if="((details || {}).radio || []).length > 0"
              >
                Radio
              </h3>
              <div class="row">
              <div
                  class="col-md-2 col-sm-6 col-xs-6 col-6"
                  v-for="(m, i) in (details || {}).radio"
                  :key="i"
                >
                  <router-link
                  :to="'/live-radio-details/' + m.id"
                  >
                    <div class="card">
                      <img
                        v-lazy="m.image"
                        style="height: 180px; border-radius: 5px"
                        class="card-img-top recommended"
                        alt=""
                      />
                    </div>
                    
                    
                  </router-link>
                </div>
                <div
                    class="mt-3"
                    v-if="((details || {}).radio || []).length == 0"
                  >
                
                  </div>
              </div>

              <!--<div class="nav-tabs-navigation mt-5">
                <div class="nav-tabs-wrapper">
                  <ul class="nav nav-tabs" data-tabs="tabs">
                    <li class="nav-item mr-2">
                      <a
                        class="nav-link season active pl-0"
                        href="#season1"
                        data-toggle="tab"
                        >Movies</a
                      >
                    </li>
                    <span style="margin-top: 10px">|</span>
                    <li class="nav-item ml-1">
                      <a class="nav-link season" href="#season2" data-toggle="tab">
                        Series
                      </a>
                    </li>

                    <span style="margin-top: 10px">|</span>
                    <li class="nav-item ml-1">
                      <a class="nav-link season" href="#season3" data-toggle="tab">
                        Radio
                      </a>
                    </li>
                  </ul>
                  <div
                    style="border: 1px solid rgba(255, 255, 255, 0.4); border-radius: 5px"
                  ></div>
                </div>
              </div>-->


              <!--<div class="tab-content mt-0">
                <div class="tab-pane active" id="season1">
                  <div
                    class="mb-3 mt-2"
                    v-for="(m, i) in (details || {}).movie_tvshow"
                    :key="i"
                  >
                    <span class="mr-5" style="font-weight: 400">{{
                      getYear(m.movie_release_date)
                    }}</span>
                    <router-link :to="'/movie-details/' + m.id"
                      ><span class="ml-4"
                        ><span style="font-weight: 400">{{ m.title }}</span></span
                      ></router-link
                    >
                  </div>
                  <div
                    class="mt-3"
                    v-if="((details || {}).movie_tvshow || []).length == 0"
                  >
                    No movies available...
                  </div>
                </div>
                <div class="tab-pane" id="season2">
                  <div
                    class="mb-3 mt-2"
                    v-for="(m, i) in (details || {}).series_tvshow"
                    :key="i"
                  >
                    <span class="mr-5" style="font-weight: 400">{{
                      getYear(m.movie_release_date)
                    }}</span>
                    <router-link :to="'/series-details/' + m.id"
                      ><span class="ml-4"
                        ><span style="font-weight: 400">{{ m.title }}</span></span
                      ></router-link
                    >
                  </div>
                  <div
                    class="mt-3"
                    v-if="((details || {}).series_tvshow || []).length == 0"
                  >
                    No series available...
                  </div>
                </div>

                <div class="tab-pane" id="season3">
                  <div class="mb-3 mt-2" v-for="(m, i) in (details || {}).radio" :key="i">
                    <span class="mr-5" style="font-weight: 400"></span>
                    <router-link :to="'/live-radio-details/' + m.id"
                      ><span class="ml-3"
                        ><span style="font-weight: 400">{{ m.title }}</span></span
                      ></router-link
                    >
                  </div>
                  <div class="mt-3" v-if="((details || {}).radio || []).length == 0">
                    No Radio available...
                  </div>
                </div>
              </div>-->


              
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Celebrity_profile",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      profile_found: "",
      details: {},
    };
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading: () => import("vue-loading-overlay"),
  },
  methods: {
    getYear(d) {
      if (d) {
        return moment(new Date(d)).format("YYYY");
      }
    },
    getCelebrityInfo() {
      this.isLoading = true;
      axios
        .post(`https://api.aukiss.tv/api/webclebId`, {
          celeb_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.data) {
            this.details = res.data.data;
            // console.log("profile", this.details);
            this.profile_found = true;
          } else {
            this.profile_found = false;
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  created() {
    this.getCelebrityInfo();
    document.title = "Celebrity profile || Aukiss TV";
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
  },
};
</script>

<style scoped>
.table-bordered th,
.table-bordered td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.nav-tabs .nav-item .season.nav-link.active {
  color: #a88d30 !important;
  margin-bottom: -1px;
  border-bottom: 2px solid #a88d30 !important;
  border-radius: 0px !important;
}

.nav-tabs .nav-item .season.nav-link {
  background: none;
  color: rgba(255, 255, 255, 0.5) !important;
}

.card {
  margin-bottom: 15px !important;
}

#contentItem {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
