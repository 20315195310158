<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
/* eslint-disable */
import { db } from "@/firebaseConfig";
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      userInfo: this.$store.state.userInfo,
      subscription: {},
    };
  },
  methods: {
    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          console.log("res", res);
          if (res.data.data !== null) {
            if (
              ((res.data.data || {}).user || {}).role_id == 2 ||
              ((res.data.data || {}).user || {}).role_id == 3 ||
              ((res.data.data || {}).user || {}).role_id == 4 ||
              ((res.data.data || {}).user || {}).role_id == 5
            ) {
            } else {
              this.subscription = res.data.data;
              if ((this.subscription || {}).expired_at || this.subscription == null) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                }
              } else {
                this.$router.push("/subscription");
              }
            }
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },
    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
    listenMessage() {
      const messaging = db.firemessaging;
      messaging
        .getToken({
          vapidKey:
            "BGEfhqbz5V-0-ZUZkxP8Z8vzMKXEmg1ygefLM_nBhcEjJtGYBeFKiaW3Og7cghxAJyDEhxtbIb87LLDDTJ-lK-A",
        })
        .then(async (token) => {
          if (token) {
            console.log(token);
          }
        })
        .catch(function (err) {
          // console.log('An error occurred while retrieving token. ', err);
        });
      messaging.onMessage((payload) => {
        console.log("Message received. ", payload);
      });
    },
  },
  created() {
    this.listenMessage()
    if (
      this.$route.fullPath !== "/subscription/expired" &&
      this.$route.fullPath !== "/subscription"
    ) {
      if (
        this.$route.fullPath !== "/magazine" ||
        this.$route.fullPath !== "/magazine-details/:id" ||
        this.$route.fullPath !== "/magazine/category/:id" ||
        this.$route.fullPath !== "/magazine-author/:id" ||
        this.$route.fullPath !== "/help"
      ) {
        this.$loadScript(
          "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
        ).then(() => {
          if (this.userInfo != null) {
            // this.checkExpiration();
          }
        });
      }
    }
  },
};
</script>

<style>
:root {
  --orange: #f26526;
  --blue: #059ca6;
  --black: #000000;
  --grey: rgba(0, 0, 0, 0.4);
  --gotham: "gothamLight", sans-serif;
  --gothamBold: "gotham", sans-serif;
  --gothamMedium: "gothamMedium", sans-serif;
  --deep-grey: rgba(15, 61, 71, 0.9);
  --deeper-grey: #0f3d47;
  --light-grey: #e5e5e5;
}

body {
  /*font-family: var(--gotham);*/
  background-color: #1c212e;
  color: white;
}
</style>
