<template>
    <div class="text-light">
        <topnav></topnav>
        <div class="main main-raised" style="margin: 0px; background-color: #1C212E; min-height: 100vh">
            <div class="section mt-3">
                <div class="custom-container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="text-atvyellow">
                                <span class="material-icons"> west </span> <span
                                style="vertical-align: top;">Back</span>
                            </div>
                        </div>
                    </div>
                    <div class="product-view-area">
                        <div class="row">
                            <div class="wide_space product-big-image col-xs-12 col-md-4 ">
                                <!--<div class="icon-sale-label sale-left">Sale</div>-->
                                <div class="large-image"><a href="/assets/images/products/img03.jpg" class="cloud-zoom"
                                                            id="zoom1"
                                                            rel="useWrapper: false, adjustY:0, adjustX:20"> <img
                                    class="zoom-img" src="/assets/images/products/img03.jpg" alt="products"> </a></div>
                                <div class="flexslider flexslider-thumb">
                                    <ul class="previews-list slides">
                                        <li v-for="(m,i) in images" :key="i"><a :href='m'
                                               class='cloud-zoom-gallery'
                                               :rel="['useZoom:'+'\'zoom1\'','smallImage:\''+m+'\'']"
                                            >
                                            <img
                                            :src="m" alt="Thumbnail 1"/></a>
                                        </li>
                                    </ul>
                                </div>

                                <!-- end: more-images -->

                            </div>
                            <div class="col-xs-12 col-md-8 product-details-area">
                                <h3 class="text-atvyellow font-weight-bold mt-0">AUKISS S-Time G1 Watch</h3>
                                <div class="price-box">
                                    <p class="special-price"><span class="price-label">Special Price</span>
                                        <span class="price"> $329.99 </span></p>
                                    <p class="old-price"><span class="price-label">Regular Price:</span> <span
                                        class="price"> $359.99 </span></p>
                                </div>
                                <div style="font-weight: 400">
                                    <div class="mb-3"> <span class="text-atvyellow">Category:</span> <span>Aukiss Product line, Electronics</span></div>
                                    <div class="mb-3"> <span class="text-atvyellow">Color:</span> <span>Black</span></div>
                                    <div class="color mb-3">
                                        <ul>
                                            <li><a href="#"></a></li>
                                            <li><a href="#"></a></li>
                                            <li><a href="#"></a></li>
                                            <li><a href="#"></a></li>
                                            <li><a href="#"></a></li>
                                            <li><a href="#"></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="product-variation mt-3 mb-3">
                                    <form action="#" method="post">
                                        <div class="cart-plus-minus">
                                            <div class="numbers-row">
                                                <div
                                                    onClick="var result = document.getElementById('qty'); var qty = result.value; if( !isNaN( qty ) &amp;&amp; qty &gt; 0 ) result.value--;return false;"
                                                    class="dec qtybutton"><i class="fa fa-minus">&nbsp;</i>
                                                </div>
                                                <input type="text" class="qty" title="Qty" value="1"
                                                       maxlength="12" id="qty" name="qty">
                                                <div
                                                    onClick="var result = document.getElementById('qty'); console.log(result); var qty = result.value; if( !isNaN( qty )) result.value++;return false;"
                                                    class="inc qtybutton"><i class="fa fa-plus">&nbsp;</i></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div>
                                    <button class="button btn-outline-atvyellow bg-light mr-3 cursor-pointer" title="Add to Cart"
                                            type="button"><span>
                                        <!--<i class="fa fa-shopping-cart"></i> -->
                                        Add to favorite</span>
                                    </button>
                                    <button class="button btn-atvyellow cursor-pointer" title="Add to Cart"
                                            type="button"><span>
                                        <!--<i class="fa fa-shopping-cart"></i> -->
                                        Buy Now</span>
                                    </button>
                                </div>

                                <div class="short-description mt-4 text-justify col-md-8">
                                    <h2 class="text-atvyellow">Description</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fringilla
                                        augue nec est tristique auctor. Donec non est at libero vulputate
                                        rutrum.
                                    <p> Vivamus adipiscing nisl ut dolor dignissim semper. Nulla luctus
                                        malesuada tincidunt. Class aptent taciti sociosqu ad litora torquent per
                                        conubia nostra, per inceptos himenaeos. Integer enim purus, posuere at
                                        ultricies eu, placerat a felis. Suspendisse aliquet urna pretium eros
                                        convallis interdum. Quisque in arcu id dui vulputate mollis eget non
                                        arcu. Aenean et nulla purus. Mauris vel tellus non nunc mattis
                                        lobortis.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mb-5">
                        <div class="col-md-12">
                            <h4 class="mb-4 text-atvyellow font-weight-bold">Related Products</h4>
                        </div>
                        <div class="col-md-25">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../public/assets/img/shop/1.png" class="img-fluid w-100"
                                         alt="">
                                    <h4 class="font-weight-bold text-center-sm">AuKiss S-Time G1 Watch</h4>
                                    <h5 class="text-center-sm">$90.99</h5>
                                    <div class="text-center">
                                        <button class="btn btn-atvyellow">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-25">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../public/assets/img/shop/1.png" class="img-fluid w-100"
                                         alt="">
                                    <h4 class="font-weight-bold text-center-sm">AuKiss S-Time G1 Watch</h4>
                                    <h5 class="text-center-sm">$90.99</h5>
                                    <div class="text-center">
                                        <button class="btn btn-atvyellow">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-25">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../public/assets/img/shop/1.png" class="img-fluid w-100"
                                         alt="">
                                    <h4 class="font-weight-bold text-center-sm">AuKiss S-Time G1 Watch</h4>
                                    <h5 class="text-center-sm">$90.99</h5>
                                    <div class="text-center">
                                        <button class="btn btn-atvyellow">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-25">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../public/assets/img/shop/1.png" class="img-fluid w-100"
                                         alt="">
                                    <h4 class="font-weight-bold text-center-sm">AuKiss S-Time G1 Watch</h4>
                                    <h5 class="text-center-sm">$90.99</h5>
                                    <div class="text-center">
                                        <button class="btn btn-atvyellow">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-25">
                            <div class="card">
                                <div class="card-body">
                                    <img src="../../public/assets/img/shop/1.png" class="img-fluid w-100"
                                         alt="">
                                    <h4 class="font-weight-bold text-center-sm">AuKiss S-Time G1 Watch</h4>
                                    <h5 class="text-center-sm">$90.99</h5>
                                    <div class="text-center">
                                        <button class="btn btn-atvyellow">Buy Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footernav></footernav>
        </div>


    </div>
</template>

<script>
    /* eslint-disable */

    export default {
        name: "Home",
        data() {
            return {
                data: {
                    email: "",
                    password: ""
                },
                isLoading: false,
                fullPage: true,
                details: {},
                height: 128,
                width: 128,
                images:['http://localhost:8080/assets/images/products/img03.jpg','http://localhost:8080/assets/images/products/img07.jpg','http://localhost:8080/assets/images/products/img02.jpg','http://localhost:8080/assets/images/products/img04.jpg'],
                userInfo: this.$store.state.userInfo,
            };
        },
        components: {
            topnav: () => import('@/components/topNav'),
            footernav: () => import('@/components/footer')
        },
        // beforeDestroy(){
        //     $('.magnifier, .cursorshade, .tracker').remove();
        // },

        created() {
            // this.isLoading=true;
            this.$loadScript("/assets/js/new/jquery.flexslider.js")
                .then(() => {
                    this.$loadScript("/assets/js/new/zoom.js")
                        .then(() => {
                            runCloud();
                            flexing();
                        })
                        .catch(() => {
                        }).finally(()=>{
                            // this.isLoading=false;
                    });
                })
                .catch(() => {
                });
        },

        methods: {
        }
    };
</script>

<style scoped>
    .card {
        margin-bottom: 0px !important;
        border-radius: 10px !important;
    }

    .card-img-top {
        height: 280px !important;
        object-fit: cover !important;
        border-radius: 10px !important;
    }

    @media screen and (max-width: 599px) {
        .text-center-sm {
            text-align: center !important;
        }
    }

    .pagination > .page-item > .page-link, .pagination > .page-item > span {
        border-radius: 5px !important;
    }


</style>
