<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-5">
        <div class="container-fluid custom-container-fluid">
          <div class="row">
            <div class="col-md-12 mt-4">
              <div class="tab-content text-center">
                <div class="tab-pane active" id="profile">
                  <div class="row">
                    <div
                      class="col-md-3 col-sm-6 col-xs-6 col-6"
                      v-for="(a, i) in shows"
                      :key="i"
                    >
                      <router-link
                        :to="'/radio-details/' + a.id"
                        @click.native.prevent="handleNavigation(a.id)"
                      >
                        <div class="card">
                          <img
                            v-lazy="
                              a.image
                                ? a.image
                                : '../../public/assets/img/tv/2.png'
                            "
                            class="card-img-top"
                            alt=""
                          />
                        </div>

                        <div class="font-weight-bold">{{ a.name }}</div>
                      </router-link>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-12">
                      <!--<button class="btn btn-atvyellow">Load More</button>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Selectize from "vue2-selectize";

export default {
  name: "Radio",
  data() {
    return {
      isLoading: false,
      fullPage: false,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      shows: [],
      details: {
        id: this.$store.state.userInfo.id,
        city: "",
        continentCode: "",
        continentName: "",
        countryCode: "",
        countryName: "",
        ipAddress: "",
        stateProv: "",
      },
    };
  },
  components: {
    topnav: () => import("@/components/darktopNav"),
    footernav: () => import("@/components/footer"),
    Loading,
    Selectize,
  },
  methods: {
    getAllRadios() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/radiomain")
        .then((res) => {
          this.shows = res.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    saveInfo() {
      this.isLoading = true;

      axios
        .post("https://api.aukiss.tv/api/locationupdate", this.details)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getlocation() {
      this.isLoading = true;
      axios
        .get("https://api.db-ip.com/v2/free/self")
        .then((res) => {
          this.details.city = res.data.city;
          this.details.continentCode = res.data.continentCode;
          this.details.continentName = res.data.continentName;
          this.details.countryCode = res.data.countryCode;
          this.details.countryName = res.data.countryName;
          this.details.ipAddress = res.data.ipAddress;
          this.details.stateProv = res.data.stateProv;

          this.saveInfo();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleNavigation(ids) {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          if (res.data.data !== null) {
            if (
              ((res.data.data || {}).user || {}).role_id == 2 ||
              ((res.data.data || {}).user || {}).role_id == 3 ||
              ((res.data.data || {}).user || {}).role_id == 4 ||
              ((res.data.data || {}).user || {}).role_id == 5
            ) {
              this.$router.push("/radio-details/" + ids);
            } else {
              this.subscription = res.data.data;
              if (
                (this.subscription || {}).expired_at ||
                this.subscription == null
              ) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                } else {
                  this.$router.push("/radio-details/" + ids);
                }
              } else {
                //
                this.$router.push("/subscription");
              }
            }
          } else {
            this.$router.push("/subscription");
          }
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          //console.log("res", res);
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {

            //  } else {
              this.subscription = res.data.data;
              if (
                (this.subscription || {}).expired_at ||
                this.subscription == null
              ) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                }
              } else {
                this.$router.push("/subscription");
              }
            // }
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    document.title = "Radio || Aukiss TV";
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getAllRadios();
    //this.checkExpiration();
    this.getlocation();
    var elements = document.getElementsByClassName(
      "index-page sidebar-collapse"
    );
    for (var i = 0; i < elements.length; i++) {
      elements[i].removeAttribute("style");
    }
  },
};
</script>

<style scoped>
.card {
  margin-bottom: 0px !important;
  border-radius: 10px !important;
}

.card-img-top {
  height: 280px !important;
  /*object-fit: cover !important;*/
  border-radius: 10px !important;
}

.card-img-top[lazy="loading"] {
  object-fit: cover !important;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .card-img-top {
    height: 100px !important;
  }
}
</style>
