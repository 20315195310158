<template>
  <div class="text-light">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      loader="dots"
      :is-full-page="fullPage"
      :height="height"
      :width="width"
      background-color="#6b6969"
    ></loading>
    <div class="container-fluid custom-container-fluid mt-5">
      <div class="row">
        <div class="col-md-12">
          <div class="font-weight-bold h5">
            <span style="vertical-align: bottom" class="material-icons"> west </span>
            CONFIRM PAYMENT
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-5">
      <div class="row mb-4 pb-3">
        <div class="col-lg-4 col-12 col-sm-10 offset-lg-4 offset-sm-2">
          <div class="text-atvyellow mb-4 font-weight-bold">PAYMENT METHOD</div>
        </div>
        <div class="col-lg-6 col-sm-10 offset-lg-4 offset-sm-2">
          <div class="row">
            <div class="col-md-4">
              <img
                src="../../public/assets/img/payment/paypal.png"
                style="height: 120px; object-fit: cover; border-radius: 10px"
                alt="Circle Image"
                class="img-raised img-fluid"
              />
            </div>
            <div class="col-md-4">
              <img
                src="../../public/assets/img/payment/paypal2.png"
                style="height: 120px; object-fit: cover; border-radius: 10px"
                alt="Circle Image"
                class="img-raised img-fluid"
              />
            </div>
            <div class="col-md-4">
              <PayPal
                amount="30.00"
                currency="USD"
                :button-style="myStyle"
                env="production"
                v-on:payment-authorized="paymentauthorized"
                v-on:payment-completed="paymentcompleted"
                v-on:payment-cancelled="paymentcancelled"
                :client="credentials"
              >
              </PayPal>
            </div>
          </div>

          <!--<img src="../../public/assets/img/payment/momo.png"-->
          <!--style="width: 130px; height: 120px; object-fit: cover; border-radius: 10px" alt="Circle Image"-->
          <!--class="img-raised img-fluid mr-3">-->
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-12 col-sm-10 offset-lg-4 offset-sm-2">
          <form>
            <div class="text-atvyellow mb-4 font-weight-bold">PAYMENT DETAILS</div>
            <div class="form-group">
              <label for="coupon" class="bmd-label-floating">Email</label>
              <input
                type="text"
                class="form-control"
                id="coupon"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="form-group">
              <label for="password" class="bmd-label-floating">Password</label>
              <input type="password" class="form-control" id="password" />
            </div>
            <div class="text-center mt-5 pt-3">
              <button
                type="submit"
                class="btn btn-block btn-atvyellow mb-3 h5 font-weight-bold"
              >
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import PayPal from "vue-paypal-checkout";

export default {
  data() {
    return {
      data: {
        email: "",
        password: "",
      },
      myStyle: {
        label: "paypal",
        height: 55,
        size: "responsive", // small | medium | large | responsive
        shape: "rect", // pill | rect
        color: "silver", // gold | blue | silver | black
      },
      credentials: {
        sandbox:
          "Ab47KRc0wtB2EQ5RV6aK3hmK-dQOAUtOjgRYcLwlnaUQgJvOui5tHL2cVudLVpipz4NjYq2acjUpm8hL",
        production:
          "AWHLI5aPG32f0Sjkwnvf90mUIO6Vvi1ojXtaU6Ju8jQRCmy6aMp-EkzTa5IGlfpZkd4qercMxp8lT9ZK",
      },
      isLoading: false,
      fullPage: true,
      details: {},
      height: 128,
      width: 128,
    };
  },
  components: {
    Loading,
    PayPal,
  },
  methods: {
    paymentauthorized(event) {
      // Your response
      console.log(event, "authorised");
    },
    paymentcancelled(event) {
      // Your response
      console.log(event, "cancelled");
    },
    paymentcompleted(event) {
      // Your response
      console.log(event, "completed");
    },
    submit() {
      this.isLoading = true;
      this.$store
        .dispatch("retrieveToken", this.data)
        .then((res) => {
          if (res.info.type == "intern") {
            // console.log(this.$store.state.redirectPathLink ,'path')
            // this.$router.push(this.$store.state.redirectPathLink ? this.$store.state.redirectPathLink : '/internship');
            this.$router.push("/internship");
          } else {
            Swal.fire({
              title: "Error!",
              text:
                "Sorry this session is for interns only. Please contact Aukiss TV Support.",
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            title: "Error!",
            text: "Email/Password does not exist",
            icon: "error",
            confirmButtonText: "Close",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  created() {
    document.title = "Complete Registration || ATV";
  },
};
</script>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
  background-color: black !important;
  color: black !important;
  -webkit-box-shadow: 0 0 0 1000px #bdbdbd inset !important;
  -webkit-text-fill-color: black !important;
}

/* Change the white to any color ;) */
/*input:-webkit-autofill,*/
/*input:-webkit-autofill:hover,*/
/*input:-webkit-autofill:focus,*/
/*input:-webkit-autofill:active  {*/
/*-webkit-box-shadow: 0 0 0 30px white inset !important;*/
/*transition: background-color 5000s ease-in-out 0s;*/

/*}*/
/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}

input:-webkit-autofill {
  color: #2a2a2a !important;
}

/*#contentItem {*/
/*position: absolute;*/
/*left: 50%;*/
/*top: 50%;*/
/*transform: translate(-50%, -50%);*/
/*}*/
@media only screen and (min-width: 150px) {
  .paypal-button:not(.paypal-button-card) {
    height: 120px !important;
    /* min-height: 25px; */
    max-height: 120px !important;
  }
}
</style>
