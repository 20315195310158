<template>
    <div class="text-light">
        <loading
            :active.sync="isLoading"
            :can-cancel="true"
            loader="dots"
            :is-full-page="fullPage"
            :height="height"
            :width="width"
            background-color="#6b6969"
        ></loading>
        <div class="container-fluid custom-container-fluid mt-5">
            <div class="row">
                <div class="col-md-12">
                    <div class="font-weight-bold h5"><span style="vertical-align: bottom;" class="material-icons"> west </span> COMPLETE REGISTRATION
                    </div>
                </div>
            </div>
        </div>
        <div class="container mt-5">
            <div class="row mb-5 pb-3">
                <div class="col-md-12 text-center">
                    <div>
                        <img src="../../public/assets/img/christian.jpg"
                             style="width: 120px; height: 120px; object-fit: contain;" alt="Circle Image"
                             class="img-raised rounded-circle img-fluid">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-12 col-sm-10 offset-lg-4 offset-sm-2">
                    <form>
                        <div class="form-group">
                            <label for="coupon" class="bmd-label-floating">Coupon code</label>
                            <input type="text" class="form-control" id="coupon" aria-describedby="emailHelp">
                        </div>
                        <div class="form-group">
                            <label for="payment" class="bmd-label-floating">Payment Details</label>
                            <input type="text" class="form-control" id="payment">
                        </div>
                        <div class="form-group">
                            <label for="price" class="bmd-label-floating">Final Price</label>
                            <input type="number" class="form-control" id="price">
                        </div>
                        <div class="text-center mt-5">
                            <button type="submit" class="btn btn-block btn-atvyellow mb-3 h5 font-weight-bold">Finish
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import Swal from "sweetalert2";
    import Loading from "vue-loading-overlay";
    import "vue-loading-overlay/dist/vue-loading.css";
    import axios from "axios";

    export default {
        data() {
            return {
                data: {
                    email: "",
                    password: ""
                },
                isLoading: false,
                fullPage: true,
                details: {},
                height: 128,
                width: 128,

            };
        },
        components: {
            Loading
        },
        methods: {
            submit() {
                this.isLoading = true;
                this.$store
                    .dispatch("retrieveToken", this.data)
                    .then((res) => {
                        if (res.info.type == 'intern') {
                            // console.log(this.$store.state.redirectPathLink ,'path')
                            // this.$router.push(this.$store.state.redirectPathLink ? this.$store.state.redirectPathLink : '/internship');
                            this.$router.push('/internship');
                        } else {
                            Swal.fire({
                                title: "Error!",
                                text: "Sorry this session is for interns only. Please contact GhanaTech lab's administration.",
                                icon: "error",
                                confirmButtonText: "Close"
                            });
                        }
                    })
                    .catch(err => {
                        console.log((err))
                        Swal.fire({
                            title: "Error!",
                            text: "Email/Password does not exist",
                            icon: "error",
                            confirmButtonText: "Close"
                        });
                    }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        created() {
            document.title = 'Complete Registration || Aukiss TV';
        }
    };
</script>


<style scoped>
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:active,
    input:-webkit-autofill:focus {
        background-color: black !important;
        color: black !important;
        -webkit-box-shadow: 0 0 0 1000px #bdbdbd inset !important;
        -webkit-text-fill-color: black !important;
    }

    /* Change the white to any color ;) */
    /*input:-webkit-autofill,*/
    /*input:-webkit-autofill:hover,*/
    /*input:-webkit-autofill:focus,*/
    /*input:-webkit-autofill:active  {*/
    /*-webkit-box-shadow: 0 0 0 30px white inset !important;*/
    /*transition: background-color 5000s ease-in-out 0s;*/

    /*}*/
    /*Change text in autofill textbox*/
    input:-webkit-autofill {
        -webkit-text-fill-color: black !important;
    }

    input:-webkit-autofill {
        color: #2a2a2a !important;
    }

    /*#contentItem {*/
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 50%;*/
    /*transform: translate(-50%, -50%);*/
    /*}*/
</style>
