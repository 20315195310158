<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-3">
        <div class="custom-container-fluid">
          <div class="row mb-5">
            <div class="col-md-12">
              <div
                class="text-atvyellow cursor-pointer"
                @click="$router.go(-1)"
              >
                <span class="material-icons">west</span>
                <span style="vertical-align: top">Back</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 wide_space">
              <img
                v-lazy="detail.image"
                class="img-fluid main_image"
                style="border-radius: 10px"
                alt
              />
            </div>
            <div class="col-md-9">
              <h3 class="text-atvyellow mt-lg-0 font-weight-bold mt-sm-4">
                {{ detail.name }}
              </h3>
              <div class="mb-4">
                <span class="text-atvyellow font-weight-bold">
                  <span v-for="(a, k) in detail.categories" :key="k">
                    {{ a }}
                    <span v-if="detail.categories.length - 1 !== k">,</span>
                  </span>
                </span>
              </div>
              <p class="col-md-10 text-justify pl-0 pr-0">
                {{ detail.description }}
              </p>
              <div>
                <router-link
                  class="btn btn-atvyellow pr-5 pl-5"
                  :to="'/live-radio-player/' + detail.id + '/' + detail.radio_type_id"
                  >
                  Play Now
                </router-link>
              </div>
              <!--cast code :to="
                    '/live-radio-player/' +
                    detail.id +
                    '/' +
                    detail.radio_type_id
                  "-->

              <h3 class="text-atvyellow mt-5" style="font-weight: 400">
                Celebrity Profile
              </h3>
              <br />

              <div class="tab-content">
                <div class="tab-pane active" id="cast">
                  <div class="row">
                    <div
                      class="col-md-3 mb-4"
                      v-for="(c, i) in detail.cast"
                      :key="i"
                    >
                      <router-link :to="'/celebrity-profile/' + c.id">
                        <div class="media">
                          <img
                            v-lazy="c.cast_image"
                            class="mr-3 img-fluid rounded-circle"
                            style="width: 60px; height: 60px; object-fit: cover"
                            alt="..."
                          />
                          <div class="media-body">
                            <div class="mt-2">{{ c.title }}</div>
                            <div
                              class="text-atvyellow"
                              style="font-weight: 400"
                            >
                              {{
                                (c.person_info || []).length > 60
                                  ? (c.person_info || []).substring(0, 60) +
                                    "..."
                                  : c.person_info
                              }}
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Plyr from "plyr";

export default {
  name: "RadioDetail",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      detail: {},
      movie_player: "",
      poster_image: "",
    };
  },
  components: {
    topnav: () => import("@/components/topNav"),
    footernav: () => import("@/components/footer"),
    Loading,
  },
  methods: {
    getYear(d) {
      if (d) {
        return moment(new Date(d)).format("YYYY");
      }
    },

    sendViews(mov_id) {
      let data = {
        user_id: (this.userInfo || {}).id,
        magazine: "",
        movie: "",
        tvshow: "",
        livetv: "",
        liveradio: mov_id,
        movie_clicked: 1,
        magazine_clicked: "",
        tvshow_clicked: "",
        livetv_clicked: "",
        liveradio_clicked: "",
      };
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/postUserView", data)
        .then((res) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },

    getMovieDetails() {
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/radiodetails", {
          radio_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.data.success) {
            this.detail = res.data.data;

            document.name = this.detail.name + " || Aukiss TV";
            console.log("details", this.detail);

            this.sendViews(this.detail.id);
          } else {
            this.$router.push("/404");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id,
        })
        .then((res) => {
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {
            //} else {
              this.subscription = res.data.data;
              if (
                (this.subscription || {}).expired_at ||
                this.subscription == null
              ) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                }
              } else {
                this.$router.push("/subscription");
              }
            // }
          } else {
            this.$router.push("/subscription");
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getMovieDetails();
    this.checkExpiration();
  },
};
</script>

<style scoped>
.table-bordered th,
.table-bordered td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.nav-tabs .nav-item .nav-link.active {
  color: white !important;
  background-color: #a88d30;
}

.nav-tabs .nav-item .nav-link {
  background-color: whitesmoke;
  color: #a88d30 !important;
}

@media screen and (max-width: 599px) and (min-width: 300px) {
  .main_image {
    height: 260px !important;
    width: 100%;
  }

  .recommended {
    height: 208px !important;
  }
}
</style>
