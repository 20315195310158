<template>
  <div class="text-light">
    <loading
      :active.sync="isLoading"
      :can-cancel="true"
      loader="dots"
      :is-full-page="fullPage"
      :height="height"
      :width="width"
      background-color="#6b6969"
    ></loading>
    <!--<div class="container-fluid custom-container-fluid mt-5">-->
    <!--<div class="row">-->
    <!--<div class="col-md-12">-->
    <!--<div class="font-weight-bold h5 cursor-pointer" @click="$router.go(-1)"><span style="vertical-align: bottom;"-->
    <!--class="material-icons"> west </span> SUBSCRIPTION PLAN-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <div class="container" id="contentItem">
      <div class="row text-center">
        <div class="col-lg-10 offset-lg-1 col-sm-6 offset-sm-3 col-12 p-3 error-main">
          <div class="row">
            <div class="col-md-4" v-for="(s, i) in subscription" :key="i">
              <div class="card text-center text-light">
                <h4 class="font-weight-bold">{{ s.plan_name }}</h4>
                <div class="bg-light">
                  <p class="text-dark mt-2 mb-2 font-weight-bold">{{ s.amount_sub }}</p>
                </div>
                <h5 class="font-weight-bold">{{ s.sub_name }}</h5>
                <p class="p-2 text-left">{{ s.description }}</p>
                <div class="mb-3">
                  <!-- <button
                    class="btn btn-atvyellow pl-5 pr-5"
                    @click="
                      plan_selected = s;
                      openModal();
                    "
                  >
                    Select
                  </button> -->

                  <img
                    src="../../public/assets/img/paypal.jpg"
                    alt=""
                    class="img-fluid mr-1"
                    ype="button"
                    @click="
                      plan_selected = s;
                      openModal();
                    "
                  />

                  <img
                    src="../../public/assets/img/momo.jpeg"
                    alt=""
                    class="img-fluid mr-1"
                    ype="button"
                    @click="
                      plan_selected = s;
                      momo();
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--myModal-->
    <div
      class="modal fade"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="display: none"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content text-dark">
          <div class="modal-header">
            <h5 class="modal-title">Make payment</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="material-icons">clear</i>
            </button>
          </div>
          <div class="modal-body">
            <PayPal
              :amount="(plan_selected || {}).amount"
              currency="USD"
              :button-style="myStyle"
              env="production"
              v-on:payment-authorized="paymentauthorized"
              v-on:payment-completed="paymentcompleted"
              v-on:payment-cancelled="paymentcancelled"
              :client="credentials"
            >
            </PayPal>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

    <!--end of modal-->
  </div>
</template>

<script>
/* eslint-disable */
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import PayPal from "vue-paypal-checkout";

export default {
  // beforeRouteEnter(to, from, next) {
  //     next(vm => {
  //         if (from.fullPath !== '/register') {
  //             vm.$router.go(-1);
  //         }
  //     });
  // },
  data() {
    return {
      subscription: [],
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      plan_selected: {
        amount: "0",
      },
      myStyle: {
        label: "paypal",
        height: 55,
        size: "responsive", // small | medium | large | responsive
        shape: "rect", // pill | rect
        color: "gold", // gold | blue | silver | black
      },
      credentials: {
        sandbox:
          "Ab47KRc0wtB2EQ5RV6aK3hmK-dQOAUtOjgRYcLwlnaUQgJvOui5tHL2cVudLVpipz4NjYq2acjUpm8hL",
        production:
          "AWHLI5aPG32f0Sjkwnvf90mUIO6Vvi1ojXtaU6Ju8jQRCmy6aMp-EkzTa5IGlfpZkd4qercMxp8lT9ZK",
      },
    };
  },
  components: {
    Loading,
    PayPal,
  },
  methods: {
    paymentauthorized(event) {
      // console.log(event, 'authorised');
    },
    paymentcancelled(event) {
      hideModal("#myModal");
      Swal.fire({
        title: "Error!",
        text: "Payment was cancelled...",
        icon: "error",
        confirmButtonText: "OK",
      });
    },
    paymentcompleted(event) {
      if (event.state == "approved") {
        hideModal("#myModal");
        this.postSubscriptionPlan().then(() => {
          this.postPaidsubPlan(event.id).then((res) => {
            Swal.fire({
              title: "Success!",
              text: "Payment and Subscription has been completed successfully...",
              icon: "success",
              confirmButtonText: "OK",
            }).then((confirm) => {
              if (confirm) {
                this.$router.push("/");
              }
            });
          });
        });
      }
    },
    getSubscriptionPlans() {
      this.isLoading = true;
      axios
        .get("https://api.aukiss.tv/api/subscriptionplans")
        .then((res) => {
          var list = res.data.data;
          list.splice(0, 1);
          this.subscription = list;
          //console.log("subscription", this.subscription);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    postPaidsubPlan(paypal_transaction_id) {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        axios
          .post("https://api.aukiss.tv/api/postPaidsubPlan", {
            user_id: this.userInfo.id,
            subscription_plan_id: this.plan_selected.id,
            amount: this.plan_selected.amount,
            coupon: 0,
            paypal_id: paypal_transaction_id,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    postSubscriptionPlan() {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        axios
          .post("https://api.aukiss.tv/api/subscriptionplans", {
            user_id: this.userInfo.id,
            subscription_plan_id: this.plan_selected.id,
          })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },

    momo() {
      let me = this;
      let handler = PaystackPop.setup({
        key: "pk_live_1016a5a17cb953a069bfa1ca9f410b551a8aa0a5", // Replace with your public key
        email: this.userInfo.email,
        currency: "GHS",
        amount: this.plan_selected.amount * 100 * 8.6,
        channels: ["mobile_money", "card"],
        ref: "" + Math.floor(Math.random() * 1000000000 + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        // label: "Optional string that replaces customer email"
        //this.userInfo.email
        //this.plan_selected.amount * 100 * 6
        onClose: function () {
          //alert("Window closed.");
        },
        callback: function (response) {
          let message = "Payment complete! Reference: " + response.reference;
          //console.log("response", message);

          //console.log("data response", response.data);
          //console.log("status", response.status);
          //console.log("message", response.message);

          if (response.status == "success" && response.message == "Approved") {
            //console.log("hereeeeeee11111");
            me.postPaidsubPlan(response.reference).then((res) => {
              //console.log("hereeeeeee222222222");
              Swal.fire({
                title: "Success!",
                text: "Payment and Subscription has been completed successfully...",
                icon: "success",
                confirmButtonText: "OK",
              }).then((confirm) => {
                if (confirm) {
                  me.$router.push("/");
                }
              });
            });
          }
        },
      });

      handler.openIframe();
    },
    openModal() {
      if (this.plan_selected.amount > 0) {
        openModal("#myModal");
      } else {
        this.postSubscriptionPlan().then((res) => {
          // console.log(res)
          Swal.fire({
            title: "Success!",
            text: "Subscription has been completed successfully...",
            icon: "success",
            confirmButtonText: "OK",
          }).then((confirm) => {
            if (confirm) {
              this.$router.push("/home");
            }
          });
        });
      }
    },
  },
  created() {
    document.title = "Subscription || Aukiss TV";
    this.getSubscriptionPlans();
  },
};
</script>

<style scoped>
.card {
  background: #1c212e;
  border: 1px solid #ffffff;
  border-radius: 10px;
}

#contentItem {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
