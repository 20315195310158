<template>
  <div class="text-light">
    <topnav></topnav>
    <div
      class="main main-raised"
      style="margin: 0px; background-color: #1c212e; min-height: 100vh"
    >
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        background-color="#6b6969"
      ></loading>
      <div class="section mt-3">
        <div class="custom-container-fluid">
          <div class="row mb-5">
            <div class="col-md-12">
              <div
                class="text-atvyellow cursor-pointer"
                @click="$router.go(-1)"
              >
                <span class="material-icons">west</span>
                <span style="vertical-align: top">Back</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 wide_space">
              <img
                v-lazy="detail.image"
                class="img-fluid main_image"
                style="border-radius: 10px"
                alt=""
              />
            </div>
            <div class="col-md-9">
              <h3 class="text-atvyellow mt-lg-0 font-weight-bold mt-sm-4">
                {{ detail.post_title }}
              </h3>
              <div class="mb-4">
                <span class="mr-2">{{ getYear(detail.movireleasedate) }}</span>
                <span v-if="detail.movireleasedate" class="mr-2">|</span>
                <span class="mr-2" v-if="detail.movie_time_duration">
                  {{ detail.movie_time_duration }}
                </span>
                <span v-if="detail.movireleasedate" class="mr-2">|</span>
                <span class="text-atvyellow font-weight-bold">
                  <span v-for="(a, k) in detail.categories" :key="k">
                    {{ a }}
                    <span v-if="detail.categories.length - 1 !== k">,</span>
                  </span>
                </span>
              </div>
              <p class="col-md-10 text-justify pl-0 pr-0">
                {{ detail.post_excerpt }}
              </p>
              <div>
                <button
                  class="btn btn-outline-atvyellow bg-light mr-4 pr-5 pl-5"
                  :disabled="!detail.trailler_url"
                  @click="
                    poster_image = detail.hori_image || detail.image;
                    showVideo(detail.trailler_url);
                    movie_title = detail.post_title;
                  "
                >
                  Trailer
                </button>
              </div>

              <div class="nav-tabs-navigation mt-5">
                <div class="nav-tabs-wrapper">
                  <ul class="nav nav-tabs" data-tabs="tabs">
                    <!--<li class="nav-item mr-3">-->
                    <!--<a class="nav-link season active" href="#season1"-->
                    <!--data-toggle="tab">Season 1</a>-->
                    <!--</li>-->
                    <li class="nav-item" v-for="(c, i) in seasons" :key="i">
                      <a
                        :class="['nav-link season']"
                        :id="'season' + i"
                        href="#season1"
                        @click="getCategoryMovies(c)"
                        data-toggle="tab"
                        >Season {{ c }}</a
                      >
                    </li>

                    <li
                      v-show="detail.s2added == 1"
                      class="nav-item"
                      v-for="(m, n) in seasons2"
                      :key="'A' + n"
                    >
                      <a
                        :class="['nav-link season2']"
                        :id="'season2' + n"
                        href="#season2"
                        @click="getCategoryMovies2(m)"
                        data-toggle="tab"
                        >Season {{ m }}</a
                      >
                    </li>

                    <li
                      v-show="detail.s3added == 1"
                      class="nav-item"
                      v-for="(m, n) in seasons3"
                      :key="'B' + n"
                    >
                      <a
                        :class="['nav-link season3']"
                        :id="'season3' + n"
                        href="#season3"
                        @click="getCategoryMovies3(m)"
                        data-toggle="tab"
                        >Season {{ m }}</a
                      >
                    </li>

                    <li
                      v-show="detail.s4added == 1"
                      class="nav-item"
                      v-for="(m, n) in seasons4"
                      :key="'C' + n"
                    >
                      <a
                        :class="['nav-link season4']"
                        :id="'season4' + n"
                        href="#season4"
                        @click="getCategoryMovies4(m)"
                        data-toggle="tab"
                        >Season {{ m }}</a
                      >
                    </li>

                    <li
                      v-show="detail.s5added == 1"
                      class="nav-item"
                      v-for="(m, n) in seasons5"
                      :key="'D' + n"
                    >
                      <a
                        :class="['nav-link season5']"
                        :id="'season5' + n"
                        href="#season5"
                        @click="getCategoryMovies5(m)"
                        data-toggle="tab"
                        >Season {{ m }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-content mt-0">
                <div class="tab-pane" id="season1">
                  <div class="row">
                    <div
                      class="col-md-3 cursor-pointer col-sm-6 col-xs-6 col-6"
                      v-for="(a, i) in season_episodes"
                      :key="i"
                      @click="
                        poster_image = a.image;
                        showVideo(a.video_url, i);
                        movie_title = a.title;
                      "
                    >
                      <div class="card">
                        <img
                          v-lazy="a.image"
                          style="border-radius: 10px; height: 206px; object-fit: cover"
                          class="card-img-top"
                          alt
                        />
                      </div>

                      <div class="font-weight-bold">{{ a.title }}</div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="season2">
                  <div class="row">
                    <div
                      class="col-md-3 cursor-pointer col-sm-6 col-xs-6 col-6"
                      v-for="(b, k) in season_episodes2"
                      :key="k"
                      @click="
                        poster_image = b.image2;
                        showVideo(b.video_url2, k);
                        movie_title = b.title2;
                      "
                    >
                      <div class="card">
                        <img
                          v-lazy="b.image2"
                          style="border-radius: 10px; height: 206px; object-fit: cover"
                          class="card-img-top"
                          alt
                        />
                      </div>

                      <div class="font-weight-bold">{{ b.title2 }}</div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="season3">
                  <div class="row">
                    <div
                      class="col-md-3 cursor-pointer col-sm-6 col-xs-6 col-6"
                      v-for="(b, k) in season_episodes3"
                      :key="k"
                      @click="
                        poster_image = b.image3;
                        showVideo(b.video_url3, k);
                        movie_title = b.title3;
                      "
                    >
                      <div class="card">
                        <img
                          v-lazy="b.image3"
                          style="border-radius: 10px; height: 206px; object-fit: cover"
                          class="card-img-top"
                          alt
                        />
                      </div>

                      <div class="font-weight-bold">{{ b.title3 }}</div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="season4">
                  <div class="row">
                    <div
                      class="col-md-3 cursor-pointer col-sm-6 col-xs-6 col-6"
                      v-for="(b, k) in season_episodes4"
                      :key="k"
                      @click="
                        poster_image = b.image4;
                        showVideo(b.video_url4, k);
                        movie_title = b.title4;
                      "
                    >
                      <div class="card">
                        <img
                          v-lazy="b.image4"
                          style="border-radius: 10px; height: 206px; object-fit: cover"
                          class="card-img-top"
                          alt
                        />
                      </div>

                      <div class="font-weight-bold">{{ b.title4 }}</div>
                    </div>
                  </div>
                </div>

                <div class="tab-pane" id="season5">
                  <div class="row">
                    <div
                      class="col-md-3 cursor-pointer col-sm-6 col-xs-6 col-6"
                      v-for="(b, k) in season_episodes5"
                      :key="k"
                      @click="
                        poster_image = b.image5;
                        showVideo(b.video_url5, k);
                        movie_title = b.title5;
                      "
                    >
                      <div class="card">
                        <img
                          v-lazy="b.image5"
                          style="border-radius: 10px; height: 206px; object-fit: cover"
                          class="card-img-top"
                          alt
                        />
                      </div>

                      <div class="font-weight-bold">{{ b.title5 }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <!--cast code-->
              <!-- <h3 class="text-atvyellow mt-5" style="font-weight: 400">Cast & Crew</h3> -->
              <div class="nav-tabs-navigation mb-5 mt-5">
                <div class="nav-tabs-wrapper">
                  <ul class="nav nav-tabs" data-tabs="tabs">
                    <li class="nav-item mr-3">
                      <a
                        class="nav-link active pl-4 pr-4 pt-2 pb-2"
                        href="#cast"
                        data-toggle="tab"
                        >Cast</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link pl-4 pr-4 pt-2 pb-2"
                        href="#crew"
                        data-toggle="tab"
                        >Crew</a
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <div class="tab-content">
                <div class="tab-pane active" id="cast">
                  <div class="row">
                    <div
                      class="col-md-3 mb-4"
                      v-for="(c, i) in detail.cast"
                      :key="i"
                    >
                      <router-link :to="'/celebrity-profile/' + c.id">
                        <div class="media">
                          <img
                            v-lazy="c.cast_image"
                            class="mr-3 img-fluid rounded-circle"
                            style="width: 60px; height: 60px; object-fit: cover"
                            alt="..."
                          />
                          <div class="media-body">
                            <div class="mt-2">{{ c.title }}</div>
                            <div
                              class="text-atvyellow"
                              style="font-weight: 400"
                            >
                              {{ c.character }}
                              <!-- {{
                              (c.person_info || []).length > 60
                              ? (c.person_info || []).substring(0, 60) + "..."
                              : c.person_info
                              }} -->
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="tab-pane" id="crew">
                  <div class="row">
                    <div
                      class="col-md-3 mb-4"
                      v-for="(c, i) in detail.crew"
                      :key="i"
                    >
                      <router-link :to="'/celebrity-profile/' + c.id">
                        <div class="media">
                          <img
                            v-lazy="c.crew_image"
                            class="mr-3 img-fluid rounded-circle"
                            style="width: 60px; height: 60px; object-fit: cover"
                            alt="..."
                          />
                          <div class="media-body">
                            <div class="mt-2">{{ c.title }}</div>
                            <div
                              class="text-atvyellow"
                              style="font-weight: 400"
                            >
                              {{ c.character }}
                              <!-- {{
                              (c.person_info || []).length > 60
                              ? (c.person_info || []).substring(0, 60) + "..."
                              : c.person_info
                              }} -->
                            </div>
                          </div>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

              <h3
                class="text-atvyellow mt-5"
                v-if="detail.fun_fact"
                style="font-weight: 400"
              >
                Fun Facts
              </h3>
              <p
                class="col-md-10 text-justify pl-0 pr-0"
                v-if="detail.fun_fact"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit
                tempor rutrum vivamus nascetur amet, dolor. Ipsum posuere ornare
                cras faucibus nulla sed. Est eu aliquam sit nunc in arcu
                condimentum. Sed dui ac eget nisl. Habitasse dictumst ipsum
                donec lectus purus eleifend tellus auctor. Egestas convallis
                arcu augue magna consequat in feugiat pharetra.
              </p>
              <h3
                class="text-atvyellow mt-5"
                style="font-weight: 400"
                v-if="detail.recommendedTVshows.length > 0"
              >
                You May Also Like
              </h3>
              <div class="row">
                <!-- <div class="col-md-12" v-if="detail.recommendedTVshows == 0">No recommended videos</div> -->
                <div
                  class="col-md-2 col-sm-6 col-xs-6 col-6"
                  v-for="(c, i) in detail.recommendedTVshows"
                  :key="i"
                >
                  <router-link
                    @click.native="getSeriesDetails()"
                    :to="'/series-details/' + c.id"
                  >
                    <div class="card">
                      <img
                        v-lazy="c.image"
                        style="height: 150px; border-radius: 5px"
                        class="card-img-top recommended"
                        alt
                      />
                    </div>
                    <div class="mt-1 movie-text">
                      {{ getYear(c.movireleasedate) }}
                      <span
                        v-if="
                          (c.categories || []).length > 0 && c.movireleasedate
                        "
                        >,</span
                      >
                      <span v-for="(cc, p) in c.categories" :key="p">
                        {{ cc }}
                        <span v-if="c.categories.length - 1 !== p">,</span>
                      </span>
                    </div>
                    <div class="font-weight-bold">{{ c.post_title }}</div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footernav></footernav>
    </div>
    <div
      class="modal fade"
      id="movieModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="text-center">
              <h3 class="text-orange mt-2 font-weight-bold">
                {{ movie_title }}
              </h3>
            </div>

            <!-- <div v-show="next_player == true">
           
            
              <button class="btn btn-outline-atvyellow bg-light mr-4 pr-5 pl-5">
                Play Next Video {{ timer }}
              </button>
            </div>-->

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="stopPlayer()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <video poster="capture.jpg" id="player" playsinline controls>
              <source src type="video/mp4" />
              <source src type="video/webm" />
            </video>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-orange button"
              data-dismiss="modal"
              aria-label="Close"
              @click="stopPlayer()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Plyr from "plyr";

export default {
  name: "Series-details",
  data() {
    return {
      isLoading: false,
      fullPage: true,
      height: 128,
      width: 128,
      userInfo: this.$store.state.userInfo,
      subscription: {},
      detail: {},
      movie_player: "",
      seasons: [],
      episodes: [],
      season_episodes: [],
      seasons2: [],
      episodes2: [],
      season_episodes2: [],

      seasons3: [],
      episodes3: [],
      season_episodes3: [],

      seasons4: [],
      episodes4: [],
      season_episodes4: [],

      seasons5: [],
      episodes5: [],
      season_episodes5: [],

      next_player_video: "",
      movie_title: "",
      poster_image: "",
      next_player: false,
      timer: 0.0
    };
  },
  components: {
    topnav: () => import("@/components/topNav"),
    footernav: () => import("@/components/footer"),
    Loading
  },
  methods: {
    triggerPlayer(videoUrl, index = null) {
      this.movie_player = new Plyr("#player", {
        debug: false,
        settings: ["speed"]
        // autoplay: true,
      });
      this.movie_player.source = {
        type: "video",
        title: "Example title",
        sources: [
          {
            src: videoUrl ? videoUrl : "",
            type: "video/mp4",
            size: 720
          },
          {
            src: videoUrl ? videoUrl : "",
            type: "video/webm",
            size: 1080
          }
        ],
        poster: this.poster_image ? this.poster_image : ""
      };
      // this.movie_player.on("playing", (event) => {
      //   console.log(this.movie_player.duration, event.detail.plyr.duration);
      //   let player_duration = this.movie_player.duration * 0.9;
      //   let player_current_time = this.movie_player.currentTime;
      //   if (player_current_time >= player_duration) {
      //     if (index + 1 <= this.season_episodes.length - 1) {
      //       this.next_player = true;
      //       this.next_player_video = this.season_episodes[index + 1];
      //       console.log("next player", index, "index", this.next_player_video);
      //       this.startTimer(10, index + 1);
      //     } else {
      //       this.next_player = false;
      //       console.log("no player exist");
      //     }
      //   }
      // });
    },

    startTimer(duration, videoIndex) {
      var timer = duration,
        minutes,
        seconds;
      let me = this;
      let timed = setInterval(function() {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        // display.textContent = minutes + ":" + seconds;
        me.timer = minutes + ":" + seconds;
        console.log(minutes + ":" + seconds);
        if (--timer < 0) {
          timer = duration;
          clearInterval(timed);
          console.log("timer has fined");
          console.log(me.season_episodes[videoIndex]["video_url"]);
          hideModal("#movieModal");
          me.poster_image = me.season_episodes[videoIndex]["image"];
          me.movie_title = me.season_episodes[videoIndex]["title"];
          me.showVideo(me.season_episodes[videoIndex]["video_url"], videoIndex);
        }
      }, 1000);
    },

    showVideo(videoUrl, i = null) {
      //console.log("url", videoUrl);
      this.next_player = false;

      //console.log("hello");
      openModal("#movieModal");

      if (this.movie_player) {
        this.movie_player.destroy();
        this.triggerPlayer(videoUrl, i);
        return;
      }
      this.triggerPlayer(videoUrl, i);
    },
    stopPlayer() {
      if (this.movie_player) {
        this.movie_player.stop();
      }
    },
    getYear(d) {
      if (d) {
        return moment(new Date(d)).format("YYYY");
      }
    },

    sendViews(series_id) {
      let data = {
        user_id: (this.userInfo || {}).id,
        magazine: "",
        movie: "",
        tvshow: series_id,
        livetv: "",
        liveradio: "",
        movie_clicked: "",
        magazine_clicked: "",
        tvshow_clicked: 1,
        livetv_clicked: "",
        liveradio_clicked: ""
      };
      this.isLoading = true;
      axios
        .post("https://api.aukiss.tv/api/postUserView", data)
        .then(res => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getSeriesDetails() {
      this.isLoading = true;

      axios
        .post("https://api.aukiss.tv/api/webtvshowId", {
          tvshow_id: this.$route.params.id
        })
        .then(res => {
          if (res.data.success) {
            this.detail = res.data.data;
            // console.log("data", res.data.data);
            this.sendViews(this.detail.id);

            this.seasons = [];

            (this.detail || {}).episodes.forEach(e => {
              //console.log("season 1", e.season);
              this.seasons = this.seasons.concat(e.season + "");
            });
            this.seasons = this.removeDuplicate(this.seasons);
            if (this.seasons.length > 0) {
              this.getCategoryMovies(this.seasons[0]);
              this.$nextTick(() => {
                $(".season").addClass("active show");
                $("#season1").addClass("active show");
              });
            }

            this.seasons2 = [];

            (this.detail || {}).episodes2.forEach(f => {
              this.seasons2 = this.seasons2.concat("2" + "");
            });

            this.seasons2 = this.removeDuplicate(this.seasons2);
            if (this.seasons2.length > 1) {
              this.getCategoryMovies2(this.seasons2[1]);
              this.$nextTick(() => {
                $(".season2").addClass("active show");
                $("#season2").addClass("active show");
              });
            }

            this.seasons3 = [];

            (this.detail || {}).episodes3.forEach(f => {
              this.seasons3 = this.seasons3.concat("3" + "");
            });

            this.seasons3 = this.removeDuplicate(this.seasons3);
            if (this.seasons3.length > 1) {
              this.getCategoryMovies3(this.seasons3[1]);
              this.$nextTick(() => {
                $(".season3").addClass("active show");
                $("#season3").addClass("active show");
              });
            }

            this.seasons4 = [];

            (this.detail || {}).episodes4.forEach(f => {
              this.seasons4 = this.seasons4.concat("4" + "");
            });

            this.seasons4 = this.removeDuplicate(this.seasons4);
            if (this.seasons4.length > 1) {
              this.getCategoryMovies4(this.seasons4[1]);
              this.$nextTick(() => {
                $(".season4").addClass("active show");
                $("#season4").addClass("active show");
              });
            }

            this.seasons5 = [];

            (this.detail || {}).episodes5.forEach(f => {
              this.seasons5 = this.seasons5.concat("5" + "");
            });

            this.seasons5 = this.removeDuplicate(this.seasons5);
            if (this.seasons5.length > 1) {
              this.getCategoryMovies5(this.seasons5[1]);
              this.$nextTick(() => {
                $(".season5").addClass("active show");
                $("#season5").addClass("active show");
              });
            }
          } else {
            this.$router.push("/404");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    removeDuplicate(data) {
      return [...new Set(data)];
    },
    getCategoryMovies(season) {
      this.season_episodes = (this.detail || {}).episodes.filter(e => {
        if (e.season === season) {
          return e;
        }
      });
    },

    getCategoryMovies2(season) {
      this.season_episodes2 = (this.detail || {}).episodes2.filter(e => {
        if ("2" === season) {
          return e;
        }
      });
    },

    getCategoryMovies3(season) {
      this.season_episodes3 = (this.detail || {}).episodes3.filter(e => {
        if ("3" === season) {
          return e;
        }
      });
    },

    getCategoryMovies4(season) {
      this.season_episodes4 = (this.detail || {}).episodes4.filter(e => {
        if ("4" === season) {
          return e;
        }
      });
    },

    getCategoryMovies5(season) {
      this.season_episodes5 = (this.detail || {}).episodes5.filter(e => {
        if ("5" === season) {
          return e;
        }
      });
    },

    checkExpiration() {
      axios
        .post("https://api.aukiss.tv/api/webuserSubplan", {
          user_id: this.userInfo.id
        })
        .then(res => {
          //console.log("res", res);
          if (res.data.data !== null) {
            // if (
            //   ((res.data.data || {}).user || {}).role_id == 2 ||
            //   ((res.data.data || {}).user || {}).role_id == 3 ||
            //   ((res.data.data || {}).user || {}).role_id == 4 ||
            //   ((res.data.data || {}).user || {}).role_id == 5
            // ) {
            // } else {
              this.subscription = res.data.data;
              if (
                (this.subscription || {}).expired_at ||
                this.subscription == null
              ) {
                if (this.checkDate()) {
                  this.$router.push("/subscription/expired");
                }
              } else {
                this.$router.push("/subscription");
              }
           //  }
          } else {
            this.$router.push("/subscription");
            // if (this.userInfo.first_name.toLowerCase() == "nana") {
            // } else {

            // }
          }
        });
    },

    checkDate() {
      var date = moment(this.subscription.expired_at);
      var now = moment();
      if (now > date) {
        return true;
      } else {
        return false;
      }
    }
  },

  created() {
    this.$loadScript(
      "https://cdn.jsdelivr.net/npm/moment@2.27.0/min/moment.min.js"
    ).then(() => {});
    this.getSeriesDetails();
    this.checkExpiration();
    document.title = "Series || Aukiss TV";
  }
};
</script>

<style scoped>
.table-bordered th,
.table-bordered td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.nav-tabs .nav-item .nav-link.active {
  color: white !important;
  background-color: #a88d30;
}

.nav-tabs .nav-item .nav-link {
  background-color: whitesmoke;
  color: #a88d30 !important;
}

.nav-tabs .nav-item .season.nav-link.active {
  color: #a88d30 !important;
}

.nav-tabs .nav-item .season.nav-link {
  background: none;
  color: rgba(255, 255, 255, 0.5) !important;
}

.nav-tabs .nav-item .season2.nav-link.active {
  color: #a88d30 !important;
}

.nav-tabs .nav-item .season2.nav-link {
  background: none;
  color: rgba(255, 255, 255, 0.5) !important;
}

.nav-tabs .nav-item .season3.nav-link.active {
  color: #a88d30 !important;
}

.nav-tabs .nav-item .season3.nav-link {
  background: none;
  color: rgba(255, 255, 255, 0.5) !important;
}

.nav-tabs .nav-item .season4.nav-link.active {
  color: #a88d30 !important;
}

.nav-tabs .nav-item .season4.nav-link {
  background: none;
  color: rgba(255, 255, 255, 0.5) !important;
}

.nav-tabs .nav-item .season5.nav-link.active {
  color: #a88d30 !important;
}

.nav-tabs .nav-item .season5.nav-link {
  background: none;
  color: rgba(255, 255, 255, 0.5) !important;
}

.card {
  margin-bottom: 15px !important;
  border-radius: 10px !important;
}

.card-img-top[lazy="loading"] {
  object-fit: cover !important;
}

/* .main_image {
  height: 800px;
  object-fit: cover;
  zoom: 22%;
} */

@media screen and (max-width: 599px) and (min-width: 300px) {
  .main_image {
    height: 260px !important;
    width: 100%;
  }

  .recommended {
    height: 208px !important;
  }
}
</style>
